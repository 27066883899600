import { useSelector } from 'hooks/useSelector'
import { useActionStatus } from 'hooks/useActionStatus'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { FC, ReactNode, useEffect } from 'react'
import { identityGet } from 'store/kyc-backend/asyncThunks/identityGet'
import { readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } from 'store/kyc-backend/slices/statusSlice'
import { approveCompanyToggleClear , approveIndividualToggleClear } from 'store/kyc-backend/slices/finalApproveSlice'
import { companyDocsGetClear } from 'store/kyc-backend/slices/companyDocsSlice'
import { uboBackDocsGetClear } from 'store/kyc-backend/slices/uboDocsSlice'
import { authorizedPersonDocsBackGetClear } from 'store/kyc-backend/slices/authorizedPersonDocsSlice'
import { companyProfileBackGetClear, archivedCompanyProfilesBackGetClear } from 'store/kyc-backend/slices/companySlice'
import { individualProfileBackGetClear } from 'store/kyc-backend/slices/individualSlice'
import { setFormUpdated } from 'store/kyc-front/slices/navSlice'
import { identityGetClear } from 'store/kyc-backend/slices/identitySlice'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { dispatch } from 'store/store'

const ClearUserState: FC<{ children?: ReactNode }> = ({ children }) => {
    useEffect(() => {
        dispatch(readyForReviewBackValidateClear({}));
        dispatch(individualBackReadyForReviewValidateClear({}));
        dispatch(approveCompanyToggleClear({}));
        dispatch(approveIndividualToggleClear({}));
        dispatch(authorizedPersonDocsBackGetClear({}));
        dispatch(uboBackDocsGetClear({}));
        dispatch(companyDocsGetClear({}));
        dispatch(companyProfileBackGetClear({}));
        dispatch(archivedCompanyProfilesBackGetClear({}));
        dispatch(individualProfileBackGetClear({}));
        dispatch(identityGetClear({}));
        dispatch(setFormUpdated({updated: false, form: []}));
	}, []);
    return <>{children}</>
	
}
export const AdminRoute: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<ClearUserState>
			{children}
		</ClearUserState>
	)
}
