import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyUpdatedResponse } from "../slices/companySlice";
import { Components } from "../../../api/kyc/generated/client";
import { store } from "../../store";

type UpdateCompanyRequest = Components.Schemas.UpdateCompany;

export const companyUpdate: AsyncThunkActionType<
  CompanyUpdatedResponse,
  AsyncThunkDataType<null, UpdateCompanyRequest, "data">
> = createAsyncThunk(
  "companyUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.UpdateCompanyProfile(store.getState().general.envs.profileId, data, config);

      return response.data as CompanyUpdatedResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
