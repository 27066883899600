import { PATH, BASE_PATH, ADMIN_BASE_PATH } from 'routes/AppRoutes'
import { useKeycloakLoginPage } from 'modules/keycloak'
import { CoverPage } from './ui-elements/CoverPage'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

type RedirectParam = 'off'

interface LProps {
	isBackend: boolean
}

const Login: FunctionComponent<LProps> = ({isBackend}) => {
	const { redirect } = useParams<{ redirect: RedirectParam }>()
	
	let loginType = isBackend ? PATH.ADMIN_LOGIN : PATH.LOGIN;
	let loginPath = process.env.REACT_APP_DOMAIN + loginType;
	useKeycloakLoginPage({
		loginPagePath: loginPath,
		keycloakDomain: redirect === 'off' ? undefined : process.env.REACT_APP_KEYCLOAK_DOMAIN,
		homePath: isBackend ? ADMIN_BASE_PATH : BASE_PATH,
		//@ts-ignore
		client_id: isBackend ? process.env.REACT_APP_KEYCLOAK_CLIENT_BACK : process.env.REACT_APP_KEYCLOAK_CLIENT_FRONT,
		is_backend: isBackend
	})

	return <CoverPage visible={true} />
}

export default Login