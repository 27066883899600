import { IThemeScreen, themeScreen } from '../../styles/themeStyles'
import { FC } from 'react'
import styled from 'styled-components'
import { store } from "../../store/store";

interface IBg {
	background?: string
}
const Bg = styled.div<IBg>`
	background: ${(props) => props.background};
	width: 100%;
	height: 100%;
`

interface IContentBox {
	width?: 'fullWidth' | keyof Pick<IThemeScreen, 'maxContent' | 'midContent' | 'smallContent'>
}
const ContentBox = styled.div<IContentBox>`
	width: 100%;
	height: 100%;
	position: relative;
	margin: ${() => store.getState().general.envs.type === 'standalone' ? '40px auto' : '0 auto'};
	max-width: ${({ width }) => (width === 'fullWidth' ? 'none' : `calc(${themeScreen[width || store.getState().general.envs.type === 'standalone' ? 'maxContent' : 'maxContent']} + 2%)`)};
	padding: 0 1%;
`

type IProps = IBg & IContentBox
export const PageContent: FC<IProps> = ({ children, background, width }) => {
	const content = <ContentBox width={width}>{children}</ContentBox>
	return background ? <Bg background={background}>{content}</Bg> : content
}
