import { Space, Button, Divider, Row, Col, message, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FC, useEffect, useState, ReactNode } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { dispatch } from '../../../store/store'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { approveCompanyToggle } from '../../../store/kyc-backend/asyncThunks/approveCompanyToggle'
import { approveIndividualToggle } from '../../../store/kyc-backend/asyncThunks/approveIndividualToggle'
import { individualArchive } from '../../../store/kyc-backend/asyncThunks/individualArchive'
import { companyArchive } from '../../../store/kyc-backend/asyncThunks/companyArchive'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { ButtonUpper, ButtonWrapper } from '../ui-elements/Buttons'
import { ColRight } from '../ui-elements/Columns'
import { DividerFirst } from '../ui-elements/Dividers'
import { useActionStatus } from '../../../hooks/useActionStatus'
import Well from '../ui-elements/Well'

import { FormSaver } from '../../../utilities/FormSaver'

const NavSpace = styled(Space)`
	background-color: #fff;
	padding: 15px 30px;
	margin-left: -30px;
	margin-right: -30px;
`

interface IProps {
	approve?: boolean,
	archive?: boolean,
	restore?: boolean,
	validationAction?: (value: any) => any,
	validationActionParams?: object
}

export const KycNav: FC<IProps> = ({approve, archive, restore, validationAction, validationActionParams}) => {
	const nav = useSelector((state) => state.general.nav);
	const envs = useSelector((state) => state.general.envs);
	const profile = useSelector((state) => state.user.profile);
	const individual = useSelector((state) => state.user.individual);
	const validationStatus = useSelector((state) => state.user.status);
	const validationStatusBack = useSelector((state) => state.admin.backStatus);
	let [popupArchiveVisible, setPopupArchiveVisible] = useState(false);
	const { userProfile } = profile;
	let {next, nextName, nextButton, prev, prevName, step} = nav.nav;
	let {formUpdated} = nav;
	let [disabledApprove, setDisabledApprove] = useState(false);
	let [popupPrevVisible, setPopupPrevVisible] = useState(false);
	let [popupNextVisible, setPopupNextVisible] = useState(false);
	let [messageShown, setMessageShown] = useState(false);
	let [archiveMessageShown, setArchiveMessageShown] = useState(false);
	let [restoreMessageShown, setRestoreMessageShown] = useState(false);
    const [errorsLoaded, setErrorsLoaded] = useState(false);
    const [onConfirm, setOnConfirm] = useState(false);
    const [onConfirmLink, setOnConfirmLink] = useState('');
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
    let actionStatus = false;
    //@ts-ignore
    const {isFullfilled, isError, isCleared} = useActionStatus(validationAction);
    const archivingStatus = useActionStatus(envs.accountType === 'BUSINESS' ? companyArchive : individualArchive);
    useEffect(() => {
		if(archivingStatus.isFullfilled && !archivingStatus.isCleared) {
			if(archiveMessageShown || restoreMessageShown) {
				if(restoreMessageShown) {
					message.success('Profile was successfully restored');
					setRestoreMessageShown(false);
				}
				if(archiveMessageShown) {
					message.success('Profile was successfully archived');
					setArchiveMessageShown(false);
				}
				if(envs.accountType === 'BUSINESS') {
					dispatch(companyProfileBackGet({params: {companyId: envs.profileId}}))
				}
				if(envs.accountType === 'PRIVATE') {
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
				}
			}
			
		}
    }, [archivingStatus]);
    useEffect(() => {
		if(isFullfilled && !isCleared) {
			if(envs.accountType === 'BUSINESS') {
				switch(nav.nav.step) {
					case 1:
						handleValidationMessageShow(validationStatus?.companyValidated?.validationErrors);
						return;
					case 2:
						handleValidationMessageShow(validationStatus?.authorizedPersonsValidated, true);
						return;
					case 3:
						handleValidationMessageShow(validationStatus?.beneficialOwnersValidated, true);
						return;
					default:
						return;
				}
			}
			if(envs.accountType === 'PRIVATE') {
				switch(nav.nav.step) {
					case 1:
						handleValidationMessageShow(validationStatus?.individualValidated?.validationErrors);
						return;
					default:
						return;
				}
			}
			
			
		}
    }, [validationStatus]);
     useEffect(() => {
		if(isFullfilled && !isCleared) {
			if(envs.accountType === 'BUSINESS') {
				switch(nav.nav.step) {
					case 1:
						handleValidationMessageShow(validationStatusBack?.companyValidated?.validationErrors);
						return;
					case 2:
						handleValidationMessageShow(validationStatusBack?.authorizedPersonsValidated, true);
						return;
					case 3:
						handleValidationMessageShow(validationStatusBack?.beneficialOwnersValidated, true);
						return;
					default:
						return;
				}
			}
			if(envs.accountType === 'PRIVATE') {
				switch(nav.nav.step) {
					case 1:
						handleValidationMessageShow(validationStatusBack?.individualValidated?.validationErrors);
						return;
					default:
						return;
				}
			}
			
			
		}
    }, [validationStatusBack]);
    
	const handleValidationMessageShow = (el: any, isArray?: boolean) => {
		if(isArray) {
			let isValid = true;
			el.forEach((elemInner: any) => {
				if(elemInner.validationErrors.length) {
					isValid = false
					return
				}
			});
			if(isValid && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false);
			}
		} else {
			if(el.length === 0 && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false)
			}
		}
		
	}

	const cancel = () => {
		setPopupPrevVisible(false);
	  	setPopupNextVisible(false);
	}
	const confirm = (type: string) => {
		if(prev && type === 'prev') {
			setOnConfirm(true);
        	setOnConfirmLink(prev);
			setPopupPrevVisible(false);
		}
		if(next && type === 'next') {
			setOnConfirm(true);
        	setOnConfirmLink(next);
			setPopupNextVisible(false);
		}
	  	
	}
	const handleVisibleChange = (type: string) => {
		if(formUpdated.updated) {
			if(prev && type === 'prev') {
				setOnConfirm(true);
        		setOnConfirmLink(prev);
			}
			if(next && type === 'next') {
				setOnConfirm(true);
        		setOnConfirmLink(next);
			}
	      	
		}
	}
	const handleArchiveVisibleChange = () => {
        setPopupArchiveVisible(!popupArchiveVisible);
    }
    const cancelArchive = () => {
        setPopupArchiveVisible(false);
    }
    const confirmArchive = () => {
        setPopupArchiveVisible(false);
        setArchiveMessageShown(true);
        if(envs.accountType === 'BUSINESS') {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: true}}))
        }
        if(envs.accountType === 'PRIVATE') {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: true}}))
        }
    }
    const confirmRestore = () => {
    	setPopupArchiveVisible(false);
    	setRestoreMessageShown(true);
        if(envs.accountType === 'BUSINESS') {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: false}}))
        }
        if(envs.accountType === 'PRIVATE') {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: false}}))
        }
    }
	const validateParams = () => {
		if(validationAction) {
			setMessageShown(true);
			dispatch(validationAction({ params: validationActionParams }))
		}
    }
    const handleApprove = () => {
		if(envs.admin) {
			setDisabledApprove(true);
			if(envs.accountType === 'BUSINESS') {
				dispatch(approveCompanyToggle({params: {companyId: envs.profileId}, data: {approve: true}}))
			} else {
				dispatch(approveIndividualToggle({params: {individualId: envs.profileId}, data: {approve: true}}))
			}
		}
	}
	const status = envs.accountType === 'BUSINESS' ? userProfile?.profile?.reviewStatus : individual?.individual?.reviewStatus;
	let Next = <ButtonUpper btnType="primary" ghost={nextButton !== 'primary' ? true : false}>{nextName || 'Next step'}</ButtonUpper>;
	let Prev = <ButtonUpper onClick={handleVisibleChange} btnType="primary" ghost>{prevName || 'Back'}</ButtonUpper>;
	return (
		<div>
			<FormSaver onConfirm={onConfirm} onConfirmLink={onConfirmLink} />
	      	<NavSpace direction='vertical' size={'small'}>
	      		{(status === 'Approved' || status === 'SubmittedForReview') && step === 0 ? (
	      			<Row>
		    			<Col md={24}>
		    				<Well>
	    						<b>If your details change, you can make changes to KYC.</b>
	    						<p>Please note that making some changes may prevent you from using Bitclear Applications until Bitclear AG approves the changes.</p>
	    						<Row justify="end">
				    				<Link to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + next : envs.routeSuffix + next}>
				    					<ButtonUpper btnType="primary" ghost={true}>Go to KYC</ButtonUpper>
				    				</Link>
			    				</Row>
			      		 	</Well>
		    			</Col>
	    			</Row>
	      		) : (
	      			<div>
			    		<Row>
			    			<Col md={12}>
			    				{prev &&
			    					<>
				    					{formUpdated.updated ? (
				    						<ButtonWrapper popupVisible={popupPrevVisible} confirm={() => confirm('prev')} cancel={cancel} handleVisibleChange={() => handleVisibleChange('prev')}>
							    				{Prev}
						    				</ButtonWrapper>
				    					) : (
				    						<Link to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + prev : envs.routeSuffix + prev}>
						    					{Prev}
						    				</Link>
				    					)}
			    					</>
			    					
				    			}
			    			</Col>
			    			<ColRight>
								<>
									{archive &&
										<Popconfirm
                                            title="Are You sure you want to archive this KYC profile?"
                                            visible={popupArchiveVisible}
                                            onVisibleChange={handleArchiveVisibleChange}
                                            onConfirm={confirmArchive}
                                            onCancel={cancelArchive}
                                            okText="Archive KYC"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper btnType="primary" className='left' ghost>Archive KYC</ButtonUpper>
                                        </Popconfirm>
									}
									{restore &&
										<Popconfirm
                                            title="Are You sure you want to restore this KYC profile?"
                                            visible={popupArchiveVisible}
                                            onVisibleChange={handleArchiveVisibleChange}
                                            onConfirm={confirmRestore}
                                            onCancel={cancelArchive}
                                            okText="Restore KYC"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper btnType="primary" className='left' ghost>Restore KYC</ButtonUpper>
                                        </Popconfirm>
									}
									{next &&
				    					<>
				    						{formUpdated.updated ? (
				    							<>
				    								{prev && <ButtonUpper btnType="text" className='left' onClick={validateParams} disabled>Validate step</ButtonUpper>}
					    							<ButtonWrapper popupVisible={popupNextVisible} confirm={() => confirm('next')} cancel={cancel} handleVisibleChange={() => handleVisibleChange('next')}>
									    				{Next}
								    				</ButtonWrapper>
							    				</>
				    						) : (
				    							<>
					    							{prev && <ButtonUpper btnType="text" className='left' onClick={validateParams}>Validate step</ButtonUpper>}
					    							<Link to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + next : envs.routeSuffix + next}>
								      		 			{Next}
								      		 		</Link>
							      		 		</>
				    						)}
					    				
					      		 		</>
					      		 	}
					      		 	{approve &&
						      		 	<ButtonUpper btnType="primary" size='small' onClick={handleApprove} disabled={disabledApprove}>Approve KYC</ButtonUpper>
						      		}
								</>
			      		 	</ColRight>
			    		</Row>
		    		</div>
	    		)}
	      	</NavSpace>
      	</div>
				      
	)
}

export default KycNav
