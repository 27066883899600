import axios, { AxiosPromise } from 'axios'
import { SessionResponse, UserInfoResponse } from './types'

const KEYCLOAK = {
	appName: 'frontoffice',
}
//const CLIENT_ID = `fx-${KEYCLOAK.appName}-client`
export const getBasePath = (isBackend: boolean) => `/realms/${isBackend ? process.env.REACT_APP_KEYCLOAK_REALM_BACK : process.env.REACT_APP_KEYCLOAK_REALM_FRONT }/protocol/openid-connect`

//export const KEYCLOAK_LOGIN_URL = `${BASE_PATH}/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=`
//export const KEYCLOAK_REGISTER_URL = `${BASE_PATH}/registrations?response_type=code&client_id=${CLIENT_ID}&redirect_uri=`
export const getKeycloakTokenUrl = (isBackend: boolean) => `${getBasePath(isBackend)}/token`
export const getKeycloakLogoutUrl = (isBackend: boolean) => `${getBasePath(isBackend)}/logout`
export const getKeycloakUserInfo = (isBackend: boolean) => `${getBasePath(isBackend)}/userinfo`

export interface IGetTokenParams {
	keycloakDomain: string
	code: string
	redirect_uri: string,
	client_id: string,
	is_backend: boolean
}

export const getToken = ({ keycloakDomain, code, redirect_uri, client_id, is_backend}: IGetTokenParams): AxiosPromise<SessionResponse> => {
	return axios.post(
		`${keycloakDomain}${getKeycloakTokenUrl(is_backend)}`,
		new URLSearchParams({
			client_id: client_id,
			grant_type: 'authorization_code',
			code,
			redirect_uri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}

export interface ILogoutParams {
	keycloakDomain: string
	refreshToken?: string,
	client_id: string,
	is_backend: boolean
}

export const logout = ({ keycloakDomain, refreshToken = '', client_id, is_backend }: ILogoutParams): Promise<void> => {
	return refreshToken
		? axios.post(
				`${keycloakDomain}${getKeycloakLogoutUrl(is_backend)}`,
				new URLSearchParams({
					client_id: client_id,
					refresh_token: refreshToken,
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
		  )
		: Promise.reject()
}

export interface IRefreshTokenParams {
	keycloakDomain: string
	refreshToken?: string,
	client_id: string,
	is_backend: boolean
}

export const refreshToken = ({ refreshToken = '', keycloakDomain, client_id, is_backend }: IRefreshTokenParams): AxiosPromise<SessionResponse> => {
	return axios.post(
		`${keycloakDomain}${getKeycloakTokenUrl(is_backend)}`,
		new URLSearchParams({
			client_id: client_id,
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}

export interface IUserInfoParams {
	keycloakDomain: string
	accessToken?: string,
	is_backend: boolean
}

export const getUserInfo = ({ accessToken = '', keycloakDomain, is_backend }: IUserInfoParams): AxiosPromise<UserInfoResponse> => {
	return axios.post(
		`${keycloakDomain}${getKeycloakUserInfo(is_backend)}`,
		new URLSearchParams({
			access_token: accessToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}
