import { Spin, Divider, Row, Col } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DownOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { FC, useEffect, useState,  ReactNode} from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import Title from 'antd/es/typography/Title'
import KycLayout from '../kyc-layout/KycLayout'
import KycAuthorizedPersonsForm from '../forms/KycAuthorizedPersonsForm'
import { themeColor, themeWeight } from '../../../styles/themeStyles'
import { ButtonUpper } from '../ui-elements/Buttons'
import styled from 'styled-components'
import KycNextSteps from '../KycNextSteps'
import { PageContent } from '../../layout/PageContent'
import FormSelect from '../ui-elements/FormSelect'
import KycDataValidationForm from '../forms/KycDataValidationForm'
import { SuccessInfo } from '../ui-elements/SuccessInfo'

interface IProps {
	profile: any,
	profileGet: any,
	authorizedPersonsGet: any,
	statusUpdate: any
}

interface SProps {
	status: any,
	showSuccess: boolean, 
	isFullfilled?: boolean, 
	envs: any,
	children: ReactNode
}

export const KycSuccessWrapper: FC<SProps> = ({status, showSuccess, isFullfilled, envs, children}) => {
	useEffect(() => {
		if(status === 'SubmittedForReview' && showSuccess === true) {
			window.scrollTo(0, 0);
		}
	}, [status === 'SubmittedForReview'])
	return (
	<>
	{(status === 'SubmittedForReview' && showSuccess === true) ? (
			<PageContent>
				<Row>
					<Col>
						<SuccessInfo additionalContent={<KycNextSteps />} linkEl={envs.type === 'standalone' ? envs.routeSuffix + '/summary' : envs.routeSuffix + '/'} linkText="Go to Summary">
							<p>Your form was sucessfully sent</p>
							<p>Our team will review Your form and get back to You shortly</p>
						</SuccessInfo>
					</Col>
				</Row>
			</PageContent>
			) : (
				<>
					{isFullfilled ? (
						<>
			      			{children}
			      		
						</>
		      		) : (
		      			<Spin size="large" />
		      		)}
				</>
			)}
		</>
	)
      		
}

const KycDataValidationListing: FC<IProps> = ({
	profile, profileGet, authorizedPersonsGet, statusUpdate
}) => {
	const envs = useSelector((state) => state.general.envs);
	const [showSuccess, setShowSucces] = useState(false);
	const { userProfile } = profile;
	const { isPending, isError, isFullfilled } = useActionStatus(authorizedPersonsGet);
	const formStatus = useActionStatus(statusUpdate);
	useEffect(() => {
        dispatch(authorizedPersonsGet({ params: { companyId: envs.profileId} }));
        dispatch(profileGet({ params: { companyId: envs.profileId} }));
    }, []);
    useEffect(() => {
    	if(formStatus.isFullfilled && showSuccess === false) {
    		setShowSucces(true);
    	}
    }, [formStatus.isFullfilled]);
    let reviewStatus = 'Approved';
	return (
		<>
	      	<KycLayout pageName='Step 4' heading='Data validation and printout' nav={{step: 4, prev:'/step3', hideNav: true, navSubpage: 'kyc'}}>
				<KycSuccessWrapper status={userProfile?.profile?.reviewStatus} showSuccess={showSuccess} isFullfilled={isFullfilled} envs={envs}>
					<KycDataValidationForm />
				</KycSuccessWrapper>
			</KycLayout>
		</>
			 
	)
}

export default KycDataValidationListing
