import { FC } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { authorizedPersonsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonsGet'
import { statusUpdate } from '../../../store/kyc-front/asyncThunks/statusUpdate'
import KycDataValidationListing from '../listings/KycDataValidationListing'

const KycDataValidation: FC = () => {
	const profile = useSelector((state) => state.user.profile);
	return (
      	<KycDataValidationListing profile={profile} profileGet={profileGet} authorizedPersonsGet={authorizedPersonsGet} statusUpdate={statusUpdate} /> 
	)
}

export default KycDataValidation
