import { FC } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import KycUbosListing from '../../kyc-front/listings/KycUbosListing'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { authorizedPersonsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackGet'
import { ubosBackGet } from '../../../store/kyc-backend/asyncThunks/ubosBackGet'
import { uboBackCreate } from '../../../store/kyc-backend/asyncThunks/uboBackCreate'
import { uboBackUpdate } from '../../../store/kyc-backend/asyncThunks/uboBackUpdate'
import { uboBackArchive } from '../../../store/kyc-backend/asyncThunks/uboBackArchive'
import { uboBackDocUrlGet } from '../../../store/kyc-backend/asyncThunks/uboBackDocUrlGet'
import { uboBackDocsGet } from '../../../store/kyc-backend/asyncThunks/uboBackDocsGet'
import { uboBackDocCreate } from '../../../store/kyc-backend/asyncThunks/uboBackDocCreate'
import { uboBackDocArchive } from '../../../store/kyc-backend/asyncThunks/uboBackDocArchive'
import { uboBackCreateClear, uboBackUpdateClear, uboBackArchiveClear } from '../../../store/kyc-backend/slices/ubosSlice'
import { uboBackDocArchiveClear } from '../../../store/kyc-backend/slices/uboDocsSlice'
import { ubosBackValidate } from '../../../store/kyc-backend/asyncThunks/ubosBackValidate'
import { ubosBackValidateClear } from '../../../store/kyc-backend/slices/statusSlice'

const KycUbos: FC = () => {
	const company = useSelector((state) => state.admin.backCompany);
	const backAuthorizedPersons = useSelector((state) => state.admin.backAuthorizedPersons);
	const backUbos = useSelector((state) => state.admin.backUbos);
	return (
		<KycUbosListing 
			profile={company.profile} 
			profileGet={companyProfileBackGet} 
			authorizedPersons={backAuthorizedPersons} 
			authorizedPersonsGet={authorizedPersonsBackGet} 
			ubos={backUbos} 
			ubosGet={ubosBackGet} 
			uboCreate={uboBackCreate} 
			uboClearUpdate={uboBackUpdateClear}
			uboDocsGet={uboBackDocsGet}
			uboDocCreate={uboBackDocCreate}
    		docUrlGet={uboBackDocUrlGet}
    		uboDocArchive={uboBackDocArchive}
			uboDocArchiveClear={uboBackDocArchiveClear}
			uboUpdate={uboBackUpdate} 
			uboClearCreate={uboBackCreateClear} 
			uboArchive={uboBackArchive}
			uboClearArchive={uboBackArchiveClear}
			validationAction={ubosBackValidate} 
           	validationActionClear={ubosBackValidateClear}
		/>	 
	)
}

export default KycUbos
