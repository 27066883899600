import { Formik, Form, useFormikContext } from 'formik'
import _ from 'lodash';
import * as Yup from 'yup'
import { FC, useState, useEffect, ReactNode } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { Row, Col, Divider, Space, Popconfirm, message, Result, Typography } from 'antd'
import { CloseCircleOutlined, DownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { moment } from '../ui-elements/FormDate'
import { dispatch } from '../../../store/store'
import { readyForReviewValidate } from '../../../store/kyc-front/asyncThunks/readyForReviewValidate'
import { individualReadyForReviewValidate } from '../../../store/kyc-front/asyncThunks/individualReadyForReviewValidate'
import { readyForReviewBackValidate } from '../../../store/kyc-backend/asyncThunks/readyForReviewBackValidate'
import { individualBackReadyForReviewValidate } from '../../../store/kyc-backend/asyncThunks/individualBackReadyForReviewValidate'
import { statusUpdate } from '../../../store/kyc-front/asyncThunks/statusUpdate'
import { userCreate } from '../../../store/kyc-front/asyncThunks/userCreate'
import { individualStatusUpdate } from '../../../store/kyc-front/asyncThunks/individualStatusUpdate'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { statusUpdateClear, individualStatusUpdateClear } from '../../../store/kyc-front/slices/statusSlice'
import { companyApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/companyApprovedFieldsGet'
import { individualApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/individualApprovedFieldsGet'
import { adminProfileGet } from '../../../store/kyc-backend/asyncThunks/adminProfileGet'
import { redirectToError } from '../../../store/kyc-front/slices/errorsSlice'
import { setFormUpdated, setCurrentEditedForm } from '../../../store/kyc-front/slices/navSlice'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'
import { ButtonUpper, ButtonWrapper } from '../ui-elements/Buttons'
import { ErrorElement, ErrorParagraph } from '../ui-elements/ErrorElement'
import { ColRight } from '../ui-elements/Columns'
import { FormSaver } from '../../../utilities/FormSaver'

const { Paragraph, Text } = Typography

const CustomLinkSpan = styled.span`
    cursor: pointer;
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
`
const CustomLink = styled.span`
    cursor: pointer;
`

const LinkEl = styled(Link)`
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
`

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

const StickyNav = styled.div`
    position: sticky;
    margin-bottom: -17px;
    border-top: 2px solid ${themeColor.grayWhite};
    border-bottom: 2px solid ${themeColor.grayWhite};
    &.bottom {
        bottom: 0;
    }
    &.no-stick {
        position: static;
        bottom: 0;
        border: 0;
    }
    &#formFixed {
        .sticky-content {
            position: fixed;
            bottom: 0;
            width: 952px;
        }
    }
`

const StickyContent = styled.div`
    margin-left: -30px;
    margin-right: -30px;
    padding: 15px 30px;
    background-color: #fff;
`

Yup.setLocale({
    mixed: {
        required: 'Field is required',
        default: 'Value is invalid',
        notType: ({ path, type, value, originalValue }) => `Value should be a ${type}`
    },
    number: {
        integer: 'Value should be a number',
        positive: 'Value should be positive number',
        min: 'Value should be at min ${min}',
    }
});

interface FProps {
    id: string,
    type?:  'apForm' | 'uboForm',
    isUbo?: boolean,
    initial: object,
    initialErrors?: object,
    validation: any,
    //dispatchSubmit: (props: any) => Action,
    dispatchSubmit?: any,
    params?: object,
    dataFormat? : any,
    ommitFields?: Array<string>,
    dispatchClear?: any,
    dispatchGet?: any,
    successCondition: string,
    saveInnerForm?: boolean,
    deleteForm?: boolean,
    restoreForm?: boolean,
    validateButton?: boolean,
    deleteEnabled?: boolean,
    finalSubmit?: boolean,
    loginSubmit?: boolean,
    registerSubmit?: boolean,
    saveText?: string,
    saveDraft?: boolean,
    deleteAction?: any,
    dispatchDeleteClear?: any,
    deleteSuccessCondition?: boolean,
    restoreSuccessCondition?: boolean,
    transformBools?: boolean,
    printRender?: boolean,
    validationAction?: any,
    validationActionClear?: any,
    validatedElem?: any,
    standaloneFormSubmit?: boolean,
    responseState?: any,
    editable?: boolean,
    hideOnDisabled?: boolean,
    validateOnBlur?: boolean,
    children: (props: any) => ReactNode
}
interface OProps {
    id: string
}

export const scrollToErrors = (id: string) => {
    // @ts-ignore
    document.getElementById(id).querySelectorAll('.error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const FormObserver: FC<OProps>  = ({id}) => {
  const { values } = useFormikContext();
  useEffect(() => {
    dispatch(setCurrentEditedForm({values: values, id: id}));
  }, [values]);
  return null;
};

function getOffset(el: any) {
  const rect = el?.getBoundingClientRect();
  return {
    left: rect?.left + window.scrollX,
    top: rect?.top + window.scrollY
  };
}

const BasicFormWrapper: FC<FProps> = ({
    id, type, isUbo, initial, validation, dispatchSubmit, params, dataFormat, ommitFields, dispatchClear, dispatchGet, successCondition, saveInnerForm, deleteForm, restoreForm, deleteEnabled, validateButton, finalSubmit, loginSubmit, registerSubmit, saveText, saveDraft, deleteAction, dispatchDeleteClear, deleteSuccessCondition, restoreSuccessCondition, transformBools, printRender, validationAction, validationActionClear, validatedElem, standaloneFormSubmit, responseState, editable, hideOnDisabled, validateOnBlur, children
}) => {
    const envs = useSelector((state) => state.general.envs);
    const [formChanged, setFormChanged] = useState(false);
    const [formDisabled, setDisabledForm] = useState(false);
    const [messageShown, setMessageShown] = useState(finalSubmit || loginSubmit || registerSubmit ? false : true);
    const [messageNoErrorsShown, setMessageNoErrorsShown] = useState(false);
    const [validationSuccessMessage, setValidationSuccessMessage] = useState(false);
    let [popupVisible, setPopupVisible] = useState(false);
    let [popupEditVisible, setPopupEditVisible] = useState(false);
    let [deletePopupVisible, setDeletePopupVisible] = useState(false);
    let [popupBackVisible, setPopupBackVisible] = useState(false);
    let [errorsListing, setErrorsListing] = useState(false);
    let [visibleErrors, setVisibleErrors] = useState<string[]>([]); 
    let [disabled, setDisabled] = useState(false);
    let [isDeleting, setDeleting] = useState(false);
    const [initialErrors, setInitialErrors] = useState({});
    const [errorsLoaded, setErrorsLoaded] = useState(false);
    const nav = useSelector((state) => state.general.nav);
    const status = useSelector((state) => state.user.status);
    const statusBack = useSelector((state) => state.admin.backStatus);
    const profile = useSelector((state) => state.user.profile);
    const backCompany = useSelector((state) => state.admin.backCompany);
    const errorsState = useSelector((state) => state.general.errorsState);
    const [onConfirm, setOnConfirm] = useState(false);
    const [onConfirmLink, setOnConfirmLink] = useState('')
    const { userProfile } = profile;
    const individual = useSelector((state) => state.user.individual);
    const backIndividual = useSelector((state) => state.admin.backIndividual);
    const admin = useSelector((state) => state.admin.admin);
    let {prev} = nav.nav;
    let {formUpdated, currentEditedForm} = nav;
    const { isPending, isError, isFullfilled } = useActionStatus(dispatchSubmit);
    const actionValidationStatus = useActionStatus(validationAction);
    const deleteEl = useActionStatus(deleteAction);
    const finalSubmitStatus = useActionStatus(envs.accountType === 'BUSINESS' ? statusUpdate : individualStatusUpdate);
    const approvesGetStatus = useActionStatus(envs.accountType === 'BUSINESS' ? companyApprovedFieldsGet : individualApprovedFieldsGet);
    const [fixedNavPos, setFixedNavPos] = useState(true);
    const [contentWidth, setContentWidth] = useState(0);
    useEffect(() => {
        const onScroll = () => {
            let topPos = window.pageYOffset + 600;
            let formPos = getOffset(document.getElementById("dataValidationForm")).top;
            let res = topPos - formPos;
            if(res <= 0) {
                setFixedNavPos(true);
            }
            if(res > 0) {
                setFixedNavPos(false);
            }
        };
        if(envs.accountType === 'PRIVATE' && id === 'dataValidationForm') {
            // clean up code
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, { passive: true });
        }  
        //@ts-ignore
        setContentWidth(document?.getElementById("pageWrapper") ? document?.getElementById("pageWrapper").offsetWidth : 0);      
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
    const setUpErrors = (validatedEl: any) => {
        let errors = {};
        let errorsElem;
        if(validatedEl) {
            if(Array.isArray(validatedEl)) {
                validatedEl.forEach((elem: any) => {
                    if(elem.entry === id) {
                        errorsElem = elem.validationErrors;
                        return;
                    }
                })
            } else {
                errorsElem = validatedEl.validationErrors;
            }
            if(errorsElem) {
                if(errorsElem && errorsElem.length) {
                    //@ts-ignore
                    errorsElem.forEach((error: any) => {
                        if(error.ref === 'missing-passport-document') {
                            //@ts-ignore
                            errors.PassportFront = true
                        }
                        if(error.ref === 'missing-front end back-of-id-card') {
                            //@ts-ignore
                            errors.IDCardFront = true
                            //@ts-ignore
                            errors.IDCardBack = true
                        }
                        if(error.ref === 'missing-front-of-the-id-card') {
                            //@ts-ignore
                            errors.IDCardFront = true
                        }
                        if(error.ref === 'missing-back-of-the-id-card') {
                            //@ts-ignore
                            errors.IDCardBack = true
                        }
                        if(error.ref === 'proof-of-address') {
                            //@ts-ignore
                            errors.ProofOfAddress = true
                        }
                        if(error.ref === 'proof-of-address') {
                            //@ts-ignore
                            errors.ProofOfAddress = true
                        }
                        /*if(error.ref === 'identityDocument' && error.slug === 'nationality') {
                            //@ts-ignore
                            if(!errors.identityDocument) {
                                //@ts-ignore
                                errors.identityDocument = {};
                            }
                            //@ts-ignore
                            if(!errors.identityDocument.Passport) {
                                //@ts-ignore
                                errors.identityDocument.Passport = {};
                            }
                            //@ts-ignore
                            if(!errors.identityDocument.NationalIDCard) {
                                //@ts-ignore
                                errors.identityDocument.NationalIDCard = {};
                            }
                            //@ts-ignore
                            errors.identityDocument.Passport.nationality = 'Field is required';
                            //@ts-ignore
                            errors.identityDocument.NationalIDCard.nationality = 'Field is required';
                        } else {*/
                            if(error.ref !== '') {
                                //@ts-ignore
                                if(errors[error?.ref]) {
                                    //@ts-ignore
                                    errors[error?.ref][error?.slug] = 'Field is required'
                                } else {
                                    //@ts-ignore
                                    errors[error?.ref] = {};
                                    //@ts-ignore
                                    errors[error?.ref][error?.slug] = 'Field is required'
                                }
                            } else {
                                //@ts-ignore
                                errors[error.slug] = 'Field is required'
                            }
                        //}
                        
                        
                    })
                    let fieldName = '';
                    if(errorsState) {
                        //@ts-ignore
                        fieldName = errorsState?.error?.ref ? errorsState?.error?.ref + '.' + errorsState?.error?.slug : errorsState?.error?.slug;
                    }
                    if(Array.isArray(validatedEl)) {
                        setInitialErrors({personalDetails: errors});
                        let timer;
                        let timerTimeout = 400;
                            if(errorsState.error !== undefined && !_.isEmpty(errorsState.error)) {
                                {/*if (errorsState.formName === id) {*/}
                                    timer = setTimeout(() => scrollToErrors('root'), timerTimeout);
                                {/*}}*/}
                            } else {
                                timer = setTimeout(() => scrollToErrors('root'), timerTimeout);
                            }
                        
                        
                        
                    } else {
                        if(envs.accountType === 'PRIVATE' && nav.nav.step !== 2) {
                            setInitialErrors({personalDetails: errors});
                        } else {
                            setInitialErrors(errors);
                        }
                        let timer;
                        let timerTimeout = 400;
                            if(errorsState.error !== undefined && !_.isEmpty(errorsState.error)) {
                                {/*if(errorsState.formName === id) {*/}
                                    timer = setTimeout(() => scrollToErrors('root'), timerTimeout);
                                {/*}}*/}
                            } else {
                                timer = setTimeout(() => scrollToErrors('root'), timerTimeout);
                            }
                        
                    }
                    dispatch(validationActionClear({}));
                    dispatch(redirectToError({}));
                } else {
                    if(actionValidationStatus.isFullfilled) {
                        setInitialErrors({});
                    }
                }
            }
        }
    }
    /*useEffect(() => {
    }, [errorsState])*/
    useEffect(() => {
        if(!_.isEmpty(errorsState) && (errorsState.formName === id)) {
            if(envs.accountType === 'BUSINESS') {
                dispatch(validationAction({ params: { companyId: envs.profileId } }));
            } else {
                 dispatch(validationAction({ params: { individualId: envs.profileId } }));
            }
            
        }
    }, [errorsState])
    useEffect(() => {
        if(envs.accountType === 'BUSINESS') {
            if(envs.admin) {
                if(backCompany?.profile?.companyId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(companyProfileBackGet({ params: { companyId: envs.profileId } }));
                }
            } else {
                if(userProfile.companyId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(profileGet({ params: { companyId: envs.profileId } }));
                }
            }
        } else {
            if(envs.admin) {
                if(backIndividual.individual.individualId === '' && !registerSubmit && !loginSubmit)  {
                    dispatch(individualProfileBackGet({ params: { individualId: envs.profileId } }));
                }
            } else {
                if(individual.individual.individualId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(individualGet());
                }
            }    
        }

    }, [])
    useEffect(() => {
        let reviewStatus = envs.admin ? backCompany?.profile?.profile?.reviewStatus : userProfile?.profile?.reviewStatus;
        if((reviewStatus === 'SubmittedForReview' || reviewStatus === 'Approved') && (disabled === false) && id !== 'loginForm' && id !== 'registerForm') {
            setDisabled(true);
        }
         if((reviewStatus === 'PartiallyFilled') && (disabled === true)) {
            setDisabled(false);
        }
    }, [userProfile])
    useEffect(() => {
        let reviewStatus = envs.admin ? backIndividual?.individual?.reviewStatus : individual?.individual?.reviewStatus;
        if((reviewStatus === 'SubmittedForReview' || reviewStatus === 'Approved') && (disabled === false) && id !== 'loginForm' && id !== 'registerForm') {
            setDisabled(true);
        }
        if((reviewStatus === 'PartiallyFilled') && (disabled === true)) {
            setDisabled(false);
        }
    }, [individual])
    useEffect(() => {
        setUpErrors(validatedElem); 
    }, [validatedElem])
    useEffect(() => {
        if(envs.admin && admin.profile.adminId === '' && id !== 'loginForm' && id !== 'registerForm') {
            dispatch(adminProfileGet({params: null}));
        }
    }, [admin]);
    useEffect(() => {
        if(messageShown) {
            if(finalSubmit) {
                if(finalSubmitStatus.isFullfilled) {
                    if(envs.accountType === 'BUSINESS') {
                        let stat = status.updatedStatus;
                        //@ts-ignore
                        if(stat.companyProfileValidated.validationErrors.length || 
                            stat?.numberOfBeneficialOwnersValidated?.validationErrors?.length || 
                            stat?.numberOfDirectorsValidated?.validationErrors?.length || 
                            stat?.numberOfSignatoryValidated?.validationErrors?.length || 
                            //@ts-ignore
                            _.find(stat?.beneficialOwnersValidated, function(ubo) { return ubo?.validationErrors?.length > 0}) || 
                            //@ts-ignore
                            _.find(stat?.authorizedPersonsValidated, function(ap) { return ap?.validationErrors?.length > 0})) 
                        {
                            message.error('Some error occured while posting this form');
                            //dispatch(dispatchClear({}));
                            //dispatch(statusUpdateClear({}));
                            setMessageShown(false);
                            setErrorsListing(true)
                        } else {
                            dispatch(statusUpdateClear({}));
                            if(dispatchGet) {
                                dispatch(dispatchGet({ params: { companyId: envs.profileId } }))
                            } 
                            if(envs.admin) {
                                dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                            } else {
                                dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                            }
                            setMessageShown(false);
                            setErrorsListing(false)
                        }

                    } else {
                        let indStat = status.individualUpdatedStatus;
                        if(indStat?.beneficialOwnerValidated?.validationErrors?.length || indStat?.declarationValidated?.validationErrors?.length) {
                            message.error('Some error occured while posting this form');
                            setMessageShown(false);
                            setErrorsListing(true)
                        } else {
                            dispatch(individualStatusUpdateClear({}));
                            if(dispatchGet) {
                                dispatch(dispatchGet({ params: { individualId: envs.profileId } }))
                            }
                            if(envs.admin) {
                                dispatch(individualBackReadyForReviewValidate({params: {individualId : envs.profileId}}))
                            } else {
                                dispatch(individualReadyForReviewValidate({params : null}))
                            }
                            
                            setMessageShown(false);
                            setErrorsListing(false);
                        }
                    }
                }
                if(finalSubmitStatus.isError) {
                    message.error('Some error occured while posting this form');
                    dispatch(dispatchClear({}));
                    dispatch(statusUpdateClear({}));
                    dispatch(individualStatusUpdateClear({}));
                    setMessageShown(false);
                }
            }
            if(isFullfilled && (successCondition !== '')) {
                if(finalSubmit) {
                    if(envs.accountType === 'BUSINESS') {
                        if(envs.admin) {
                            dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                            dispatch(statusUpdate({ params: { companyId: envs.profileId } }));
                        } else {
                            dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                        }
                    } else {
                        if(envs.admin) {
                            dispatch(individualBackReadyForReviewValidate({params: {individualId : envs.profileId}}));
                            dispatch(individualStatusUpdate());
                        } else {
                            dispatch(individualReadyForReviewValidate({params : null}))
                        }
                    }
                    if(dispatchClear) dispatch(dispatchClear({}));
                } else {
                    message.success('Form was sucessfully saved');
                    if(dispatchClear) dispatch(dispatchClear({}));
                    if(dispatchGet) {
                        if(envs.accountType === 'BUSINESS') {
                            dispatch(dispatchGet({ params: { companyId: envs.profileId } }));
                            if(envs.admin) {
                                dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                            } else {
                                dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                            }
                            if(actionValidationStatus.isCleared) {
                                dispatch(validationAction({ params: { companyId: envs.profileId } }));
                            }
                        } else {
                            dispatch(dispatchGet({ params: { individualId: envs.profileId } }));
                            
                            if(envs.admin) {
                                dispatch(dispatchGet({ params: { individualId: envs.profileId } }))
                                dispatch(individualBackReadyForReviewValidate({params : {individualId: envs.profileId}}))
                            } else {
                                dispatch(dispatchGet({}))
                                dispatch(individualReadyForReviewValidate({params : null}))
                            }
                            if(actionValidationStatus.isCleared) {
                                dispatch(validationAction({ params: { individualId: envs.profileId } }));
                            }
                        }
                    }
                    setMessageShown(false);
                }
                
            }
            if(isError) {
                message.error('Some error occured while saving this form');
                if(dispatchClear) dispatch(dispatchClear({}));
                setMessageShown(false);
            }
            if(deleteEl?.isFullfilled && isDeleting) {
                if(deleteSuccessCondition) {
                    message.success('Element was successfully deleted');
                } else {
                    message.success('Element was successfully restored');
                }
                dispatch(dispatchDeleteClear({}));
                if(dispatchGet) {
                    dispatch(dispatchGet({ params: { companyId: envs.profileId } }))
                }
                if(envs.accountType === 'BUSINESS') {
                    if(envs.admin) {
                        dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                    } else {
                        dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                    }
                } else {
                    if(envs.admin) {
                        dispatch(individualBackReadyForReviewValidate({params: {individualId : envs.profileId}}))
                    } else {
                        dispatch(individualReadyForReviewValidate({params : null}))
                    }
                }
                setMessageShown(false);
                setDeleting(false);
            }
            if(deleteEl?.isError) {
                message.error('Some error occured while deleting this element');
                dispatch(dispatchDeleteClear({}));
                setMessageShown(false);
            }
        }
        
    })
    useEffect(() => {
        return () => {
            if(dispatchClear) dispatch(dispatchClear({}));
        };
      }, []);
    useEffect(() => {
        if(errorsListing && finalSubmit) {
            //@ts-ignore
            document.getElementById("errorSummaryUser").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [errorsListing]);
    useEffect(() => {
        if(actionValidationStatus.isFullfilled && !actionValidationStatus.isCleared) {
            if(envs.accountType === 'PRIVATE' && nav.nav.step === 2) {
                if(status?.individualDeclarationsValidated?.validationErrors?.length === 0 && messageNoErrorsShown) {
                    message.success('Validation completed! No errors found');
                    setMessageNoErrorsShown(false)
                }
            }
        }
    }, [status]);
    useEffect(() => {
        if(actionValidationStatus.isFullfilled && !actionValidationStatus.isCleared) {
            if(envs.accountType === 'PRIVATE' && nav.nav.step === 2) {
                if(statusBack?.individualDeclarationsValidated?.validationErrors?.length === 0 && messageNoErrorsShown) {
                    message.success('Validation completed! No errors found');
                    setMessageNoErrorsShown(false)
                }
            }
        }
    }, [statusBack]);
    const formChangedUpdate = (update: boolean) => {
        if(update) {
            if(formChanged === false) {
                setFormChanged(true)
                dispatch(setFormUpdated({updated: true, form: [id], formName: type}));
            }
            if(formDisabled) {
                setDisabledForm(false)
            }
        } else {
           setFormChanged(false)
           dispatch(setFormUpdated({updated: false, form: []}));
        }
       
    }
    const finalSubmitAction = () => {
        if(envs.accountType === 'BUSINESS') {
            dispatch(statusUpdate({ params: { companyId: envs.profileId } }))
        }
        if(envs.accountType === 'PRIVATE') {
            dispatch(individualStatusUpdate())
        }
        setMessageShown(true);
        setDisabledForm(true);
    }


    const handleInputChange = (el: string) => {
        formChangedUpdate(true)
    }
    const cancel = () => {
        setPopupVisible(false);
    }
    const cancelBack = () => {
        setPopupBackVisible(false);
    }
    const cancelDelete = () => {
        setDeletePopupVisible(false);
    }
    const cancelEdit = () => {
        setPopupEditVisible(false);
    }
    const navBack = (link?: string) => {
        if(link) {
            setOnConfirm(true);
            setOnConfirmLink(link);
        }
    }
    const handleFormDelete = (restore?: boolean) => {
        dispatch(setFormUpdated({updated: true, form: [id], formName: type}));
        setDeleting(true);
        setMessageShown(true);
        if(isUbo) {
            dispatch(deleteAction({ params: { companyId: envs.profileId, beneficialOwnerId: id}, data: {archive: restore ? false : true}}));
        } else {
            dispatch(deleteAction({ params: { companyId: envs.profileId, authorizedPersonId: id}, data: {archive: restore ? false : true}}));
        }
        
    }
    const handleVisibleChange = () => {
        setPopupVisible(!popupVisible);
    }
    const handleDeleteVisibleChange = () => {
        setDeletePopupVisible(!deletePopupVisible)
    }
    const handleBackVisibleChange = () => {
        setPopupBackVisible(!popupBackVisible);
    }
    const handleEditVisibleChange = () => {
        setPopupEditVisible(!popupEditVisible);
    }
    const showErrors = (el: string) => {
        if(visibleErrors.includes(el)) {
            setVisibleErrors(visibleErrors.filter(elem => elem !== el))
        } else {
            setVisibleErrors((visibleErrors) => [...visibleErrors, el])
        }
        
    }
    const validateStepAction = () => {
        if(envs.accountType === 'BUSINESS') {
            dispatch(validationAction({ params: { companyId: envs.profileId } }));
        } else {
            dispatch(validationAction({ params: { individualId: envs.profileId } }));
            if(nav.nav.step === 2) {
                setMessageNoErrorsShown(true);
            }
        }
        
    }
    //@ts-ignore
    const onSubmit = (values, silent) => {
        setDisabledForm(true)
        formChangedUpdate(false)
        let submitValues = {...values};
        if(ommitFields) {
            ommitFields.forEach((el: string) => {
                delete submitValues[el as keyof typeof submitValues];
            })
        }
        if(transformBools) {
            Object.entries(submitValues).forEach(([i, value]) => {
                if(value === 'true') {
                    // @ts-ignore
                    submitValues[i as keyof typeof submitValues] = true;
                }
                if(value === 'false') {
                    // @ts-ignore
                    submitValues[i as keyof typeof submitValues] = false;
                    //delete submitValues[i as keyof typeof submitValues];
                }
            });
        }
        if(dispatchSubmit) {
            dispatch(
                dispatchSubmit({
                    params: params,
                    data: dataFormat ? dataFormat(submitValues) : submitValues
                }),
            )
        }
        
        if(!silent) {
            setMessageShown(true);
        }
        
    }
    return (
        <>
            <FormSaver onConfirm={onConfirm} onConfirmLink={onConfirmLink} />
            <Formik
                initialValues={initial}
                validateOnBlur={validateOnBlur ? validateOnBlur : false}
                validateOnChange={false}
                validateOnMount={false}
                initialErrors={initialErrors}
                onReset={() => {
                   //console.log('reset');
                }}
                enableReinitialize={true}
                validationSchema={validation}
                onSubmit={(values) => {
                    onSubmit(values, false) 
                }}>
                {({ validateForm, validateField, resetForm, errors, touched, isValid, isValidating, values, handleChange, setFieldValue, setErrors, setFieldError, initialErrors }) => {
                    
                    const onSelectChange = (value: string, type: string) => {
                        if(Array.isArray(value)) {
                            setFieldValue(type, value.join());
                        } else {
                            setFieldValue(type, value);
                        }
                        formChangedUpdate(true)
                    }
                    const onDateChange = (value: string, type: string) => {
                        setFieldValue(type, value !== null ? moment(value).format("yyyy-MM-DD") : undefined);
                        formChangedUpdate(true)
                    }
                    const confirmReset = () => {
                        setPopupVisible(false);
                        resetForm();
                        formChangedUpdate(false);
                    }
                    const confirmEdit = () => {
                        setPopupEditVisible(false);
                        formChangedUpdate(true);
                        setDisabled(false);
                    }
                    const loginAction = () => {
                        //@ts-ignore
                        let userId = values.login;
                        let profileId = userId;
                        let pass = '';
                        const {apiURL, adminSuffix, type, env, accountType, admin, routeSuffix} = envs;
                        if(envs.admin) {
                            //@ts-ignore
                             dispatch(setEnvs({apiURL, adminSuffix, type, env, accountType, userId, routeSuffix, profileId: '', userPass: values.password, admin}));
                        } else {
                            //@ts-ignore
                            dispatch(setEnvs({apiURL, adminSuffix, type, env, accountType, userId, routeSuffix, profileId, userPass: values.password, admin}));
                        }
                        //setMessageShown(true);
                    }
                    const registerAction = () => {
                        validateForm().then((formErrors) => {
                            if(_.isEmpty(formErrors)) {
                                //@ts-ignore
                                dispatch(userCreate({ data: {type: values.clientType, email: values.email, password: values.password} }));
                            } else {
                                return
                            }
                        });
                        
                    }
                    const basicFormProps = {values: values, errors: errors, handleInputChange: handleInputChange, onSelectChange: onSelectChange, onDateChange: onDateChange, setFieldValue: setFieldValue, disabled, initialErrorsValues: initialErrors, touched: touched}
                    return (
                        <Form className='ant-form-vertical' id={id}>
                            <FormObserver id={id} />
                            <Space direction='vertical' size={'large'}>
                                {children(basicFormProps)}
                            </Space>
                            {errorsListing && finalSubmit &&
                                <div id='errorSummaryUser' className="desc" style={{'marginBottom': '30px'}}>
                                  <Paragraph>
                                    <Text
                                      strong
                                      style={{
                                        fontSize: 16,
                                      }}
                                    >
                                      The content You try to submit has following errors:
                                    </Text>
                                  </Paragraph>
                                  {envs.accountType === 'BUSINESS' &&
                                      <>
                                          {!_.isEmpty(status.updatedStatus.companyProfileValidated.validationErrors) &&
                                              <ErrorElement business={true} showErrors={showErrors} formName='companyInfoForm' elem='companyProfileValidated' visibleErrors={visibleErrors} errorsListing={status.updatedStatus} header={<Text>Some <b>Company Informations</b> are missing</Text>} route='step1' additionalAction={() => navBack('/step1')} />
                                          }
                                          {_.find(status.updatedStatus.authorizedPersonsValidated,(ap) => ap.validationErrors?.length) &&
                                              <ErrorElement showErrors={showErrors} formName='authorizedPersonsForm' elem='authorizedPersonsValidated' visibleErrors={visibleErrors} errorsListing={status.updatedStatus} header={<Text>Some <b>Authorized Person</b> Informations are missing</Text>} route='step2' additionalAction={() => navBack('/step2')}/>
                                          }
                                          {_.find(status.updatedStatus.beneficialOwnersValidated,(ubo) => ubo.validationErrors?.length) &&
                                              <ErrorElement showErrors={showErrors} formName='ubosForm' elem='beneficialOwnersValidated' visibleErrors={visibleErrors} errorsListing={status.updatedStatus} header={<Text>Some <b>Beneficial Owner</b> Informations are missing</Text>} route='step3' additionalAction={() => navBack('/step3')}/>
                                          }
                                          {status.updatedStatus.numberOfDirectorsValidated.entry === 0 &&
                                              <ErrorParagraph>
                                                <CloseCircleOutlined />At least one <b>Director</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navBack('/step2')} >Fix issue &gt;</CustomLinkSpan>) : (<LinkEl to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + '/step2' : envs.routeSuffix + '/step2'}>Fix issue &gt;</LinkEl> )}
                                              </ErrorParagraph>
                                          }
                                          {status.updatedStatus.numberOfBeneficialOwnersValidated.entry === 0 &&
                                              <ErrorParagraph>
                                                <CloseCircleOutlined />At least one <b>Beneficial Owner</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navBack('/step3')} >Fix issue &gt;</CustomLinkSpan>) : (<LinkEl to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + '/step2' : envs.routeSuffix + '/step3'}>Fix issue &gt;</LinkEl> )}
                                              </ErrorParagraph>
                                          }
                                          {status.updatedStatus.numberOfSignatoryValidated.entry === 0 &&
                                              <ErrorParagraph>
                                                <CloseCircleOutlined />At least one <b>Signatory</b> should be defined
                                              </ErrorParagraph>
                                          }
                                      </>
                                  }
                                  {envs.accountType === 'PRIVATE' &&
                                      <>
                                          {!_.isEmpty(status.individualUpdatedStatus.beneficialOwnerValidated.validationErrors) &&
                                              <ErrorElement formName='individualInfoForm' business={false} showErrors={showErrors} elem='beneficialOwnerValidated' visibleErrors={visibleErrors} errorsListing={status.individualUpdatedStatus} header={<Text>Some <b>Personal Informations</b> are missing</Text>} route='step1' additionalAction={() => navBack('/step1')}/>
                                          }
                                          {!_.isEmpty(status.individualUpdatedStatus.declarationValidated.validationErrors) &&
                                              <ErrorElement formName='dataIndividualValidationForm' business={false} showErrors={showErrors} elem='declarationValidated' visibleErrors={visibleErrors} errorsListing={status.individualUpdatedStatus} header={<Text>Some <b>Declarations</b> are missing</Text>} route='step2' additionalAction={() => navBack('/step2')}/>
                                          }
                                      </>
                                  }
                                </div>
                            }
                            <>
                            </>
                            <StickyNav id={id === 'dataValidationForm' && envs.accountType === 'PRIVATE' && fixedNavPos === true ? 'formFixed' : ''} style={{display: hideOnDisabled ? formChanged ? 'block' : 'none' : 'block', bottom: `${envs.admin ? (standaloneFormSubmit ? '60px' : '120px') : (id === 'dataValidationForm') ? '0' : '69px'}`}} className={finalSubmit ? 'bottom form-nav' : (loginSubmit || registerSubmit) ? 'no-stick form-nav' : 'form-nav'}>
                                <StickyContent className='sticky-content' style={id === 'dataValidationForm' && envs.accountType === 'PRIVATE' ? {width: contentWidth + 30} : {}}>
                                    <Row>
                                        {loginSubmit || registerSubmit ? (
                                            <Col md={24}>
                                                <ButtonUpper btnType="primary" htmlType="submit" disabled={formDisabled || disabled} onClick={registerSubmit ? () => registerAction() : () => loginAction()} block>{registerSubmit ? 'Register' :  'Login'}</ButtonUpper>
                                            </Col>
                                        ) : (
                                        <>
                                        <Col md={12}>
                                            {finalSubmit ? (
                                                <>
                                                    {formUpdated.updated ? (
                                                        <CustomLink onClick={() => navBack(prev)} >
                                                            <ButtonUpper btnType="primary" className='left' ghost>Back</ButtonUpper>
                                                        </CustomLink>
                                                    ) : (
                                                        <Link to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + prev : prev ? envs.routeSuffix + prev : ''}>
                                                            <ButtonUpper btnType="primary" className='left' ghost>Back</ButtonUpper>
                                                        </Link>
                                                    )}
                                                </>
                                                
                                            ) : (
                                                <>
                                                        <>
                                                            <PopconfirmCustom
                                                                title="Are You sure You want to clear this form? All unsaved data will be lost"
                                                                visible={popupVisible}
                                                                onVisibleChange={handleVisibleChange}
                                                                onConfirm={confirmReset}
                                                                onCancel={cancel}
                                                                okText="Yes, clear form"
                                                                cancelText="Cancel"
                                                                getPopupContainer={(trigger) => trigger}
                                                                icon={<QuestionCircleOutlined />}
                                                            >
                                                                <ButtonUpper btnType="dashed" className='left dashed-primary' disabled={formChanged ? false : true} >Clear all</ButtonUpper>
                                                            </PopconfirmCustom>
                                                            {disabled && editable !== false &&
                                                                <PopconfirmCustom
                                                                    title={envs.admin ? "If You make changes in this form it will revert your approvals" : "If You make changes in this form it may block Your account until Bitclear AG approves the changes"}
                                                                    visible={popupEditVisible}
                                                                    onVisibleChange={handleEditVisibleChange}
                                                                    onConfirm={confirmEdit}
                                                                    onCancel={cancelEdit}
                                                                    okText="Edit form"
                                                                    cancelText="Cancel"
                                                                    getPopupContainer={(trigger) => trigger}
                                                                    icon={<QuestionCircleOutlined />}
                                                                >
                                                                    <ButtonUpper btnType="primary" className='left' ghost>Edit</ButtonUpper>
                                                                </PopconfirmCustom>
                                                            }
                                                        </>
                                                </>
                                            )}
                                        </Col>
                                        <ColRight>
                                            {deleteForm &&
                                                <PopconfirmCustom
                                                    title={isUbo ? "Are You sure You want to delete this Ultimate Beneficial Owner?" : "Are You sure You want to delete this Authorized Person?"}
                                                    visible={deletePopupVisible}
                                                    onVisibleChange={handleDeleteVisibleChange}
                                                    onConfirm={() => handleFormDelete()}
                                                    onCancel={cancelDelete}
                                                    okText="Yes, delete"
                                                    cancelText="Cancel"
                                                    getPopupContainer={(trigger) => trigger}
                                                    icon={<QuestionCircleOutlined />}
                                                >
                                                    <ButtonUpper btnType="primary" className='left' disabled={formChanged || deleteEnabled  ? false : true || disabled} ghost>Delete</ButtonUpper>
                                                </PopconfirmCustom>
                                            }
                                            {restoreForm &&
                                                <ButtonUpper btnType="primary" className='left' disabled={formChanged ? true : false || disabled} onClick={() => handleFormDelete(true)} ghost>Restore</ButtonUpper>
                                            }
                                            {validateButton &&
                                                <ButtonUpper btnType="text" className='left' onClick={validateStepAction} disabled={formChanged}>Validate step</ButtonUpper>
                                            }
                                            {saveDraft &&
                                                <ButtonUpper btnType="primary" htmlType="submit" className='left' disabled={formChanged ? false : true}>Save draft</ButtonUpper>
                                            }
                                            {finalSubmit ? (
                                                <ButtonUpper btnType="primary" htmlType="submit" disabled={formDisabled || disabled} ghost onClick={() => finalSubmitAction()}>Submit to us</ButtonUpper>
                                            ) : (
                                                <>
                                                    {!saveDraft && <ButtonUpper btnType="primary" htmlType="submit" disabled={formDisabled || (formChanged ? false : true)} >{saveText ? saveText : <>{saveInnerForm ? 'Save' : 'Save step'}</>}</ButtonUpper>}
                                                </>
                                            )}
                                           </ColRight>
                                           </>
                                           )}
                                    </Row>
                                </StickyContent>
                            </StickyNav>
                        </Form>
                    )}}
                </Formik>
        </>
             
    )
}

export default BasicFormWrapper
