import apiSchema from "./generated/api.json"
import { Client } from "./generated/client"
import { setInterceptors } from "./interceptors"

import { OpenAPIClientAxios } from "openapi-client-axios"

export const openAPIClientAxios = new OpenAPIClientAxios({
  // @ts-ignore
  definition: apiSchema,
  strict: true,
  timeout: 20000,
  validate: true,
  //@ts-ignore
  withServer: { url: process.env.REACT_APP_KYC_API_URL , description: "KYC API" },
  axiosConfigDefaults: {
    headers: {
      "Content-Type": "application/json",
    },
    /*adapter: throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
        defaultCache: new Cache({ max: 100, maxAge: 1000 * 60 }),
      }),
      { threshold: 2 * 1000 }
    ),*/
  },
});

const initialize = () => {
  const api = openAPIClientAxios.initSync<Client>();
  setInterceptors(openAPIClientAxios.client);
  return api;
};

export default initialize();
