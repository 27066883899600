import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PersonsResponse = Components.Schemas.AuthorizedPersonProfile;
type PathParameters = Paths.AuthorizedPersons.PathParameters;

export const authorizedPersonsBackGet: AsyncThunkActionType<
  PersonsResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("authorizedPersonsBackGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.AuthorizedPersons(data.params, null, data?.config);
    return response.data as PersonsResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
