import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { approveCompanyToggle } from "../asyncThunks/approveCompanyToggle";
import { approveIndividualToggle } from "../asyncThunks/approveIndividualToggle";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

export type CompanyResponse = Components.Schemas.CompanyApproveValidated;
export type IndividualResponse = Components.Schemas.IndividualApproveValidated;

type State = {
  companyApprovedStatus: CompanyResponse,
  individualApprovedStatus: IndividualResponse
};


const initialState: State = {
  companyApprovedStatus: {
    companyUploadedDocumentsValidations: {
      companyId: '',
      documentsSummary: {
        approvedDocuments: 0,
        unApprovedDocuments: 0,
        isValid: false
      }
    },
    authorizedPersonsUploadedDocumentsSummary: {
      isValid: false
    },
    beneficialOwnersUploadedDocumentsSummary: {
      isValid: false
    },
    preReviewValidated: {
      companyProfileValidated: {
        entry: ''
      },
      numberOfDirectorsValidated: {
        entry: 0
      },
      numberOfSignatoryValidated: {
        entry: 0
      },
      numberOfBeneficialOwnersValidated: {
        entry: 0
      },
      percentageFilled: 0
    },
    companyValidations: {
      approvedFields: 0,
      unApprovedFields: 0,
      isValid: false
    },
    isApproved: false
  },
  individualApprovedStatus: {
    preReviewValidated: {
      beneficialOwnerValidated: {
        entry: ''
      },
      declarationValidated: {
        entry: false
      },
      percentageFilled: 0
    },
    beneficialOwnerValidations: {
      beneficialOwner: {
        beneficialOwnerId: '',
        firstName: '',
        lastName: ''
      },
      fieldsSummary: {
        approvedFields: 0,
        unApprovedFields: 0,
        isValid: false
      }
    },
    uploadedDocumentsApproveValidated: {
      individualId: '',
      documentsSummary: {
        approvedDocuments: 0,
        unApprovedDocuments: 0,
        isValid: false
      }
    },
    isApproved: false
  }
};

type CaseReducers<State> = {
  approveCompanyToggleClear: CaseReducerType<State, object>;
  approveIndividualToggleClear: CaseReducerType<State, object>;
};

export const finalApproveSlice = createSlice<State, CaseReducers<State>>({
  name: "finalApprove",
  initialState,
  reducers: {
    approveCompanyToggleClear: (state, { payload }) => {
      state.companyApprovedStatus = initialState.companyApprovedStatus
    },
    approveIndividualToggleClear: (state, { payload }) => {
      state.individualApprovedStatus = initialState.individualApprovedStatus
    },
  },
  extraReducers: (builder) => {
    builder.addCase(approveCompanyToggle.fulfilled, (state, action) => {
      state.companyApprovedStatus = action.payload;
    });
    builder.addCase(approveIndividualToggle.fulfilled, (state, action) => {
      state.individualApprovedStatus = action.payload;
    });
  },
});

export const { approveCompanyToggleClear, approveIndividualToggleClear } = finalApproveSlice.actions;
export default finalApproveSlice.reducer;
