import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { userCreate } from "../asyncThunks/userCreate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { number } from "yup";

export type UserResponse = Paths.Registration.Responses.Default;

type ErrorWithStatusObject = {
	data: string;
	status: number;
};

type State = {
  new: UserResponse | ErrorWithStatusObject
};

const initialState: State = {
  new: ''
};

type CaseReducers<State> = {
  userCreateClear: CaseReducerType<State, object>;
};

export const userSlice = createSlice<State, CaseReducers<State>>({
  name: "user",
  initialState,
  reducers: {
    userCreateClear: (state, { payload }) => {
      state.new = initialState.new
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userCreate.fulfilled, (state, action) => {
      	state.new = action.payload
    });
	builder.addCase(userCreate.rejected, (state, action) => {
		//@ts-ignore
		state.new = action.payload
    });
  },
});

export const { userCreateClear } = userSlice.actions;
export default userSlice.reducer;
