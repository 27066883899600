import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyResponse } from "../slices/companySlice";
import { Components } from "../../../api/kyc/generated/client";

type CreateCompanyRequest = Components.Schemas.NewCompany;

export const companyCreate: AsyncThunkActionType<
  CompanyResponse,
  AsyncThunkDataType<null, CreateCompanyRequest, "data">
> = createAsyncThunk(
  "companyCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.createCompany(params, data, config);
      return response.data as CompanyResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
