import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type DocsResponse = Components.Schemas.BeneficialOwnerDocumentEntry;
type PathParameters = Paths.BeneficialOwnerDocuments.PathParameters;

export const uboDocsGet: AsyncThunkActionType<
  DocsResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("uboDocsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.BeneficialOwnerDocuments(data.params, null, data?.config);
    return response.data as DocsResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
