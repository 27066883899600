import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { individualDocsGet } from "../asyncThunks/individualDocsGet";
import { individualDocUrlGet } from "../asyncThunks/individualDocUrlGet";
import { individualDocCreate } from "../asyncThunks/individualDocCreate";
import { individualDocArchive } from "../asyncThunks/individualDocArchive"
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type IndividualDocsResponse = Components.Schemas.IndividualDocumentEntry;
export type UrlResponse = Paths.IndividualDocumentContent.Responses.$200;
export type ArchiveResponse = Paths.ArchiveDocument.Responses.$200;

type State = {
    individualDocs: IndividualDocsResponse[];
    individualDocUrl: UrlResponse;
    newIndividualDoc: IndividualDocsResponse,
    individualDocArchived: {id: string, value: ArchiveResponse}
};

const initialState: State = {
    newIndividualDoc: {
        individualId: '',
        documentId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: '',
        adminOnly: false
    },
    individualDocs: [],
    individualDocUrl: '',
    individualDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    individualDocCreateClear: CaseReducerType<State, object>;
    individualDocsGetClear: CaseReducerType<State, object>;
    individualDocUrlClear: CaseReducerType<State, object>;
    individualDocArchiveClear: CaseReducerType<State, object>;
};

export const individualDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "individualDocs",
    initialState,
    reducers: {
        individualDocCreateClear: (state, { payload }) => {
            state.newIndividualDoc = initialState.newIndividualDoc
        },
        individualDocUrlClear: (state, { payload }) => {
            state.individualDocUrl = initialState.individualDocUrl
        },
        individualDocsGetClear: (state, { payload }) => {
            state.individualDocs = initialState.individualDocs
        },
        individualDocArchiveClear: (state, { payload }) => {
            state.individualDocArchived = initialState.individualDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(individualDocsGet.fulfilled, (state, action) => {
            state.individualDocs = action.payload
        });
        builder.addCase(individualDocCreate.fulfilled, (state, action) => {
            state.newIndividualDoc = action.payload;
        });
        builder.addCase(individualDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newIndividualDoc = action?.payload;
        });
        builder.addCase(individualDocUrlGet.fulfilled, (state, action) => {
            state.individualDocUrl = action.payload;
        });
        builder.addCase(individualDocArchive.fulfilled, (state, action) => {
            state.individualDocArchived = action.payload;
        });
    }
});

export const { individualDocCreateClear, individualDocUrlClear, individualDocsGetClear, individualDocArchiveClear } = individualDocsSlice.actions;
export default individualDocsSlice.reducer;
