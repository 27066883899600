import { Row, Col, Divider, Space, Popconfirm, message, Result, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { FC, ReactNode, useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector } from '../../../hooks/useSelector'
import styled from 'styled-components'
import { ErrorElement, ErrorParagraph } from '../../kyc-front/ui-elements/ErrorElement'
import { dispatch } from '../../../store/store'
import { adminProfileGet } from '../../../store/kyc-backend/asyncThunks/adminProfileGet'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { FormSaver } from '../../../utilities/FormSaver'
import { themeColor } from '../../../styles/themeStyles'

const { Paragraph, Text } = Typography

const Validation = styled.div`
	margin-bottom: 0;
    margin-left: -30px;
    margin-right: -30px;
    background: white;
    padding: 20px 30px;
`

const CenteredCol = styled(Col)`
    text-align: center;
    margin-bottom: 30px;
`

const CustomLinkSpan = styled.span`
    cursor: pointer;
    color: ${themeColor.gray};
`

interface CProps {
	
}

export const ValidationErrors: FC<CProps> = ({}) => {
	const envs = useSelector((state) => state.general.envs);
	const finalApprove = useSelector((state) => state.admin.finalApprove);
	const status = useSelector((state) => state.user.status);
	const adminProfile = useSelector((state) => state.admin.admin);
    const nav = useSelector((state) => state.general.nav);
    let {formUpdated, currentEditedForm} = nav;
	let [visibleErrors, setVisibleErrors] = useState<string[]>([]); 
	let [errorsListing, setErrorsListing] = useState(false);
    const [onConfirm, setOnConfirm] = useState(false);
    const [onConfirmLink, setOnConfirmLink] = useState('')
	const companyFinal = finalApprove.companyApprovedStatus;
	const individualFinal = finalApprove.individualApprovedStatus;
	const companyPreReview = companyFinal.preReviewValidated;
    const individualPreReview = individualFinal.preReviewValidated;
	const isApproved = finalApprove.individualApprovedStatus.isApproved;
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
	const showErrors = (el: string) => {
        if(visibleErrors.includes(el)) {
            setVisibleErrors(visibleErrors.filter(elem => elem !== el))
        } else {
            setVisibleErrors((visibleErrors) => [...visibleErrors, el])
        }
        
    }
    const navToError = (link?: string) => {
        if(link) {
            setOnConfirm(true);
            setOnConfirmLink(link);
        }
    }
    useEffect(() => {
	    if(envs.accountType === 'BUSINESS') {
	        //@ts-ignore
	        if(companyPreReview?.companyProfileValidated?.validationErrors?.length || 
                //@ts-ignore
	            companyPreReview.numberOfBeneficialOwnersValidated?.entry === 0 || 
                //@ts-ignore
	            companyPreReview.numberOfDirectorsValidated?.entry === 0|| 
                //@ts-ignore
	            companyPreReview.numberOfSignatoryValidated?.entry === 0 || 
	            //@ts-ignore
	            _.find(companyPreReview.beneficialOwnersValidated, function(ubo) { return ubo?.validationErrors?.length > 0}) || 
	            //@ts-ignore
	            _.find(companyPreReview.authorizedPersonsValidated, function(ap) { return ap?.validationErrors?.length > 0}) ||
                !companyFinal.isApproved )
	        {
	            setErrorsListing(true)
	        }
	    }
	}, [companyPreReview]);
    useEffect(() => {
        if(envs.accountType === 'PRIVATE') {
            if(individualPreReview?.beneficialOwnerValidated?.validationErrors?.length ||
                individualPreReview?.declarationValidated?.validationErrors?.length ||
                !individualFinal.isApproved
            ) {
                setErrorsListing(true)
            }

        }
    }, [individualPreReview]);
	useEffect(() => {
	    if(adminProfile.profile.adminId === '') {
	    	dispatch(adminProfileGet({params: null}));
	    }
	}, [adminProfile]);
    let admin = adminProfile.profile.adminId;
    let isNotApproved = envs.accountType === 'BUSINESS' ? !individualFinal.isApproved : !companyFinal.isApproved;
    useEffect(() => {
        if(errorsListing && (individualPreReview?.beneficialOwnerValidated?.entry !== '') && isNotApproved) {
            //@ts-ignore
            document.getElementById("errorSummary").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [individualFinal]);
	useEffect(() => {
        if(errorsListing && (companyPreReview?.companyProfileValidated?.entry !== '') && isNotApproved) {
            //@ts-ignore
            document.getElementById("errorSummary").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [companyFinal]);
	return (
		<>
			{errorsListing && (envs.accountType === 'BUSINESS' ? companyPreReview?.companyProfileValidated?.entry !== '' : individualPreReview?.beneficialOwnerValidated?.entry !== '') &&
            <>
                <FormSaver onConfirm={onConfirm} onConfirmLink={onConfirmLink} />
                {isNotApproved &&
                    <Validation className="desc" id='errorSummary'>
                      <Paragraph>
                        <Text
                          strong
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Content You are trying to approve has following errors:
                        </Text>
                      </Paragraph>
                      {envs.accountType === 'BUSINESS' &&
                          <>
                              {!_.isEmpty(companyPreReview.companyProfileValidated.validationErrors) &&
                                  <ErrorElement key={1} business={true} showErrors={showErrors} formName='companyInfoForm' elem='companyProfileValidated' visibleErrors={visibleErrors} errorsListing={companyPreReview} header={<Text>Some <b>Company Informations</b> are missing</Text>} route='step1' additionalAction={() => navToError('/step1')} />
                              }
                              {_.find(companyPreReview.authorizedPersonsValidated,(ap) => ap.validationErrors?.length) &&
                                  <ErrorElement key={2} showErrors={showErrors} formName='authorizedPersonsForm' elem='authorizedPersonsValidated' visibleErrors={visibleErrors} errorsListing={companyPreReview} header={<Text>Some <b>Authorized Person</b> Informations are missing</Text>} route='step2' additionalAction={() => navToError('/step2')}/>
                              }
                              {_.find(companyPreReview.beneficialOwnersValidated,(ubo) => ubo.validationErrors?.length) &&
                                  <ErrorElement key={3} showErrors={showErrors} formName='ubosForm' elem='beneficialOwnersValidated' visibleErrors={visibleErrors} errorsListing={companyPreReview} header={<Text>Some <b>Beneficial Owner</b> Informations are missing</Text>} route='step3' additionalAction={() => navToError('/step3')}/>
                              }
                              
                              {companyPreReview.numberOfDirectorsValidated.entry === 0 &&
                                  <ErrorParagraph>
                                    <CloseCircleOutlined />At least one <b>Director</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navToError('/step2')} >Fix issue &gt;</CustomLinkSpan>) : (<Link to={isStandaloneAdmin ? envs.routeSuffix +  '/' + envs.profileId + '/step2' : envs.routeSuffix + '/step2'}>Fix issue &gt;</Link> )}
                                  </ErrorParagraph>
                              }
                              {companyPreReview.numberOfBeneficialOwnersValidated.entry === 0 &&
                                  <ErrorParagraph>
                                    <CloseCircleOutlined />At least one <b>Beneficial Owner</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navToError('/step3')} >Fix issue &gt;</CustomLinkSpan>) : (<Link to={isStandaloneAdmin ? envs.routeSuffix +  '/' + envs.profileId + '/step2' : envs.routeSuffix + '/step3'}>Fix issue &gt;</Link> )}
                                  </ErrorParagraph>
                              }
                              {companyPreReview.numberOfSignatoryValidated.entry === 0 &&
                                  <ErrorParagraph>
                                    <CloseCircleOutlined />At least one <b>Signatory</b> should be defined
                                  </ErrorParagraph>
                              }
                              {!companyFinal.companyValidations.isValid &&
                                  <ErrorElement key={6} admin={admin} business={true} showErrors={showErrors} formName='companyInfoForm' elem='companyValidations' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingApprove' header={<Text>Some <b>Company Informations</b> are not fully <b>Approved:</b></Text>} route='step1' additionalAction={() => navToError('/step1')}/>
                              }
                              {_.find(companyFinal.authorizedPersonsValidations,(ap) => ap.fieldsSummary.isValid === false) &&
                                  <ErrorElement key={7} admin={admin} business={true} showErrors={showErrors} formName='authorizedPersonsForm' elem='authorizedPersonsValidations' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingApprove' header={<Text>Some <b>Authorized Persons</b> are not fully <b>Approved:</b></Text>} route='step2' additionalAction={() => navToError('/step2')}/>
                              }
                              {_.find(companyFinal.beneficialOwnerValidations,(ubo) => ubo.fieldsSummary.isValid === false) &&
                                  <ErrorElement key={8} admin={admin} business={true} showErrors={showErrors} formName='ubosForm' elem='beneficialOwnerValidations' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingApprove' header={<Text>Some <b>Beneficial Owners</b> are not fully <b>Approved:</b></Text>} route='step3' additionalAction={() => navToError('/step3')}/>
                              }
                              {!companyFinal.authorizedPersonsUploadedDocumentsSummary.isValid &&
                                  <ErrorElement key={4} admin={admin} business={true} showErrors={showErrors} formName='authorizedPersonsForm' id='authorizedPersonsUploadedDocumentsSummaryMissing' elem='authorizedPersonsUploadedDocumentsSummary' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingDocument' header={<Text>Document of some <b>Authorized Persons</b> are not fully approved</Text>} route='step2' additionalAction={() => navToError('/step2')}/>
                              }
                              {!companyFinal.beneficialOwnersUploadedDocumentsSummary.isValid &&
                                  <ErrorElement key={5} admin={admin} business={true} showErrors={showErrors} formName='ubosForm' id='beneficialOwnersUploadedDocumentsSummaryMissing' elem='beneficialOwnersUploadedDocumentsSummary' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingDocument' header={<Text>Documents of some <b>Beneficial Owners</b> are not fully approved</Text>} route='step3' additionalAction={() => navToError('/step3')} />
                              }
                              {!companyFinal.companyUploadedDocumentsValidations.documentsSummary.isValid &&
                                  <ErrorElement key={9} admin={admin} business={true} showErrors={showErrors} formName='companyForm' id='companyUploadedDocumentsSummaryMissing' elem='companyUploadedDocumentsValidations' visibleErrors={visibleErrors} errorsListing={companyFinal} listingType='missingDocument' header={<Text><b>Company documents</b> are not fully approved</Text>} route='step1' additionalAction={() => navToError('/step1')} />
                              }
                          </>
                      }
                      {envs.accountType === 'PRIVATE' &&
                          <>
                              {!_.isEmpty(individualPreReview.beneficialOwnerValidated.validationErrors) &&
                                  <ErrorElement key={11} formName='individualInfoForm' business={false} showErrors={showErrors} elem='beneficialOwnerValidated' visibleErrors={visibleErrors} errorsListing={individualPreReview} header={<Text>Some <b>Personal Informations</b> are missing</Text>} route='step1' additionalAction={() => navToError('/step1')}/>
                              }
                              {!_.isEmpty(individualPreReview.declarationValidated.validationErrors) &&
                                  <ErrorElement key={12} formName='dataIndividualValidationForm' business={false} showErrors={showErrors} elem='declarationValidated' visibleErrors={visibleErrors} errorsListing={individualPreReview} header={<Text>Some <b>Declarations</b> are missing</Text>} route='step2' additionalAction={() => navToError('/step2')}/>
                              }
                              {!individualFinal.beneficialOwnerValidations.fieldsSummary.isValid &&
                                  <ErrorElement key={13} admin={admin} formName='individualInfoForm' business={false} showErrors={showErrors} elem='fieldsSummary' visibleErrors={visibleErrors} errorsListing={individualFinal.beneficialOwnerValidations} listingType='missingApprove' header={<Text>Some <b>Personal Informations</b> are not fully <b>Approved</b></Text>} route='step1' additionalAction={() => navToError('/step1')}/>
                              }
                              {!individualFinal.uploadedDocumentsApproveValidated.documentsSummary.isValid &&
                                  <ErrorElement key={14} admin={admin} business={true} showErrors={showErrors} formName='individualInfoForm' id='individualUploadedDocumentsSummaryMissing' elem='uploadedDocumentsApproveValidated' visibleErrors={visibleErrors} errorsListing={individualFinal} listingType='missingDocument' header={<Text><b>Documents</b> are not fully approved</Text>} route='step1' additionalAction={() => navToError('/step1')}/>
                              }
                          </>
                      }
                    </Validation>
                }
                
              </>
        }
		</>
	)
}

