import { Spin, Row, Col, Divider } from 'antd'
import { FC, useEffect, useState } from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { individualUpdate } from '../../../store/kyc-front/asyncThunks/individualUpdate'
import { individualUpdateClear } from '../../../store/kyc-front/slices/individualSlice'
import { individualStatusUpdate } from '../../../store/kyc-front/asyncThunks/individualStatusUpdate'
import { individualDeclarationsValidate } from '../../../store/kyc-front/asyncThunks/individualDeclarationsValidate'
import { individualDeclarationsValidateClear } from '../../../store/kyc-front/slices/statusSlice'
import { ButtonUpper } from '../ui-elements/Buttons'
import { PageContent } from '../../layout/PageContent'
import { KycSuccessWrapper } from  '../listings/KycDataValidationListing'
import KycLayout from '../kyc-layout/KycLayout'
import KycIndividualDeclarationsForm from '../forms/KycIndividualDeclarationsForm'
import KycIndividualDataValidationForm from '../forms/KycIndividualDataValidationForm'
import styled from 'styled-components'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 80px;
`

const KycIndividualDeclarations: FC = () => {	
	const envs = useSelector((state) => state.general.envs);
	const individual = useSelector((state) => state.user.individual);
	const [showSuccess, setShowSucces] = useState(false);
	const formStatus = useActionStatus(individualStatusUpdate);
	const { isPending, isError, isFullfilled } = useActionStatus(individualGet);
	useEffect(() => {
		dispatch(individualGet())
	}, [])
	useEffect(() => {
    	if(formStatus.isFullfilled && showSuccess === false) {
    		setShowSucces(true);
    	}
    }, [formStatus.isFullfilled]);
    let reviewStatus = 'Approved';
	return (
		<>
      	<KycLayout pageName='Step 2' heading='Declarations and printout' nav={{step: 2, prev: "/step1", hideNav: true}} validationAction={individualDeclarationsValidate} validationActionParams={{individualId: envs.profileId}}>
			<KycSuccessWrapper status={individual.individual.reviewStatus} showSuccess={showSuccess} isFullfilled={isFullfilled} envs={envs}>
				<>
					{individual.individual.individualId !== '' ? (
	      			<>
	      				<div style={{'marginBottom': '30px'}}>
							<KycIndividualDeclarationsForm 
								individual={individual} 
								individualGet={individualGet} 
								individualUpdate={individualUpdate} 
								individualClearUpdate={individualUpdateClear} 
								validationAction={individualDeclarationsValidate} 
            					validationActionClear={individualDeclarationsValidateClear}
							/>
						</div>
						<KycIndividualDataValidationForm 
							individual={individual} 
							individualGet={individualGet} 
							individualUpdate={individualUpdate} 
							individualClearUpdate={individualUpdateClear}
						/>
					</>
				) : (
					<Spin size="large" />
				)}
				</>
			</KycSuccessWrapper>
		</KycLayout>
		{/*}
		<PageContent>
			<Row>
				<Col sm={24} md={12} lg={18} xl={18}>
					<Divider />
					<Row justify='center'>
						<CenteredCol>
							<p><b>Congratulations!</b></p>
							<p>everything is complete, you can:</p>
							<ButtonUpper btnType='primary'>Download & print kyc</ButtonUpper>
						</CenteredCol>
					</Row>
				</Col>
			</Row>
		</PageContent>*/}
		</>
			 
	)
}

export default KycIndividualDeclarations
