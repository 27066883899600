import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { individualBackDocsGet } from "../asyncThunks/individualBackDocsGet";
import { individualBackDocUrlGet } from "../asyncThunks/individualBackDocUrlGet";
import { individualBackDocCreate } from "../asyncThunks/individualBackDocCreate";
import { individualBackDocArchive } from "../asyncThunks/individualBackDocArchive"
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type IndividualDocsResponse = Components.Schemas.IndividualDocument;
export type UrlResponse = Paths.GetIndividualDocument.Responses.$200;
export type ArchiveResponse = Paths.ArchiveIndividualDocument.Responses.$200;

type State = {
  individualDocs: IndividualDocsResponse[];
  individualDocUrl: UrlResponse;
  newIndividualDoc: IndividualDocsResponse,
  individualDocArchived: {id: string, value: ArchiveResponse}
};

const initialState: State = {
    newIndividualDoc: {
        individualId: '',
        documentId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    individualDocs: [],
    individualDocUrl: '',
    individualDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
  individualBackDocCreateClear: CaseReducerType<State, object>;
  individualBackDocUrlGetClear: CaseReducerType<State, object>;
  individualBackDocsGetClear: CaseReducerType<State, object>;
  individualBackDocArchiveClear: CaseReducerType<State, object>;
};

export const individualDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "individualBackDocs",
    initialState,
    reducers: {
        individualBackDocCreateClear: (state, { payload }) => {
            state.newIndividualDoc = initialState.newIndividualDoc
        },
        individualBackDocUrlGetClear: (state, { payload }) => {
            state.individualDocUrl = initialState.individualDocUrl
        },
        individualBackDocsGetClear: (state, { payload }) => {
            state.individualDocs = initialState.individualDocs
        },
        individualBackDocArchiveClear: (state, { payload }) => {
            state.individualDocArchived = initialState.individualDocArchived
        }
      },
      extraReducers: (builder) => {
        builder.addCase(individualBackDocsGet.fulfilled, (state, action) => {
            state.individualDocs = action.payload
        });
        builder.addCase(individualBackDocCreate.fulfilled, (state, action) => {
            state.newIndividualDoc = action.payload;
        });
        builder.addCase(individualBackDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newIndividualDoc = action.payload;
        });
        builder.addCase(individualBackDocUrlGet.fulfilled, (state, action) => {
            state.individualDocUrl = action.payload;
        });
        builder.addCase(individualBackDocArchive.fulfilled, (state, action) => {
            state.individualDocArchived = action.payload;
        });
    }
});

export const { individualBackDocUrlGetClear, individualBackDocsGetClear, individualBackDocArchiveClear, individualBackDocCreateClear } = individualDocsSlice.actions;
export default individualDocsSlice.reducer;
