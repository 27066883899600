import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { APResponse } from "../slices/authorizedPersonsSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type CreateAPRequest = Components.Schemas.NewAuthorizedPerson;
type PathParameters = Paths.CreateAuthorizedPerson.PathParameters;

export const authorizedPersonCreate: AsyncThunkActionType<
  APResponse,
  AsyncThunkDataType<PathParameters, CreateAPRequest, "data">
> = createAsyncThunk(
  "authorizedPersonCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CreateAuthorizedPerson(params, data, config);
      return response.data as APResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
