import { Table, Tag, Space, Button, Typography, Input, Row, Col, Skeleton } from 'antd'
import _ from 'lodash'
import type { InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/lib/table/interface'
import type { ColumnsType, ColumnType, TableProps } from 'antd/lib/table'
import { CloseCircleOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import { PageContent } from '../../layout/PageContent'
import { store, dispatch, AsyncThunkActionType } from '../../../store/store'
import { FC, useEffect, useState, useRef } from 'react'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import TableElement from '../../kyc-front/ui-elements/TableElement'
import { MessagesListing } from '../../kyc-front/ui-elements/Comment'
import { adminMessagesGet } from '../../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { adminMessagesGetClear } from '../../../store/kyc-backend/slices/adminInboxSlice'
import { companyBackMessagePost } from '../../../store/kyc-backend/asyncThunks/companyBackMessagePost'
import { companyBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/companyBackMessageViewedPut'
import { individualBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/individualBackMessageViewedPut'
import { companyBackMessagePostClear, individualBackMessagePostClear, companyBackMessageViewedPutClear, individualBackMessageViewedPutClear } from '../../../store/kyc-backend/slices/messagesSlice'
import { individualBackMessagePost } from '../../../store/kyc-backend/asyncThunks/individualBackMessagePost'
import { identityGetClear } from 'store/kyc-backend/slices/identitySlice'
import { camelCaseToName } from '../../../utilities/nameParsers'
import { themeColor } from '../../../styles/themeStyles'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import { setNav } from '../../../store/kyc-front/slices/navSlice'
import { individualProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfilesBackGet'
import { companyProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfilesBackGet'
import { useActionStatus, ResponseInfo } from '../../../hooks/useActionStatus'

const { Text } = Typography;



interface DataType {
    title: string,
    dataIndex: string,
    key: string,
    render: any
}

type DataIndex = keyof DataType;

const AdminMessages: FC = () => {
    const envs = useSelector((state) => state.general.envs);
    const adminProfile = useSelector((state) => state.admin.admin);
    const adminInbox = useSelector((state) => state.admin.adminInbox);
    const navEl = useSelector((state) => state.general.nav);
    const backMessages = useSelector((state) => state.admin.backMessages);
    const [expanded, setExpanded] = useState<string[]>([]);
    const companies = useSelector((state) => state.admin.backCompany);
    const individuals = useSelector((state) => state.admin.backIndividual);
    const companyProfilesGetStatus = useActionStatus(companyProfilesBackGet);
    const individualProfilesGetStatus = useActionStatus(individualProfilesBackGet);
	useEffect(() => {
        dispatch(adminMessagesGet({params: null}));
        dispatch(companyProfilesBackGet({params: null}));
        dispatch(individualProfilesBackGet({params: {}}));
        /*dispatch(identityGetClear({}));*/
    }, []);
	return (
        <KycLayout listingView={true} pageName='Messages' nav={{hideNav: true, step: -1, navPage: 'messages'}}>
    		<PageContent >
    			<Title level={2} style={{marginTop: -40, marginBottom: 0}}>Messages</Title> 
                {individualProfilesGetStatus.isError || companyProfilesGetStatus.isError ? (
                        <p>Error</p>
                ) : (
                    <>
                        {individualProfilesGetStatus.isFullfilled && companyProfilesGetStatus.isFullfilled ? (
                            <MessagesListing 
                                type='message' 
                                side='admin' 
                                msgGet={adminMessagesGet} 
                                msgGetClear={adminMessagesGetClear} 
                                msgPostBusiness={companyBackMessagePost} 
                                msgPostPrivate={individualBackMessagePost}
                                msgPostBusinessClear={companyBackMessagePostClear} 
                                msgPostPrivateClear={individualBackMessagePostClear} 
                                msgViewedPutBusiness={companyBackMessageViewedPut}
                                msgViewedPutPrivate={individualBackMessageViewedPut}
                                msgViewedPutBusinessClear={companyBackMessageViewedPutClear}
                                msgViewedPutPrivateClear={individualBackMessageViewedPutClear}
                                //@ts-ignore
                                listing={adminInbox.messages && adminInbox.messages }
                                wasViewedBusiness={backMessages.companyMessageRead}
                                wasViewedPrivate={backMessages.individualMessageRead}
                                msgElemSentBusiness={backMessages.companyMessageSent}
                                msgElemSentPrivate={backMessages.individualMessageSent}
                                adminInbox={true}
                            />
                        ) : (<Skeleton style={{marginTop: 30}} paragraph={{ rows: 10 }} active />)}
                    </>
                )}
    		</PageContent>
        </KycLayout>
	)
}

export default AdminMessages
