import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { signatoriesUpdate } from "../asyncThunks/signatoriesUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

//export type SignatoryResponse = Paths.Signatories.Responses.$200;
export type SignatoryResponse = any; //todo add proper type

type State = {
  updatedSignatories: SignatoryResponse
};

const initialState: State = {
  updatedSignatories: '',
};

type CaseReducers<State> = {
  signatoriesUpdateClear: CaseReducerType<State, object>;
};

export const signantoriesSlice = createSlice<State, CaseReducers<State>>({
  name: "signatories",
  initialState,
  reducers: {
    signatoriesUpdateClear: (state, { payload }) => {
      state.updatedSignatories = initialState.updatedSignatories
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signatoriesUpdate.fulfilled, (state, action) => {
      state.updatedSignatories = action.payload
    });
  },
});

export const { signatoriesUpdateClear } = signantoriesSlice.actions;
export default signantoriesSlice.reducer;
