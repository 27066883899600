import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client"
import { companyApprovedFieldsGet } from "../asyncThunks/companyApprovedFieldsGet"
import { companyApproveFieldsUpdate } from "../asyncThunks/companyApproveFieldsUpdate"
import { individualApprovedFieldsGet } from "../asyncThunks/individualApprovedFieldsGet"
import { individualApproveFieldsUpdate } from "../asyncThunks/individualApproveFieldsUpdate"
import { authorizedPersonsApprovedFieldsGet } from "../asyncThunks/authorizedPersonsApprovedFieldsGet"
import { authorizedPersonApproveFieldsUpdate } from "../asyncThunks/authorizedPersonApproveFieldsUpdate"
import { ubosApprovedFieldsGet } from "../asyncThunks/ubosApprovedFieldsGet"
import { uboApproveFieldsUpdate } from "../asyncThunks/uboApproveFieldsUpdate"
import { authorizedPersonDocumentApproveUpdate } from "../asyncThunks/authorizedPersonDocumentApproveUpdate"
import { uboDocumentApproveUpdate } from "../asyncThunks/uboDocumentApproveUpdate"
import { companyDocumentApproveUpdate } from "../asyncThunks/companyDocumentApproveUpdate"
import { individualDocumentApproveUpdate } from "../asyncThunks/individualDocumentApproveUpdate"
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store"

import { reduce } from "lodash";

export type FieldsResponse = Components.Schemas.ApprovedField[];
export type CompanyResponse = Components.Schemas.CompanyResponse;
export type IndividualResponse = Components.Schemas.IndividualProfile;
export type AuthorizedPersonResponse = Components.Schemas.AuthorizedPersonProfile;
export type UboResponse = Components.Schemas.BeneficialOwnerProfile;
export type AuthorizedPersonDocResponse = Paths.ApproveAuthorizedPersonDocument.Responses.$200;
export type UboDocResponse = Paths.ApproveBeneficialOwnerDocumentEndpoint.Responses.$200;
export type IndividualDocResponse = Paths.ApproveIndividualDocument.Responses.$200;
export type CompanyDocResponse = Paths.ApproveCompanyDocument.Responses.$200;

type State = {
  companyApprovedFields: FieldsResponse;
  individualApprovedFields: FieldsResponse;
  authorizedPersonsApprovedFields: {personId: string, data: FieldsResponse};
  ubosApprovedFields: {personId: string, data: FieldsResponse};
  companyApprovedFieldsUpdated: {name: string, data: CompanyResponse};
  individualApprovedFieldsUpdated: {name: string, data: IndividualResponse};
  authorizedPersonApprovedFieldsUpdated: {name: string, data: AuthorizedPersonResponse};
  uboApprovedFieldsUpdated: {name: string, data: UboResponse};
  //document
  authorizedPersonDocumentApproveUpdated: {name: string, data: AuthorizedPersonDocResponse};
  uboDocumentApproveUpdated: {name: string, data: UboDocResponse};
  individualDocumentApproveUpdated: {name: string, data: IndividualDocResponse};
  companyDocumentApproveUpdated: {name: string, data: CompanyDocResponse};
};

const emptyProfile = {
    beneficialOwnerId: '',
    sourceOfWealthBusinessActivities: false,
    sourceOfWealthProfessionalOccupation: false,
    sourceOfWealthInheritance: false,
    sourceOfWealthOther: false,
    isPoliticallyExposedPerson: false,
    isUSNationality: false,
    createdAt: ''
}

const initialState: State = {
  companyApprovedFields: [],
  individualApprovedFields: [],
  authorizedPersonsApprovedFields: {personId: '', data: []},
  ubosApprovedFields: {personId: '', data: []},
  companyApprovedFieldsUpdated: {
    name: '', 
    data: {
      companyId: '',
      profile: {
        businessRelation: {
            isActive: false
        },
        companyId: '',
        name: '',
        legalForm: '',
        registrationNumber: '',
        placeOfIncorporation: '',
        phone: '',
        fax: '',
        createdAt: ''
      }
    }
    
  },
  individualApprovedFieldsUpdated: {
    name: '',
    data: {
      individualId: '',
      businessRelation: {
            isActive: false
        },
      profile: emptyProfile,
      reviewStatus: '',
      createdAt: ''
    }
    
  },
  authorizedPersonApprovedFieldsUpdated: {
    name: '',
    data: {
      authorizedPersonId: '',
      companyId: '',
      createdAt: ''
    }
  }, 
  uboApprovedFieldsUpdated: {
    name: '',
    data: emptyProfile
  },
  authorizedPersonDocumentApproveUpdated: {name: '', data: false},
  uboDocumentApproveUpdated: {name: '', data: false},
  individualDocumentApproveUpdated: {name: '', data: false},
  companyDocumentApproveUpdated: {name: '', data: false}
};

type CaseReducers<State> = {
  companyApprovedFieldsGetClear: CaseReducerType<State, object>;
  individualApprovedFieldsGetClear: CaseReducerType<State, object>;
  authorizedPersonsApprovedFieldsGetClear: CaseReducerType<State, object>;
  ubosApprovedFieldsGetClear: CaseReducerType<State, object>;
  companyApproveFieldsUpdateClear: CaseReducerType<State, object>;
  individualApproveFieldsUpdateClear: CaseReducerType<State, object>;
  authorizedPersonApproveFieldsUpdateClear: CaseReducerType<State, object>;
  uboApproveFieldsUpdateClear: CaseReducerType<State, object>;
  authorizedPersonDocumentApproveUpdateClear: CaseReducerType<State, object>;
  uboDocumentApproveUpdateClear: CaseReducerType<State, object>;
  individualDocumentApproveUpdateClear: CaseReducerType<State, object>;
  companyDocumentApproveUpdateClear: CaseReducerType<State, object>;
};

export const approvedSlice = createSlice<State, CaseReducers<State>>({
  name: "approved",
  initialState,
  reducers: {
    companyApprovedFieldsGetClear: (state, { payload }) => {
      state.companyApprovedFields = initialState.companyApprovedFields
    },
    companyApproveFieldsUpdateClear: (state, { payload }) => {
      state.companyApprovedFieldsUpdated = initialState.companyApprovedFieldsUpdated
    },
    individualApproveFieldsUpdateClear: (state, { payload }) => {
      state.individualApprovedFieldsUpdated = initialState.individualApprovedFieldsUpdated
    },
    individualApprovedFieldsGetClear: (state, { payload }) => {
      state.individualApprovedFields = initialState.individualApprovedFields
    },
    authorizedPersonsApprovedFieldsGetClear: (state, { payload }) => {
      state.authorizedPersonsApprovedFields = initialState.authorizedPersonsApprovedFields
    },
    authorizedPersonApproveFieldsUpdateClear: (state, { payload }) => {
      state.authorizedPersonApprovedFieldsUpdated = initialState.authorizedPersonApprovedFieldsUpdated
    },
    ubosApprovedFieldsGetClear: (state, { payload }) => {
      state.ubosApprovedFields = initialState.ubosApprovedFields
    },
    uboApproveFieldsUpdateClear: (state, { payload }) => {
      state.uboApprovedFieldsUpdated = initialState.uboApprovedFieldsUpdated
    },
    authorizedPersonDocumentApproveUpdateClear: (state, { payload }) => {
      state.authorizedPersonDocumentApproveUpdated = initialState.authorizedPersonDocumentApproveUpdated
    },
    uboDocumentApproveUpdateClear: (state, { payload }) => {
      state.uboDocumentApproveUpdated = initialState.uboDocumentApproveUpdated
    },
    individualDocumentApproveUpdateClear: (state, { payload }) => {
      state.individualDocumentApproveUpdated = initialState.individualDocumentApproveUpdated
    },
    companyDocumentApproveUpdateClear: (state, { payload }) => {
      state.companyDocumentApproveUpdated = initialState.companyDocumentApproveUpdated
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyApprovedFieldsGet.fulfilled, (state, action) => {
      state.companyApprovedFields = action.payload;
    });
    builder.addCase(individualApprovedFieldsGet.fulfilled, (state, action) => {
      state.individualApprovedFields = action.payload;
    });
    builder.addCase(authorizedPersonsApprovedFieldsGet.fulfilled, (state, action) => {
      state.authorizedPersonsApprovedFields = {personId: action.meta.arg.params.authorizedPersonId, data: action.payload};
    });
    builder.addCase(ubosApprovedFieldsGet.fulfilled, (state, action) => {
      state.ubosApprovedFields = {personId: action.meta.arg.params.beneficialOwnerId, data: action.payload}
    });
    builder.addCase(companyApproveFieldsUpdate.fulfilled, (state, action) => {
      state.companyApprovedFieldsUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(individualApproveFieldsUpdate.fulfilled, (state, action) => {
      state.individualApprovedFieldsUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(authorizedPersonApproveFieldsUpdate.fulfilled, (state, action) => {
      state.authorizedPersonApprovedFieldsUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(uboApproveFieldsUpdate.fulfilled, (state, action) => {
      state.uboApprovedFieldsUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(uboDocumentApproveUpdate.fulfilled, (state, action) => {
      state.uboDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(authorizedPersonDocumentApproveUpdate.fulfilled, (state, action) => {
      state.authorizedPersonDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(individualDocumentApproveUpdate.fulfilled, (state, action) => {
      state.individualDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
    builder.addCase(companyDocumentApproveUpdate.fulfilled, (state, action) => {
      state.companyDocumentApproveUpdated = {name: action.payload.name, data: action.payload.data};
    });
  }
});

export const { companyDocumentApproveUpdateClear, authorizedPersonDocumentApproveUpdateClear, individualDocumentApproveUpdateClear, uboDocumentApproveUpdateClear, companyApprovedFieldsGetClear, individualApprovedFieldsGetClear, companyApproveFieldsUpdateClear, individualApproveFieldsUpdateClear, authorizedPersonsApprovedFieldsGetClear, authorizedPersonApproveFieldsUpdateClear, ubosApprovedFieldsGetClear, uboApproveFieldsUpdateClear } = approvedSlice.actions;
export default approvedSlice.reducer;
