import { Spin } from 'antd'
import { FC, useEffect } from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { companyBackUpdate } from '../../../store/kyc-backend/asyncThunks/companyBackUpdate'
import { companyBackUpdateClear } from '../../../store/kyc-backend/slices/companySlice'
import { companyBackValidate } from '../../../store/kyc-backend/asyncThunks/companyBackValidate'
import { companyBackValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import { companyDocsGet } from '../../../store/kyc-backend/asyncThunks/companyDocsGet'
import { companyDocArchive } from '../../../store/kyc-backend/asyncThunks/companyDocArchive'
import { companyDocCreate } from '../../../store/kyc-backend/asyncThunks/companyDocCreate'
import { companyDocUrlGet } from '../../../store/kyc-backend/asyncThunks/companyDocUrlGet'
import { companyDocsGetClear, companyDocCreateClear, companyDocUrlGetClear, companyDocArchiveClear } from '../../../store/kyc-backend/slices/companyDocsSlice'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import KycCompanyInfoForm from '../../kyc-front/forms/KycCompanyInfoForm';

const KycCompanyInfo: FC = () => {	
	const company = useSelector((state) => state.admin.backCompany);
	const envs = useSelector((state) => state.general.envs);
	useEffect(() => {
		dispatch(companyProfileBackGet({ params: { companyId: envs.profileId } }))
		
	}, [])
	return (
      	<KycLayout pageName='Step 1' heading='Company information & business description' nav={{step: 1, next: "/step2", prev: envs.type === 'standalone' ? '/summary' : "/", navSubpage: 'kyc'}} adminView={true} validationAction={companyBackValidate} validationActionParams={{companyId: envs.profileId}}>
      		{company.profile.companyId !== '' ? (
				<KycCompanyInfoForm 
					company={company.profile} 
					companyGet={companyProfileBackGet} 
					companyUpdate={companyBackUpdate} 
					companyUpdatedResp={company.updated} 
					companyDocsGet={companyDocsGet}
    				companyDocsGetParams={{companyId: envs.profileId}}
    				companyDocsGetClear={companyDocsGetClear}
    				companyDocArchive={companyDocArchive}
    				companyDocArchiveClear={companyDocArchiveClear}
    				companyDocCreate={companyDocCreate}
    				companyDocCreateClear={companyDocCreateClear}
    				companyDocUrlGet={companyDocUrlGet}
    				companyDocUrlGetClear={companyDocUrlGetClear} 
					companyClearUpdate={companyBackUpdateClear} 
					validationAction={companyBackValidate} 
            		validationActionClear={companyBackValidateClear}
				/>
			) : (
				<Spin size="large" />
			)}
		</KycLayout>
			 
	)
}

export default KycCompanyInfo
