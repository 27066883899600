import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { individualProfileBackGet } from "../asyncThunks/individualProfileBackGet";
import { individualProfilesBackGet } from "../asyncThunks/individualProfilesBackGet";
import { individualProfileBackUpdate } from "../asyncThunks/individualProfileBackUpdate";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type ProfileResponse = Components.Schemas.IndividualProfile
export type IndividualBasicResponse = Components.Schemas.IndividualProfile;

type State = {
  individual: ProfileResponse,
  individuals: ProfileResponse[],
  archivedIndividuals: ProfileResponse[],
  updated: IndividualBasicResponse;
};

const emptyIndividual = {
    individualId: '',
    businessRelation: {
        isActive: false
    },
    profile: {
      
      beneficialOwnerId: '',
      sourceOfWealthBusinessActivities: false,
      sourceOfWealthProfessionalOccupation: false,
      sourceOfWealthInheritance: false,
      sourceOfWealthOther: false,
      isPoliticallyExposedPerson: false,
      isUSNationality: false,
      createdAt: ''
    }, 
    createdAt: '', 
    reviewStatus: ''
}


const initialState: State = {
  individual: emptyIndividual,
  individuals: [],
  archivedIndividuals: [],
  updated: emptyIndividual,
};

type CaseReducers<State> = {
  individualProfileBackUpdateClear: CaseReducerType<State, object>;
  individualProfileBackGetClear: CaseReducerType<State, object>;
};

export const individualSlice = createSlice<State, CaseReducers<State>>({
  name: "individual",
  initialState,
  reducers: {
    individualProfileBackUpdateClear: (state, { payload }) => {
      state.updated = initialState.updated
    },
    individualProfileBackGetClear: (state, { payload }) => {
      state.individual = initialState.individual
    }
  },
  extraReducers: (builder) => {
    builder.addCase(individualProfileBackUpdate.fulfilled, (state, action) => {
      state.updated = action.payload
    });
    builder.addCase(individualProfilesBackGet.fulfilled, (state, action) => {
        if(action.meta.arg.params.archived) {
            state.archivedIndividuals = action.payload
        } else {
            state.individuals = action.payload
        }
      
    });
    builder.addCase(individualProfileBackGet.fulfilled, (state, action) => {
      state.individual = action.payload
    });
  },
});

export const { individualProfileBackUpdateClear, individualProfileBackGetClear } = individualSlice.actions;
export default individualSlice.reducer;
