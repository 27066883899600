import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { AuthorizedPersonDocsResponse } from "../slices/authorizedPersonDocsSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type CreateAuthorizedPersonDocsRequest = Components.Schemas.AuthorizedPersonDocumentForm;
type resp = Components.Schemas.AuthorizedPersonDocumentEntry;
type PathParameters = Paths.AuthorizedPersonDocument.PathParameters;

export const authorizedPersonDocBackCreate: AsyncThunkActionType<
  resp,
  AsyncThunkDataType<PathParameters, CreateAuthorizedPersonDocsRequest, "data">
> = createAsyncThunk(
  "authorizedPersonDocBackCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.AuthorizedPersonDocument(params, data);
      return response.data as resp;
    } catch (err: any) {
      return rejectWithValue({
        error: true,
        status: err?.response.status, 
        data: err?.response.data,
        ...config
      });
    }
  }
);
