import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ArchiveReponse } from "../slices/companyDocsSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type ArchiveRequest = Components.Schemas.ArchiveRequest;
type PathParameters = Paths.ArchiveCompanyDocument.PathParameters;
type apResp = {
  id: string,
  value: ArchiveReponse
}

export const companyDocArchive: AsyncThunkActionType<
  apResp,
  AsyncThunkDataType<PathParameters, ArchiveRequest, "data">
> = createAsyncThunk(
  "companyDocArchive",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ArchiveCompanyDocument(params, data, config);
      return {id: params?.documentId, value: response.data} as apResp
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
