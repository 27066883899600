import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualBasicResponse } from "../slices/individualSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type UpdateIndividualRequest = Components.Schemas.UpdateIndividual;
type PathParameters = Paths.UpdateIndividual.PathParameters;

export const individualProfileBackUpdate: AsyncThunkActionType<
  IndividualBasicResponse,
  AsyncThunkDataType<PathParameters, UpdateIndividualRequest, "data">
> = createAsyncThunk(
  "individualProfileBackUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.UpdateIndividual(params, data, config);
      return response.data as IndividualBasicResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
