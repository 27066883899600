import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { UrlResponse } from '../slices/companyDocsSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type PathParameters = Paths.GetCompanyDocument.PathParameters;

export const companyDocUrlGet: AsyncThunkActionType<
  UrlResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("companyDocUrlGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.GetCompanyDocument(data.params, null, data?.config);
    return response.data as UrlResponse
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
