import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";
import { CaseReducerType } from "../../store";

type State = {
    apiURL: string | undefined;
    adminSuffix: string | undefined;
    type: string | undefined;
    env: string | undefined;
    profileId: string;
    routeSuffix: string;
    accountType: 'PRIVATE' | 'BUSINESS' | '',
    admin?: boolean,
};

const initialState: State = {
    apiURL: '',
    adminSuffix: '',
    type: '',
    env: '',
    profileId: '',
    routeSuffix: '',
    accountType: '',

};

type CaseReducers<State> = {
  setEnvs: CaseReducerType<State, State>;
};

export const envsSlice = createSlice<State, CaseReducers<State>>({
  name: "envs",
  initialState,
  reducers: {
    setEnvs: (state, { payload }) => {
        state.apiURL = payload.apiURL;
        state.adminSuffix = payload.adminSuffix;
        state.profileId = payload.profileId;
        state.type = payload.type;
        state.env = payload.env;
        state.routeSuffix = payload.routeSuffix;
        state.accountType = payload.accountType;
        state.admin = payload.admin;
    }
  }
});

export const { setEnvs } = envsSlice.actions;

export default envsSlice.reducer;

