import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { authorizedPersonCreate } from "../asyncThunks/authorizedPersonCreate";
import { authorizedPersonUpdate } from "../asyncThunks/authorizedPersonUpdate";
import { authorizedPersonArchive } from "../asyncThunks/authorizedPersonArchive";
import { authorizedPersonsGet } from "../asyncThunks/authorizedPersonsGet";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

import { reduce } from "lodash";

export type APResponse = Components.Schemas.AuthorizedPersonProfile;
export type ArchiveReponse = Paths.ArchiveAuthorizedPerson.Responses.$200;

type State = {
  newAuthorizedPerson: APResponse;
  updatedAuthorizedPerson: any;
  archivedAuthorizedPerson: ArchiveReponse;
  authorizedPersons: APResponse[];
};

const initialState: State = {
  newAuthorizedPerson: {
    authorizedPersonId: '',
    companyId: '',
    createdAt: ''
  },
  updatedAuthorizedPerson: {
    authorizedPersonId: '',
    companyId: '',
    firstName: '',
    lastName: '',
    createdAt: ''
  },
  archivedAuthorizedPerson: false,
  authorizedPersons: []
};

type CaseReducers<State> = {
  authorizedPersonCreateClear: CaseReducerType<State, object>;
  authorizedPersonUpdateClear: CaseReducerType<State, object>;
  authorizedPersonArchiveClear: CaseReducerType<State, object>;
};

export const authorizedPersonsSlice = createSlice<State, CaseReducers<State>>({
  name: "authorizedPersons",
  initialState,
  reducers: {
    authorizedPersonCreateClear: (state, { payload }) => {
      state.newAuthorizedPerson = initialState.newAuthorizedPerson
    },
    authorizedPersonUpdateClear: (state, { payload }) => {
      state.updatedAuthorizedPerson = initialState.updatedAuthorizedPerson
    },
    authorizedPersonArchiveClear: (state, { payload }) => {
      state.archivedAuthorizedPerson = initialState.archivedAuthorizedPerson
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authorizedPersonCreate.fulfilled, (state, action) => {
      state.newAuthorizedPerson = action.payload;
    });
    builder.addCase(authorizedPersonUpdate.fulfilled, (state, action) => {
      state.updatedAuthorizedPerson = action.payload;
    });
    builder.addCase(authorizedPersonsGet.fulfilled, (state, action) => {
      state.authorizedPersons = action.payload;
    });
    builder.addCase(authorizedPersonArchive.fulfilled, (state, action) => {
      state.archivedAuthorizedPerson = action.payload;
    });
  }
});

export const { authorizedPersonCreateClear, authorizedPersonUpdateClear, authorizedPersonArchiveClear } = authorizedPersonsSlice.actions;
export default authorizedPersonsSlice.reducer;
