import { moment } from '../kyc-front/ui-elements/FormDate'
import { Button, Alert } from 'antd'
import {
	MessageTwoTone, CloseOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { FC, useEffect, useState } from 'react'
import en from 'antd/lib/locale/en_GB'
import { dispatch } from '../../store/store'
import { LazyComponent } from '../layout/LazyComponent'
import { useSelector } from '../../hooks/useSelector'
import { setEnvs } from '../../store/kyc-front/slices/envsSlice'
import styled from 'styled-components'
import { themeColor } from '../../styles/themeStyles'
import { companyMessagesGet } from '../../store/kyc-messages/asyncThunks/companyMessagesGet'
import { companyBackMessageViewedPut } from '../../store/kyc-backend/asyncThunks/companyBackMessageViewedPut'
import { individualBackMessageViewedPut } from '../../store/kyc-backend/asyncThunks/individualBackMessageViewedPut'
import { companyMessageViewedPut } from '../../store/kyc-messages/asyncThunks/companyMessageViewedPut'
import { individualMessagesGet } from '../../store/kyc-messages/asyncThunks/individualMessagesGet'
import { individualMessageViewedPut } from '../../store/kyc-messages/asyncThunks/individualMessageViewedPut'
import { individualMessageViewedPutClear, companyMessageViewedPutClear } from '../../store/kyc-messages/slices/messagesSlice'
import { individualBackMessageViewedPutClear, companyBackMessageViewedPutClear } from '../../store/kyc-backend/slices/messagesSlice'
import { adminMessagesGet } from '../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { adminMessagesGetClear } from '../../store/kyc-backend/slices/adminInboxSlice'
import { useActionStatus } from '../../hooks/useActionStatus'
import { themeScreen } from '../../styles/themeStyles'


const CustomMessage = styled.div`
	background-color: #fff;
  	border: 1px solid #fff;
	.ant-alert {
		width: 100%;
    	height: 100%;
    	position: relative;
    	margin: 0 auto;
    	padding: 15px;
    	max-width: calc(1400px + 2%);
    	border: none;
    	background-color: #fff;
	}
	.ant-alert-message {
		margin-bottom: 0;
		display: inline-block;
	}
	.ant-alert-close-icon {
		position: relative;
    	left: 20px;
	}
	.ant-alert-icon {
    	margin-right: 15px;
    	font-size: 24px;
	}
	.ant-alert-description {
		display: inline-block;
		font-weight: 600;
		font-size: 16px;
		margin-left: 5px;
	}
	&.standalone {
		position: fixed;
		position: sticky;
    	top: 65px;
    	width: 100%;
    	z-index: 99;
    	border-bottom: 1px solid ${themeColor.grayWhite};
    	.ant-alert {
    		padding-left: 0;
    		padding-right: 0;
    		max-width: ${themeScreen.maxContent};
    	}
	}
`

interface CProps {
	config?: IProps
}

interface IProps {
	apiURL?:  string,
	adminSuffix?: string,
	token?: string,
	profileId: string,
	type?: string,
	env?: string,
	accountType: 'PRIVATE' | 'BUSINESS',
	routeSuffix: string
}


const KycMessage: FC<CProps> = ({
	config
}) => {
	const envs = useSelector((state) => state.general.envs);
	const nav = useSelector((state) => state.general.nav);
	let { env, type, profileId, routeSuffix, accountType, apiURL, adminSuffix } = config ? config : envs;
	const messages = useSelector((state) => state.messageModule.moduleMessages);
	const gettingStatus = useActionStatus(envs.accountType === 'BUSINESS' ? companyMessagesGet : individualMessagesGet);
	const gettingStatusAdmin = useActionStatus(adminMessagesGet);
	const [getting, setGetting] = useState(true);
	const [shown, setShown] = useState(false);
	const [envsLoaded, setEnvsLoaded] = useState(false);
	const [redirectPending, setRedirectPending] = useState(false)
	const [messageList, setMessageList] = useState<any>([]);
	const adminInbox = useSelector((state) => state.admin.adminInbox);
	const backMessages = useSelector((state) => state.admin.backMessages);
	let navigate = useNavigate();
	useEffect(() => {
		if(envs.type ==='standalone' && !envs.admin) {
			if(envs.accountType === 'BUSINESS') {
		        dispatch(companyMessagesGet({params: {companyId: envs.profileId}}));
		      } else {
		        dispatch(individualMessagesGet({params: null}));
		      }
		}
	}, [])
	useEffect(() => {
		if(envsLoaded === false && profileId !== '') {
			if(config) {
				let { env, type, token, profileId, routeSuffix, accountType, apiURL, adminSuffix } = config;
				dispatch(setEnvs({type, env, profileId, routeSuffix, accountType, apiURL, adminSuffix}));
			}
			setGetting(false);
			setEnvsLoaded(true);
		}
	}, [config])
	useEffect(() => {
		if(envs.admin) {
			if(gettingStatusAdmin.isPending && shown === true) {
				setShown(false);
			}
			if(gettingStatusAdmin.isFullfilled && shown === false) {
				//@ts-ignore
				let messageContentCompany = adminInbox.messages.companyMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy);
				//@ts-ignore
				let messageContentIndividual = adminInbox.messages.individualMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy);
		      	let messagesElems:any[] = [];
		        let messagesResponse = [...messageContentCompany, ...messageContentIndividual].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
		        setMessageList(messagesResponse)
		        setShown(true);
			}
		}
		
	}, [gettingStatusAdmin])
	useEffect(() => {
	    if(!gettingStatus.isFullfilled && envs.profileId !== '' && getting === false) {
	      if(envs.accountType === 'BUSINESS') {
	        dispatch(companyMessagesGet({params: {companyId: envs.profileId}}));
	      } else {
	        dispatch(individualMessagesGet({params: null}));
	      }
	      setGetting(true);
	    }
	}, [envs]);
	useEffect(() => {
      if(gettingStatus.isFullfilled && shown === false) {
      	let messageContent = envs.accountType === 'BUSINESS' ? messages.companyMessages : messages.individualMessages
      	let messagesElems:any[] = []
        messageContent.forEach(message => {
          if(!message.viewedAt && message.createdBy) {
          	messagesElems.push(message)
          }
        })
        let messagesResponse = [...messagesElems].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).reverse();
        setMessageList(messagesResponse)
        setShown(true);
        if(redirectPending === true) {
        	setRedirectPending(true);
        	navigate(envs.routeSuffix + '/inbox');
        }
      } 
      
	}, [messages]);
	useEffect(() => {
	      if(messages.companyMessageRead === 1) {
	        if(envs.accountType === 'BUSINESS') {
	          dispatch(companyMessageViewedPutClear({params: {}}));
	          dispatch(companyMessagesGet({params: {companyId: envs.profileId}}));
	        }
	      }
	}, [messages.companyMessageRead])
	useEffect(() => {
	      if(messages.individualMessageRead === 1) {
	        if(envs.accountType === 'PRIVATE') {
	          dispatch(individualMessageViewedPutClear({params: {}}));
	          dispatch(individualMessagesGet({params: null}));
	        }
	      }
	}, [messages.individualMessageRead])
	useEffect(() => {
		//@ts-ignore
	    if(backMessages.individualMessageRead.read === 1 && envs.admin) {
	        dispatch(individualBackMessageViewedPutClear({params: {}}));
	        dispatch(adminMessagesGet({params: null}));
	    }
	}, [backMessages.individualMessageRead])
	useEffect(() => {
		//@ts-ignore
	    if(backMessages.companyMessageRead.read === 1 && envs.admin) {
	        dispatch(companyBackMessageViewedPutClear({params: {}}));
	        dispatch(adminMessagesGet({params: null}));
	    }
	}, [backMessages.companyMessageRead]);
	const redirectToInbox = (id: string) => {
		if(type === 'module') {
			dismiss(id);
		}
		navigate(envs.routeSuffix + '/inbox');
	}
	const redirectToInboxAdmin = () => {
		navigate(envs.routeSuffix + '/messages');
	}
	const dismiss = (id: string, message?: any) => {
		setRedirectPending(false);
		if(envs.admin) {
			if(message.individualId) {
				dispatch(individualBackMessageViewedPut({params: {individualId: message.individualId,  messageId: message.messageId}, data: null})).then(() => setShown(false))
			} else {
				dispatch(companyBackMessageViewedPut({params: {companyId: message.companyId, messageId: message.messageId}, data: null})).then(() => setShown(false))
			}
		} else {
			if(envs.accountType === 'BUSINESS') {
				dispatch(companyMessageViewedPut({params: {companyId: envs.profileId, messageId: id}, data: null})).then(() => setShown(false))
			} else {
				dispatch(individualMessageViewedPut({params: {messageId: id}, data: null})).then(() => setShown(false))
			}
		}
		

	}
	//console.log(nav);
	return (
		<>
			{messageList.length ? (
				<CustomMessage className={envs.type ==='standalone' ? 'standalone' : ''}>
					<Alert
				      message="You've got new message: "
				      description={messageList[0].subject.startsWith('update') ? "Bitclear Admin has updated Your profile" : messageList[0].subject}
				      showIcon={true}
				      icon={<MessageTwoTone />}
				      type="info"
				      action={
				      	<>
				      		{nav.nav.navPage !== 'messages' &&
						      	<Button size="small" type='primary' style={{'marginRight': '5px', 'width': '115px'}} onClick={envs.admin ? () => redirectToInboxAdmin() : () => redirectToInbox(messageList[0].messageId)}>
						          	{'Read message'}
						        </Button>
						    }
					        <Button size="small" style={{'width': '110px'}} onClick={() => dismiss(messageList[0].messageId, messageList[0])}>
					          	Close <CloseOutlined />
					        </Button>
				        </>
				      }
				    />
				</CustomMessage>
			) : (<></>)}
		</>
	)
}



export default KycMessage