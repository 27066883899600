import { FC, useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Switch, message } from 'antd'
import { useSelector } from '../../../hooks/useSelector'
import { themeColor } from '../../../styles/themeStyles'
import { dispatch } from '../../../store/store'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { companyApproveFieldsUpdate } from '../../../store/kyc-backend/asyncThunks/companyApproveFieldsUpdate'
import { individualApproveFieldsUpdate } from '../../../store/kyc-backend/asyncThunks/individualApproveFieldsUpdate'
import { authorizedPersonApproveFieldsUpdate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonApproveFieldsUpdate'
import { uboApproveFieldsUpdate } from '../../../store/kyc-backend/asyncThunks/uboApproveFieldsUpdate'
import { companyDocumentApproveUpdateClear, individualDocumentApproveUpdateClear, uboDocumentApproveUpdateClear, authorizedPersonDocumentApproveUpdateClear, companyApproveFieldsUpdateClear, individualApproveFieldsUpdateClear, authorizedPersonApproveFieldsUpdateClear, uboApproveFieldsUpdateClear, authorizedPersonsApprovedFieldsGetClear, ubosApprovedFieldsGetClear } from '../../../store/kyc-backend/slices/approvedSlice'
import { companyApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/companyApprovedFieldsGet'
import { individualApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/individualApprovedFieldsGet'
import { individualDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/individualDocumentApproveUpdate'
import { authorizedPersonDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocumentApproveUpdate'
import { authorizedPersonsApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsApprovedFieldsGet'
import { companyDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/companyDocumentApproveUpdate'
import { uboDocumentApproveUpdate } from '../../../store/kyc-backend/asyncThunks/uboDocumentApproveUpdate'
import { ubosApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/ubosApprovedFieldsGet'
import { authorizedPersonDocsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocsBackGet'
import { uboBackDocsGet } from '../../../store/kyc-backend/asyncThunks/uboBackDocsGet'
import { individualBackDocsGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocsGet'
import { companyDocsGet } from '../../../store/kyc-backend/asyncThunks/companyDocsGet'
import styled from 'styled-components'

const Approve = styled(Col)`
    font-size: 13px;
    label {
        color: ${themeColor.gray};
    }
    .small {
        font-size: 12px;
        margin-left: 8px;
    }
    .ant-switch-checked {
        background-color: ${themeColor.green}
    }
    &.document-approve {
        position: relative;
        top: -37px;
        left: 80%;
        left: calc(100% - 120px);
        height: 0;
    }
`

interface IProps {
    onSwitchChange?: (value: any) => void,
    name: string,
    schemaParent?: string,
    schemaName?: string,
    type: 'ap' | 'ubo' | 'company' | 'individual' | 'document',
    isDocument?: boolean
    personId?: string
}

const ApproveInput: FC<IProps> = ({
   onSwitchChange, name, schemaParent, schemaName, type, isDocument, personId
}) => {
    const envs = useSelector((state) => state.general.envs);
    const admin = useSelector((state) => state.admin.admin);
    const [isApproved, setIsApproved] = useState(false);
    const [approvesCount, setApprovesCount] = useState(0);
    const [fieldName, setFieldName] = useState('');
    const [approvesListing, setApprovesListing] = useState([]);
    //@ts-ignore
    const approves = useSelector((state) => envs.accountType === 'BUSINESS' ? (type === 'ap' || type === 'ubo') ? type === 'ap' ? state.admin.approved.authorizedPersonsApprovedFields : state.admin.approved.ubosApprovedFields : state.admin.approved.companyApprovedFields : state.admin.approved.individualApprovedFields); 
    const documentsApproves = useSelector((state) => envs.accountType === 'BUSINESS' ? type === 'company' ? state.admin.backCompanyDocs.companyDocs : type === 'ap' ? state.admin.backAuthorizedPersonDocs.authorizedPersonDocs : state.admin.backUboDocs.uboDocs : state.admin.backIndividualDocs.individualDocs); 
    
    const approveUpdate = useSelector((state) => envs.accountType === 'BUSINESS' ? (type === 'ap' || type === 'ubo') ? type === 'ap' ? state.admin.approved.authorizedPersonApprovedFieldsUpdated : state.admin.approved.uboApprovedFieldsUpdated :state.admin.approved.companyApprovedFieldsUpdated : state.admin.approved.individualApprovedFieldsUpdated); 
    //documents
    const documentUpdate = useSelector((state) => envs.accountType === 'BUSINESS' ? type === 'company' ? state.admin.approved.companyDocumentApproveUpdated : type ==='ap' ? state.admin.approved.authorizedPersonDocumentApproveUpdated : state.admin.approved.uboDocumentApproveUpdated : state.admin.approved.individualDocumentApproveUpdated);
    
    const { isPending, isError, isFullfilled } = useActionStatus(envs.accountType === 'BUSINESS' ? (type === 'ap' || type === 'ubo') ? type === 'ap' ? authorizedPersonApproveFieldsUpdate : uboApproveFieldsUpdate : companyApproveFieldsUpdate : individualApproveFieldsUpdate);
    const approvesGettingStatus = useActionStatus(envs.accountType === 'BUSINESS' ? (type === 'ap' || type === 'ubo') ? type === 'ap' ? authorizedPersonsApprovedFieldsGet : ubosApprovedFieldsGet : companyApprovedFieldsGet : individualApprovedFieldsGet)
    const documentStatus = useActionStatus(envs.accountType === 'BUSINESS' ? type === 'company' ? companyDocumentApproveUpdate : type === 'ap' ? authorizedPersonDocumentApproveUpdate : uboDocumentApproveUpdate : individualDocumentApproveUpdate);
    const click = (el: any) => {
        if(isDocument) {
            if(envs.accountType === 'BUSINESS') {
                if(type === 'ap') {
                    dispatch(authorizedPersonDocumentApproveUpdate({params: {companyId: envs.profileId, authorizedPersonId: personId ? personId : '', documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
                }
                if(type === 'ubo') {
                    dispatch(uboDocumentApproveUpdate({params: {companyId: envs.profileId, beneficialOwnerId: personId ? personId : '', documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
                }
                if(type === 'company') {
                    dispatch(companyDocumentApproveUpdate({params: {companyId: envs.profileId, documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
                }
            } else {
                dispatch(individualDocumentApproveUpdate({params: {individualId: envs.profileId, documentId: name}, data: {name: name, data: {approve: !isApproved}}}))
            }
            
        } else {
            //@ts-ignore
            let nameEl;
            if(schemaParent) {
                if(schemaName) {
                    nameEl = {[schemaParent]: {[schemaName]: !isApproved}}
                } else {
                    //@ts-ignore
                    nameEl = {[schemaParent]: {[name]: !isApproved}}
                }
                
            } else {
                if(schemaName) {
                    nameEl = {[schemaName]: !isApproved}
                }
                let splitEl =  name?.split('.');
                if(splitEl?.length) {
                    if(splitEl?.length === 2) {
                        nameEl = {[splitEl[0]]: {[splitEl[1]]: !isApproved}}
                    }
                    if(splitEl?.length === 3) {
                        nameEl = {[splitEl[0]]: {[splitEl[1]]: {[splitEl[2]]: !isApproved}}}
                    }
                    
                } else {
                    //@ts-ignore
                    nameEl = {[name]: !isApproved}
                }
                
            }
            if(envs.accountType === 'BUSINESS') {
                if(type === 'ap' || type === 'ubo') {
                    if(type === 'ap') {
                        //@ts-ignore
                        dispatch(authorizedPersonApproveFieldsUpdate({params: {companyId: envs.profileId, authorizedPersonId: personId}, data: {data: nameEl, name: fieldName}}))
                    } else {
                        //@ts-ignore
                        dispatch(uboApproveFieldsUpdate({params: {companyId: envs.profileId, beneficialOwnerId: personId}, data: {data: nameEl, name: fieldName}}))
                    }
                } else {
                    //@ts-ignore
                    dispatch(companyApproveFieldsUpdate({params: {companyId: envs.profileId}, data: {data: nameEl, name: fieldName}}))
                }
            } else {
                //@ts-ignore
                dispatch(individualApproveFieldsUpdate({params: {individualId: envs.profileId}, data: {data: nameEl, name: fieldName}}));
            }
        }
        
        
    }
    useEffect(() => {
        let fieldName = schemaParent ? schemaName ? schemaParent + '.' + schemaName : schemaParent + '.' + name : name
        setFieldName(fieldName);
    }, [])

    useEffect(() => {
        if(isError || isFullfilled) {
            let typeCondition = false;
            if(type === 'ap' || type === 'ubo') {
                typeCondition = false;
                //@ts-ignore
                if((personId === approveUpdate.data.authorizedPersonId) || (personId === approveUpdate.data.beneficialOwnerId)) {
                    typeCondition = true
                }
            } else if (type === 'company' || type === 'individual') {
                typeCondition = true;
            }
            //@ts-ignore
            if((approveUpdate?.name === fieldName) && typeCondition ) {
                if(isError) {
                    message.error('Some error occured while updating this element');
                }
                if(isFullfilled) {
                    message.success('This element was sucessfully updated');
                    setIsApproved(!isApproved);
                }
                if(envs.accountType === 'BUSINESS') {
                    if(type === 'ap' || type === 'ubo') {
                        if(type === 'ap') {
                            dispatch(authorizedPersonApproveFieldsUpdateClear({}))
                            //@ts-ignore
                            dispatch(authorizedPersonsApprovedFieldsGet({params: {companyId: envs.profileId, authorizedPersonId: personId}}))
                        } else {
                            dispatch(uboApproveFieldsUpdateClear({}))
                            //@ts-ignore
                            dispatch(ubosApprovedFieldsGet({params: {companyId: envs.profileId, beneficialOwnerId: personId}}))
                        }
                    } else {
                        dispatch(companyApproveFieldsUpdateClear({}))
                        dispatch(companyApprovedFieldsGet({params: {companyId: envs.profileId}}))
                    }
                    
                } else {
                    dispatch(individualApproveFieldsUpdateClear({}))
                    dispatch(individualApprovedFieldsGet({params: {individualId: envs.profileId}}))
                }
            }
            
        }
    }, [approveUpdate]);

    useEffect(() => {
        if(approvesGettingStatus.isFullfilled || approvesGettingStatus.isError) {
            if(type === 'ap' || type === 'ubo') {
                //@ts-ignore
                if(approves && approves.personId === personId) {
                    //@ts-ignore
                    setApprovesListing(approves.data);
                    if(type === 'ap') {
                        dispatch(authorizedPersonsApprovedFieldsGetClear({}));
                    } else {
                        dispatch(ubosApprovedFieldsGetClear({}));
                    }
                    
                }
            } else {
                //@ts-ignore
                setApprovesListing(approves);
            }
            
        }  
    }, [approvesGettingStatus])
    useEffect(() => {
        if(documentStatus.isError || documentStatus.isFullfilled) {
            if(documentUpdate?.name === name) { //probably should check ap and ubo id here as well
                if(documentStatus.isError) {
                    message.error('Some error occured while updating this element'); 
                }
                if(documentStatus.isFullfilled) {
                    message.success('This element was sucessfully updated');
                    setIsApproved(!isApproved);
                }
                if(envs.accountType === 'BUSINESS') {
                    if(type === 'ap') {
                        dispatch(authorizedPersonDocumentApproveUpdateClear({}))
                        //@ts-ignore
                        dispatch(authorizedPersonDocsBackGet({params: {companyId: envs.profileId, authorizedPersonId: personId}}));
                    }
                    if(type === 'ubo') {
                        dispatch(uboDocumentApproveUpdateClear({}))
                        //@ts-ignore
                        dispatch(uboBackDocsGet({params: {companyId: envs.profileId, beneficialOwnerId: personId}}));
                    }
                    if(type === 'company') {
                        dispatch(companyDocumentApproveUpdateClear({}));
                        //@ts-ignore
                        dispatch(companyDocsGet({params: {companyId: envs.profileId}}))

                    }
                } else {
                    dispatch(individualDocumentApproveUpdateClear({}))
                    dispatch(individualBackDocsGet({params: {individualId: envs.profileId}}));
                }
            }
            
        }
    }, [documentUpdate])
    useEffect(() => {
        approvesListing.forEach(approve => {
            //@ts-ignore
            let approveName = approve.name.split('_').join('.');
            if((approveName === fieldName)) {
                //@ts-ignore
                let count = approve.approvedBy.length;
                setApprovesCount(count);
                //@ts-ignore
                if(approve.approvedBy.find((el: string) => el === admin.profile.adminId)) {
                    setIsApproved(true)
                } else {
                    if(isApproved) {
                        setIsApproved(false);
                    }
                }
                
            }
        }) 
    }, [approvesListing])
    useEffect(() => {
        documentsApproves.forEach(approveDoc => {
            //@ts-ignore
            if((approveDoc?.documentId === name)) {
                //@ts-ignore
                if(approveDoc.approvedBy.find(el => el === admin.profile.adminId)) {
                    setIsApproved(true)
                }
                //@ts-ignore
                let count = approveDoc.approvedBy.length;
                setApprovesCount(count);
            } else {
                //@ts-ignore
                if(Array.isArray(approveDoc.value)) {
                    //@ts-ignore
                    approveDoc.value.forEach(approveDocInner => {
                        if((approveDocInner?.documentId === name)) {
                            //@ts-ignore
                            if(approveDocInner.approvedBy.find(el => el === admin.profile.adminId)) {
                                setIsApproved(true)
                            } 
                            //@ts-ignore
                            let count = approveDocInner.approvedBy.length;
                            setApprovesCount(count);
                        }
                    })
                }
            }
        }) 
    }, [documentsApproves]);
    return (
        <Approve className={isDocument ? 'document-approve' : ''}>
            {!isDocument && <label>Approved: </label>}
            <Switch onChange={click} size="small" checked={isApproved} />
            <label className='small'>{approvesCount} of 2 </label>
        </Approve>
	)
}

export default ApproveInput
