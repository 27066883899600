import { FC, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { Typography } from 'antd'
import { useSelector } from '../../../hooks/useSelector'
import { redirectToError } from '../../../store/kyc-front/slices/errorsSlice'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName, nameParse, extractName } from '../../../utilities/nameParsers'
const { Paragraph } = Typography

export const ErrorParagraph = styled(Paragraph)`
    .anticon {
        color: ${themeColor.red};
        margin-right: 8px;
    }
    a {
        margin-left: 5px;
        color: ${themeColor.gray}
    }
    .details {
        transform: rotate(90deg);
        display: inline-block;
    }
`

const StyledList = styled.div`
    ul {
        margin-top: 10px;
        li {
            margin-left: 35px;
            margin-bottom: 5px;
        }
    }    
`

const LinkEl = styled.a`
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
`

interface EProps {
    visibleErrors: string[],
    elem: string,
    header: ReactNode,
    route: string,
    additionalAction?: any,
    business?: boolean,
    errorsListing: any,
    showErrors: (value: any) => void,
    formName: string,
    admin?: string,
    id?: string,
    listingType?: 'missingField' | 'missingApprove' | 'missingDocument'
}

export const ErrorElement: FC<EProps> = ({visibleErrors, elem, showErrors, errorsListing, header, route, business, formName, listingType, additionalAction, admin, id}) => {
    let err = id ? visibleErrors.find(error => error === id) : visibleErrors.find(error => error === elem);
    let navigate = useNavigate();
    const envs = useSelector((state) => state.general.envs);
    const nav = useSelector((state) => state.general.nav);
    let { formUpdated } = nav;
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
    const moveToError = (route: string, error: any, formName: string, errorType: 'error' | 'approve') => {
        if(additionalAction && formUpdated.updated) {
            additionalAction();
            if(listingType !== 'missingApprove') dispatch(redirectToError({route, error, formName}))
        } else {
            if(listingType !== 'missingApprove') dispatch(redirectToError({route, error, formName}))
            navigate(isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId +  '/' + route : envs.routeSuffix + '/' + route);
        }
        
    }
    return (
        <ErrorParagraph>
            <CloseCircleOutlined />{header}<LinkEl onClick={() => showErrors( id ? id : elem)}>Details <span className='details'>&gt;</span></LinkEl><LinkEl style={{'cursor': 'pointer', 'marginLeft': '3px'}} onClick={() => moveToError(route, 'error', formName, errorsListing[elem][0] && errorsListing[elem][0].fieldsSummary ? 'approve' : 'error' )}> Fix issues &gt;</LinkEl>
                {err &&
                    <StyledList>
                            {Array.isArray(errorsListing[elem]) ? (
                                <ul>
                                    {errorsListing[elem].map((error: any) => {
                                        if(error.fieldsSummary && error.fieldsSummary.isValid === true) { 
                                            return <></>
                                        } else {
                                            let errorType = error.validationErrors ? 'missingField' : 'missingApprove'
                                            let errorsSubList = errorType === 'missingField' ? error.validationErrors : error.fieldsSummary.validatedFields;
                                            return (
                                            <>
                                                {errorsSubList && <>
                                                    {errorsSubList.map((errorMsg: any) => {
                                                        if(errorMsg.approved === true) {
                                                            return <></>
                                                        }
                                                        let paramName = nameParse(errorType === 'missingField' ? errorMsg.slug : extractName(errorMsg.fieldName ? errorMsg.fieldName : errorMsg.name));
                                                        let personName = 'one of the profiles';
                                                        if(errorType === 'missingField') {
                                                            return (<li><b>{paramName}</b> is missing from {error.entry.firstName || error.entry.lastName ? (<b>{error.entry.firstName} {error.entry.lastName}</b>) : (<>one of the profiles</>)}</li>)
                                                        } else {
                                                            let personElem = error.authorizedPerson ? error.authorizedPerson : error.beneficialOwner;
                                                            return (<li><b>{paramName}</b> on <b>{personElem.firstName || personElem.lastName ? personElem.firstName + ' ' + personElem.lastName : 'one of the profiles'}</b> is not approved by <b>{errorMsg.approvedBy && <span>{errorMsg.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span>}</b></li>)
                                                        }
                                                        
                                                    })}
                                                </>}
                                            </>)
                                        }
                                        
                                    })}
                                 </ul>
                            ) : (
                                <>
                                    {errorsListing[elem]?.validatedFields ? (
                                        <>
                                            {Array.isArray(errorsListing[elem].validatedFields) && (
                                                <ul>
                                                    {errorsListing[elem].validatedFields.map((error: any) => {
                                                        if(error.approved === false) {
                                                            let nameEl = extractName(error.fieldName ? error.fieldName : error.name);
                                                            return (<li><b>{nameParse(nameEl)}</b> is not approved by <b>{error.approvedBy && error.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</b></li>)
                                                        }
                                                    })}
                                                </ul>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {errorsListing[elem]?.validations ? (
                                                <ul>
                                                    {errorsListing[elem].validations.map((errorListing: any) => {
                                                        let errorType = listingType;
                                                        let errorsSubList = errorType === 'missingDocument' ? errorListing.documentsSummary.errors : errorListing.documentsSummary.validatedDocuments;
                                                        return (
                                                        <>
                                                            {errorsSubList && <>
                                                                {errorsSubList.map((errorMsg: any) => {
                                                                    if(errorMsg.approved === true) {
                                                                        return <></>
                                                                    }
                                                                    let paramName = nameParse(errorType === 'missingDocument' ? errorMsg.slug : extractName(errorMsg.documentId));
                                                                    let personName = 'one of the profiles';
                                                                    let personElem = errorListing.authorizedPerson ? errorListing.authorizedPerson : errorListing.beneficialOwner;
                                                                    if(errorType === 'missingDocument') {
                                                                        let refElement = errorListing.documentsSummary.validatedDocuments.find((doc: any) => doc.documentId === errorMsg.ref);
                                                                        return (<li><b>{paramName}</b> on <b>{personElem.firstName || personElem.lastName ? personElem.firstName + ' ' + personElem.lastName : 'one of the profiles'}</b> is not approved by <b>{refElement?.approvedBy ? <span>{refElement.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span> : <span>You</span>}</b></li>)
                                                                    } else {
                                                                        return (<li><b>{paramName}</b> on <b>{personElem.firstName || personElem.lastName ? personElem.firstName + ' ' + personElem.lastName : 'one of the profiles'}</b> is not approved by <b>{errorMsg.approvedBy && <span>{errorMsg.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span>}</b></li>)
                                                                    }
                                                                    
                                                                })}
                                                            </>}
                                                        </>)
                                                    })}
                                                </ul>
                                            ) : (
                                                <>
                                                {listingType === 'missingDocument' ? (
                                                    <ul>
                                                        {errorsListing[elem]?.documentsSummary.errors.map((errorMsg: any) => {
                                                            let paramName = nameParse(errorMsg.slug);
                                                            let personName = 'user profile';
                                                            let refElement = errorsListing[elem]?.documentsSummary.validatedDocuments.find((doc: any) => doc.documentId === errorMsg.ref);
                                                            return (<li><b>{paramName === 'Company-document' ? 'Company document' : paramName}</b> is not approved by <b>{refElement?.approvedBy ? <span>{refElement.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span> : <span>You</span>}</b></li>)
                                                            
                                                        })}
                                                    </ul>
                                                ) : (
                                                    <ul>
                                                        {errorsListing[elem].validationErrors.map((errorMsg: any) => <li><b>{camelCaseToName(errorMsg.slug)}</b> is missing from <b>{business ? 'Company' : 'Personal'} profile</b></li>)}
                                                    </ul>
                                                )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                    </StyledList>
                }
        </ErrorParagraph>
    )
}