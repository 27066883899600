import { Spin } from 'antd'
import { FC, useEffect } from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { individualUpdate } from '../../../store/kyc-front/asyncThunks/individualUpdate'
import { individualDocsGet } from '../../../store/kyc-front/asyncThunks/individualDocsGet'
import { individualDocCreate } from '../../../store/kyc-front/asyncThunks/individualDocCreate'
import { individualDocUrlGet } from '../../../store/kyc-front/asyncThunks/individualDocUrlGet'
import { individualUpdateClear } from '../../../store/kyc-front/slices/individualSlice'
import { individualDocArchiveClear } from '../../../store/kyc-front/slices/individualDocsSlice'
import { individualDocArchive } from '../../../store/kyc-front/asyncThunks/individualDocArchive'
import { individualValidate } from '../../../store/kyc-front/asyncThunks/individualValidate'
import { individualValidateClear } from '../../../store/kyc-front/slices/statusSlice'
import KycLayout from '../kyc-layout/KycLayout'
import KycIndividualInfoForm from '../forms/KycIndividualInfoForm';

const KycIndividualInfo: FC = () => {	
	const individual = useSelector((state) => state.user.individual);
	const envs = useSelector((state) => state.general.envs);
	useEffect(() => {
		dispatch(individualGet())
	}, [])
	return (
      	<KycLayout pageName='Step 1' heading='Your personal details' nav={{step: 1, next: "/step2", prev: envs.type === 'standalone' ? '/summary' : "/"}}  validationAction={individualValidate} validationActionParams={{individualId: envs.profileId}}>
      		{individual.individual.individualId !== '' ? (
				<KycIndividualInfoForm 
					individual={individual} 
					individualGet={individualGet} 
					individualUpdate={individualUpdate} 
					individualClearUpdate={individualUpdateClear} 
					individualDocsGet={individualDocsGet}
    				individualDocCreate={individualDocCreate}
    				docUrlGet={individualDocUrlGet}
    				individualDocArchive={individualDocArchive}
    				individualDocArchiveClear={individualDocArchiveClear}
    				validationAction={individualValidate} 
            		validationActionClear={individualValidateClear}
				/>
			) : (
				<Spin size="large" />
			)}
		</KycLayout>
			 
	)
}

export default KycIndividualInfo
