import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { AuthorizedPersonsResponse } from "../slices/statusSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type PathParameters = Paths.ValidateAuthorizedPersons.PathParameters;

export const authorizedPersonsValidate: AsyncThunkActionType<
  AuthorizedPersonsResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "authorizedPersonsValidate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ValidateAuthorizedPersons(params, null, config);
      return response.data as AuthorizedPersonsResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
