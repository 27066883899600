import { Table, Tag, Space, Button, Typography, Input, Row, Col } from 'antd'
import type { InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/lib/table/interface'
import type { ColumnsType, ColumnType } from 'antd/lib/table'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import { PageContent } from '../../layout/PageContent'
import { store, dispatch } from '../../../store/store'
import { FC, useEffect, useState, useRef } from 'react'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import TableElement from '../../kyc-front/ui-elements/TableElement'
import { archivedCompanyProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/archivedCompanyProfilesBackGet'
import { individualProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfilesBackGet'
import { identityGetClear } from 'store/kyc-backend/slices/identitySlice'
import { archivedCompanyProfilesBackGetClear } from '../../../store/kyc-backend/slices/companySlice'
import { camelCaseToName } from '../../../utilities/nameParsers'
import { themeColor } from '../../../styles/themeStyles'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import { setNav, setFormUpdated } from '../../../store/kyc-front/slices/navSlice'

const { Text } = Typography;

const TableCustom = styled(Table)`
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        font-size: 15px;
        margin-top: -5px;
    }
`

interface DataType {
    title: string,
    dataIndex: string,
    key: string,
    render: any
}

type DataIndex = keyof DataType;


const ClientArchivedListing: FC = () => {
	const company = useSelector((state) => state.admin.backCompany);
    const individual = useSelector((state) => state.admin.backIndividual);
    const envs = useSelector((state) => state.general.envs);
    const adminProfile = useSelector((state) => state.admin.admin);
    const {apiURL, admin, adminSuffix, type, env, routeSuffix, profileId, accountType} = envs;
    const sortersList = ['createdAt','archivedAt'];
	const { archivedProfiles } = company;
    const { archivedIndividuals } = individual;
    let navigate = useNavigate();
	useEffect(() => {
        dispatch(archivedCompanyProfilesBackGet({}));
        dispatch(individualProfilesBackGet({params: {archived: true}}));
    }, []);
	return (
          <KycLayout listingView={true} pageName='Archived clients' nav={{hideNav: true, step: -1, navPage: 'archived'}}>
            <PageContent>
                <>
                    <Title level={2} style={{marginTop: -40, marginBottom: 20}}>Archived clients</Title> 
                    {archivedProfiles.length || archivedIndividuals.length ? (
                        <TableElement
                            sortersListProps={sortersList}
                            sortersState={{
                                createdAt: false,
                                archivedAt: false,
                            }}
                        >
                        {({onTableChange, getColumnSearchProps, getColumnFilterProps, defaultCurrent, sorters, filters}) => {
                            const archivedColumns = (redirectToUser: any) => ([
                              {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                //@ts-ignore
                                ...getColumnSearchProps('name'),
                                render: (text: string) => <b>{text}</b>,
                              },
                              {
                                title: 'Account type',
                                dataIndex: 'accountType',
                                key: 'accountType',
                                ...getColumnFilterProps('accountType',
                                [
                                    {
                                        text: 'BUSINESS',
                                        value: 'BUSINESS',
                                    },
                                    {
                                        text: 'PRIVATE',
                                        value: 'PRIVATE',
                                    },
                                ]),
                                onFilter: (value: string, record: any) => record.accountType.startsWith(value),
                                render: (relation: any) => <span>{relation}</span>,
                              },
                              {
                                title: 'Created at',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                sortOrder: sorters.createdAt,
                                //@ts-ignore
                                sorter: (a: any, b: any) => new Date(a.createdAt) - new Date(b.createdAt),
                                render: (created: string) => <span>{moment(created).format('YYYY-MM-DD HH:mm:ss')}</span>,
                              },
                              {
                                title: 'Archived at',
                                dataIndex: 'archivedAt',
                                key: 'archivedAt',
                                sortOrder: sorters.archivedAt,
                                //@ts-ignore
                                sorter: (a: any, b: any) => new Date(a.archivedAt) - new Date(b.archivedAt),
                                render: (approved: string) => <span>{moment(approved).format('YYYY-MM-DD HH:mm:ss')}</span>,

                              },
                              {
                                title: 'Action',
                                key: 'action',
                                render: (el: any) => (
                                    <Space size="middle">
                                        <ButtonUpper className='btn-sm' btnType='primary' onClick={() => redirectToUser(el)} ghost>Preview</ButtonUpper>
                                    </Space>
                                )
                              }
                            ]);
                            const redirectToUser = (el: any) => {
                                dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: el.id, accountType: el.accountType}));
                                //@ts-ignore
                                dispatch(setNav({paginationPage: defaultCurrent, sorters, filters, navPage: 'archived'}));
                                navigate(el.routeSuffix + '/' + el.id + '/summary');
                            }
                            return (
                                <TableCustom
                                    pagination={{ showSizeChanger: false, current: defaultCurrent }} 
                                    onChange={onTableChange} 
                                    columns={archivedColumns(redirectToUser)} 
                                    dataSource={[...archivedProfiles, ...archivedIndividuals].map((profile, i) => {
                                        //@ts-ignore
                                        return {name: profile.name ? profile.name : profile.profile?.firstName + ' ' + profile.profile?.lastName, key: i, createdAt: profile.createdAt, archivedAt: profile.archivedAt, accountType: profile.companyId ? 'BUSINESS' : 'PRIVATE', id: profile.companyId ? profile.companyId : profile.individualId, routeSuffix}
                                    })}
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
                </>
            </PageContent>
        </KycLayout>
	)
}

export default ClientArchivedListing
