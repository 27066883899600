import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualResponse } from "../slices/individualSlice";
import { Components } from "../../../api/kyc/generated/client";

type CreateIndividualRequest = Components.Schemas.NewIndividual;

export const individualCreate: AsyncThunkActionType<
  IndividualResponse,
  AsyncThunkDataType<null, CreateIndividualRequest, "data">
> = createAsyncThunk(
  "individualCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.createIndividual(params, data, config);

      return response.data as IndividualResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
