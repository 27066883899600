import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { authorizedPersonDocsGet } from "../asyncThunks/authorizedPersonDocsGet";
import { authorizedPersonDocUrlGet } from "../asyncThunks/authorizedPersonDocUrlGet";
import { authorizedPersonDocCreate } from "../asyncThunks/authorizedPersonDocCreate";
import { authorizedPersonDocArchive } from "../asyncThunks/authorizedPersonDocArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type AuthorizedPersonDocsResponse = Components.Schemas.AuthorizedPersonDocumentEntry;
export type UrlResponse = Paths.GetAuthorizedPersonDocument.Responses.$200;
export type ArchiveReponse = Paths.ArchiveAuthorizedPersonDocument.Responses.$200;

type State = {
    authorizedPersonDocs: AuthorizedPersonDocsResponse[];
    authorizedPersonDocUrl: {id: string, value: UrlResponse}[];
    newAuthorizedPersonDoc: AuthorizedPersonDocsResponse,
    authorizedPersonDocArchived: {id: string, value: ArchiveReponse}
};

const initialState: State = {
    newAuthorizedPersonDoc: {
        documentId: '',
        companyId: '',
        apId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    authorizedPersonDocs: [],
    authorizedPersonDocUrl: [],
    authorizedPersonDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    authorizedPersonDocCreateClear: CaseReducerType<State, object>;
    authorizedPersonDocsGetClear: CaseReducerType<State, object>;
    authorizedPersonDocUrlClear: CaseReducerType<State, object>;
    authorizedPersonDocArchiveClear: CaseReducerType<State, object>;
};

export const authorizedPersonDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "authorizedPersonDocs",
    initialState,
    reducers: {
        authorizedPersonDocCreateClear: (state, { payload }) => {
            state.newAuthorizedPersonDoc = initialState.newAuthorizedPersonDoc
        },
        authorizedPersonDocUrlClear: (state, { payload }) => {
            state.authorizedPersonDocUrl = initialState.authorizedPersonDocUrl
        },
        authorizedPersonDocsGetClear: (state, { payload }) => {
            state.authorizedPersonDocs = initialState.authorizedPersonDocs
        },
        authorizedPersonDocArchiveClear: (state, { payload }) => {
            state.authorizedPersonDocArchived = initialState.authorizedPersonDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authorizedPersonDocsGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocs.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocs.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
        builder.addCase(authorizedPersonDocCreate.fulfilled, (state, action) => {
            state.newAuthorizedPersonDoc = action.payload;
        });
        builder.addCase(authorizedPersonDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newAuthorizedPersonDoc = action?.payload;
        });
        builder.addCase(authorizedPersonDocArchive.fulfilled, (state, action) => {
            state.authorizedPersonDocArchived = action.payload;
        });
        builder.addCase(authorizedPersonDocUrlGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocUrl.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocUrl.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
    }
});

export const { authorizedPersonDocCreateClear, authorizedPersonDocUrlClear, authorizedPersonDocsGetClear, authorizedPersonDocArchiveClear } = authorizedPersonDocsSlice.actions;
export default authorizedPersonDocsSlice.reducer;
