import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc/generated/client";
import { companyCreate } from "../asyncThunks/companyCreate";
import { companyUpdate } from "../asyncThunks/companyUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type CompanyResponse = Components.Schemas.NewCompanyProfileResponse;
export type CompanyUpdatedResponse = Components.Schemas.CompanyResponse;

type State = {
  new: CompanyResponse;
  updated: CompanyUpdatedResponse;
};

const emptyProfile = {
    businessRelation: {
        isActive: false
    },
    companyId: '',
    name: '',
    legalForm: '',
    registrationNumber: '',
    placeOfIncorporation: '',
    phone: '',
    fax: '',
    createdAt: ''
}

const initialState: State = {
  new: {
    profile: emptyProfile,
    auth: {
      user: '',
      password: ''
    }
  },
  updated: {
    companyId: '',
    profile: emptyProfile
  }
};

type CaseReducers<State> = {
  companyUpdateClear: CaseReducerType<State, object>;
  companyCreateClear: CaseReducerType<State, object>;
};

export const companySlice = createSlice<State, CaseReducers<State>>({
  name: "company",
  initialState,
  reducers: {
    companyUpdateClear: (state, { payload }) => {
        state.updated = initialState.updated
    },
    companyCreateClear: (state, {payload}) => {
        state.new = initialState.new
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyCreate.fulfilled, (state, action) => {
        state.new = action.payload
    });
    builder.addCase(companyUpdate.fulfilled, (state, action) => {
        state.updated = action.payload
    });
  },
});

export const { companyUpdateClear, companyCreateClear } = companySlice.actions;
export default companySlice.reducer;
