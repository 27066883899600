import { Spin, Divider, Row, Col, Collapse, Empty, message, Popconfirm } from 'antd'
import _ from 'lodash'
import { moment } from '../ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { FC, useEffect, useState } from 'react'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import Title from 'antd/es/typography/Title'
import KycLayout from '../kyc-layout/KycLayout'
import KycUboForm, { addressType, documentEntry } from '../forms/KycUboForm'
import { themeColor, themeWeight } from '../../../styles/themeStyles'
import { ButtonUpper } from '../ui-elements/Buttons'
import { UboResponse } from '../../../store/kyc-front/slices/ubosSlice'
import { APResponse } from '../../../store/kyc-front/slices/authorizedPersonsSlice'
import styled from 'styled-components'
import Well from '../ui-elements/Well'
import { CustomCollapse, CollapsePanel, CollapseSubtitle } from '../ui-elements/CustomCollapse'

const { Panel } = Collapse

const CustomDivider = styled(Divider)`
	margin-bottom: 0;
	&.new {
		margin-left: -20px;
    	margin-right: -20px;
    	width: initial;
	}
`

const Profile = styled.div`
	background-color: ${themeColor.blueLight};
	padding: 15px 20px;
	margin-bottom: 25px;
	text-align: center;
	font-weight: ${themeWeight.bold};
	cursor: pointer;
`

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

type resp = UboResponse[];

interface IProps {
	profile: any,
	profileGet: any,
	authorizedPersons: any,
	authorizedPersonsGet: any,
	ubos: any,
	ubosGet: any,
	uboCreate: any,
	uboUpdate: any,
	uboClearCreate: any,
	uboClearUpdate: any,
	uboArchive: any,
	uboClearArchive: any,
	uboDocsGet?: any,
   uboDocCreate?: any,
   docUrlGet?: any,
   uboDocArchive?: any,
	uboDocArchiveClear?: any,
	validationAction?: any,
   validationActionClear?: any
}

const KycUbosListing: FC<IProps> = ({
	profile, profileGet, authorizedPersons, authorizedPersonsGet, ubos, uboDocsGet, uboDocCreate, docUrlGet, uboDocArchive, uboDocArchiveClear, ubosGet, uboCreate, uboUpdate, uboClearCreate, uboClearUpdate, uboArchive, uboClearArchive, validationAction, validationActionClear
}) => {
	const [ubosList, setUbosList] = useState([] as resp);
	const [ubosLoaded, setUbosLoaded] = useState(false);
	const [newPanelAdded, setNewPanelAdded] = useState(false);
	const [messageShown, setMessageShown] = useState(true);
	const [created, setCreated] = useState(false);
	const [activeTabs, setActiveTabs] = useState<string[]>([]);
	const [showArchived, setShowArchived] = useState(false);
	let [disabled, setDisabled] = useState(false);
	const envs = useSelector((state) => state.general.envs);
	const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status) 
   	const userProfile = profile;
	let [popupEditVisible, setPopupEditVisible] = useState(false);
	let [popupAddApVisible, setPopupAddApVisible] = useState('');
	const { isPending, isError, isFullfilled } = useActionStatus(ubosGet);
	const createStatus = useActionStatus(uboCreate);
	const updateStatus = useActionStatus(uboUpdate);
	const validateStatus = useActionStatus(validationAction);
	const errorsState = useSelector((state) => state.general.errorsState);
	useEffect(() => {
        dispatch(ubosGet({ params: { companyId: envs.profileId} }))
        dispatch(authorizedPersonsGet({ params: { companyId: envs.profileId} }))
        if(userProfile.companyId === '') {
            dispatch(profileGet({ params: { companyId: envs.profileId } }));
        }
    }, []);
	useEffect(() => {
        if((userProfile?.profile?.reviewStatus === 'SubmittedForReview') && (disabled === false)) {
            setDisabled(true);
        }
    }, [userProfile])
	//Showing all collapsed elements from errors listing
	useEffect(() => {
      	if(status.beneficialOwnersValidated && validateStatus.isFullfilled) {
      		status.beneficialOwnersValidated.forEach((personStatus) => {
      			if(activeTabs.find(el => el === personStatus.entry)) {
      				return
      			} else {
      				//@ts-ignore
      				if(personStatus.validationErrors.length) {
      					setActiveTabs(oldList => [personStatus.entry, ...oldList]);
      				}
      				
      			}
      		})
      	}
      	
    }, [status.beneficialOwnersValidated]);
	 useEffect(() => {
        if(!_.isEmpty(errorsState) && (errorsState.route === 'step3')) {
            dispatch(validationAction({ params: { companyId: envs.profileId } }));
        }
    }, [errorsState])
    useEffect(() => {
    	if(messageShown) {
            if(createStatus.isFullfilled && (ubos.newUbo.id !== '')) {
               message.success('Person was sucessfully added');
               setActiveTabs(oldList => [ubos.newUbo.id, ...oldList]);
               dispatch(uboClearCreate({}));
               dispatch(ubosGet({ params: { companyId: envs.profileId} }))
               setCreated(true);
               setMessageShown(false);
            }
            if(createStatus.isError) {
               message.error('Some error occured while adding new person');
               dispatch(uboClearCreate({}));
               setMessageShown(false);
            }
      }
      if(isFullfilled && (created === true)) {
         setNewPanelAdded(true);
         setCreated(false);
				let timeId;
				clearTimeout(timeId)
				timeId = setTimeout(() => {
			      setNewPanelAdded(false)
			}, 1500)
      }
    });
    const handleApClick = (person: APResponse) => {
    	const identityDocument: documentEntry = person.identityDocument;
    	const residentialAddress: addressType = person.residentialAddress;
    	let initialValues = {
	        personalDetails: {
	            firstName: person.firstName || '',
	            lastName: person.lastName || '',
	            dateOfBirth: person.dateOfBirth || undefined,
	            placeOfBirth: person.placeOfBirth || '',
	            residentialAddress: {
	                address: residentialAddress?.address || '',
	                postcode: residentialAddress?.postcode || '',
	                city: residentialAddress?.city || '',
	                country: residentialAddress?.country || ''
	            },
	            nationality: person.nationality || '',
	            occupation: person.occupation || '',
	            email: person.email || '',
	            phone: person.phone || '',
	            identityDocument: {
	                kind: identityDocument?.kind || 'PassportKind',
	                number: identityDocument?.number || '',
	                placeOfIssue: identityDocument?.placeOfIssue || '',
	                dateOfIssue: identityDocument?.dateOfIssue || undefined,
	                dateOfExpiry: identityDocument?.dateOfExpiry || undefined,
	                nationality: identityDocument?.nationality || ''
	            }
	        },
	        referencedAuthorizedPerson: person.authorizedPersonId
	   }
    	//@ts-ignore
    	dispatch(uboCreate({ params: { companyId: envs.profileId}, data: initialValues}));
    	//.then(response => dispatch(setFormUpdated({updated: true, form: response.payload.beneficialOwnerId, formName: 'uboForm'})))
    	setMessageShown(true);
    }
    const addUbo = () => {
		let newElement = {personalDetails: {}};
		//@ts-ignore
		dispatch(uboCreate({ params: { companyId: envs.profileId}, data: newElement}));
		//.then(response => dispatch(setFormUpdated({updated: true, form: response.payload.beneficialOwnerId, formName: 'uboForm'})))
		setMessageShown(true);
	}
	const handleCollapseChange = (els: string[] | never[]) => {
		setActiveTabs([...els]);
	}
	const cancelEdit = () => {
        setPopupEditVisible(false);
   }
   const cancelAddAp = () => {
   	setPopupAddApVisible('')
   }
   const handleEditVisibleChange = () => {
        setPopupEditVisible(!popupEditVisible);
   }
   const handleAddApVisibleChange = (id: string) => {
   	setPopupAddApVisible(popupAddApVisible === '' ? id : '');
   }
   const confirmEdit = () => {
      setPopupEditVisible(false);
      addUbo();
      //dispatch new state for kyc form - PartiallyFilled
  	}
  	const confirmAdd = (person: APResponse) => {
      setPopupAddApVisible('');
      handleApClick(person)
      //dispatch new state for kyc form - PartiallyFilled
  	}
  	const handleShowArchived = () => {
  		setShowArchived(!showArchived);
  	}

	let array = [...ubos.ubosList];
	let archivedArray:any[] = [];
	if(array) {
		if(envs.admin) {
			let adminArray:string[] = [];
			array.forEach(el => {
				if(el.archivedAt) {
					archivedArray.push(el);
				} else {
					adminArray.push(el);
				}
			})
			array = adminArray;
		}
		array.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
		archivedArray.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
		
	}
	return (
      	<KycLayout pageName='Step 3' heading='Ultimate Beneficial Owner/s' nav={{step: 3, next: "/step4", prev: "/step2", navSubpage: 'kyc'}} adminView={envs.admin} validationAction={validationAction} validationActionParams={{companyId: envs.profileId}}>
      		<div style={{'marginBottom': '-17px'}}>
	      		<Text><b>Please add all UBOs with 25% or more ownership and/or controlling rights.</b></Text>
	      		<div style={{'marginBottom': '15px'}}>
	      			<Text>Add new UBO or copy the data when UBO is one of the Directors / Users.</Text><br />
	      			<Text disabled>When you choose someone from the Directors / Users, you have to fill in additional data.</Text>
	      		</div>
	      		{authorizedPersons?.authorizedPersons?.length ? (
		      		<Row gutter={30}>
		      			{authorizedPersons.authorizedPersons.map((ap: APResponse,i: number) => {
		      				let hasUbo = _.find(ubos.ubosList, function(o) { return o.referencedAuthorizedPerson === ap.authorizedPersonId });
		      				if(!hasUbo && (ap.archivedAt == undefined)) {
		      					return (
			      					<Col span={8} key={ap.authorizedPersonId}>
						      			{disabled ? (
							      			<PopconfirmCustom
							                   title="If You make this change it may block Your account until Bitclear AG approves the changes"
							                   visible={popupAddApVisible === ap.authorizedPersonId ? true : false}
							                   onVisibleChange={() => handleAddApVisibleChange(ap.authorizedPersonId)}
							                   onConfirm={() => confirmAdd(ap)}
							                   onCancel={cancelAddAp}
							                   okText={"Add " + (ap.firstName ? ap.firstName : 'New') + " " + (ap.lastName ? ap.lastName : 'Person') + " as UBO"}
							                   cancelText="Cancel"
							                   icon={<QuestionCircleOutlined />}>
							                   <Profile>{(ap.firstName ? ap.firstName : 'New') + ' ' + (ap.lastName ? ap.lastName : 'Person')} </Profile>
							            	</PopconfirmCustom>
						            	) : (
						            		<Profile onClick={() => handleApClick(ap)}>{(ap.firstName ? ap.firstName : 'New') + ' ' + (ap.lastName ? ap.lastName : 'Person')} </Profile>
						            	)}
						      		</Col>
			      				)
		      				} return 
		      			})}
		      		</Row>
		      	):<></>}
	      		<div>
	      			{disabled ? (
		      			<PopconfirmCustom
		                   title="If You make this change it may block Your account until Bitclear AG approves the changes"
		                   visible={popupEditVisible}
		                   onVisibleChange={handleEditVisibleChange}
		                   onConfirm={confirmEdit}
		                   onCancel={cancelEdit}
		                   okText="Add new UBO"
		                   cancelText="Cancel"
		                   icon={<QuestionCircleOutlined />}>
		            		<ButtonUpper className='btn-sm' btnType="primary" ghost>{authorizedPersons?.authorizedPersons?.length ? 'Add other UBO' : 'Add new UBO'}</ButtonUpper>
		            	</PopconfirmCustom>
	            	) : (
	            		<ButtonUpper className='btn-sm' btnType="primary" ghost onClick={addUbo}>{authorizedPersons?.authorizedPersons?.length ? 'Add other UBO' : 'Add new UBO'}</ButtonUpper>
	            	)}
	            	<CustomDivider className={newPanelAdded === true ? 'new' : ''} />
		      		{ubos.ubosList.length && ubos.ubosList.find((el: any) => !el.archivedAt) ? (
		      			<>
			      			<CustomCollapse
			      			 		id='ubosForm'
						          	activeKey={activeTabs}
						          	bordered={false}
						          	onChange={handleCollapseChange}
						          	ghost={true}
						          	expandIcon={(props: any) => <ButtonUpper btnType="default">Details <DownOutlined rotate={props?.isActive ? 180 : 0} /></ButtonUpper>}
						          	expandIconPosition='right'
						        >
						        {array.map((person, i) => {
							        	return (
							        		<Panel className={i === 0 && newPanelAdded === true ? 'new' : ''} key={person.beneficialOwnerId} header={(<CollapsePanel archived={person.archivedAt ? true : false} name={ (person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person') } role='Ultimate Beneficial Owner' />)}>
									          	<KycUboForm 
									          		person={person}
									          		ubosGet={ubosGet}
									          		uboUpdate={uboUpdate}
									          		uboClearUpdate={uboClearUpdate}
									          		uboArchive={uboArchive}
									          		uboClearArchive={uboClearArchive}
									          		uboDocsGet={uboDocsGet}
														uboDocCreate={uboDocCreate}
														docUrlGet={docUrlGet}
														uboDocArchive={uboDocArchive}
		    											uboDocArchiveClear={uboDocArchive}
		    											validationAction={validationAction}
														validationActionClear={validationActionClear}
									          	/>
									      </Panel>
							        	)
						        })}

						    </CustomCollapse>
					    </>
					) : (
						<>
							{isFullfilled ? (
								<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description='No UBOs' />
							) : (
								<Spin size="large" style={{'marginTop': '30px'}} />
							)}
						</>
					)}

				</div>
				{envs.admin &&
					<>
						<Row>
							<Col span={24} style={{'textAlign': 'right', 'paddingTop': '15px', 'paddingBottom': '15px'}}>
								<ButtonUpper btnType='dashed' className='btn-sm dashed-primary right' ghost onClick={handleShowArchived}>{showArchived ? 'Hide archived UBOs' : 'Show archived UBOs'}</ButtonUpper>
							</Col>
						</Row>
						{showArchived &&
							<Row>
								<Col span={24}>
									{archivedArray.length ? (
						      			 <CustomCollapse
									          	activeKey={activeTabs}
									          	bordered={false}
									          	onChange={handleCollapseChange}
									          	ghost={true}
									          	expandIcon={(props: any) => <ButtonUpper btnType="default">Details <DownOutlined rotate={props?.isActive ? 180 : 0} /></ButtonUpper>}
									          	expandIconPosition='right'
									        >
									        {archivedArray.map((person, i) => {
										        	return (
										        		<Panel key={person.beneficialOwnerId} header={(<CollapsePanel archived={person.archivedAt ? true : false} name={(person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person')} role='Ultimate Beneficial Owner' />)}>
												          	<KycUboForm 
												          		person={person}
												          		deleteForm={false}
												          		restoreForm={true}
												          		ubosGet={ubosGet}
												          		uboUpdate={uboUpdate}
												          		uboClearUpdate={uboClearUpdate}
												          		uboArchive={uboArchive}
												          		uboClearArchive={uboClearArchive}
												          		uboDocsGet={uboDocsGet}
    															uboDocCreate={uboDocCreate}
    															docUrlGet={docUrlGet}
    															uboDocArchive={uboDocArchive}
    															uboDocArchiveClear={uboDocArchive}
    															validationAction={validationAction}
																validationActionClear={validationActionClear}
												          	/>
												      </Panel>
										        	)
									        })}

									    </CustomCollapse>
									) : (
										<>
											{isFullfilled ? (
												<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description='No archived UBOs' />
											) : (
												<Spin size="large" style={{'marginTop': '30px'}} />
											)}
										</>
									)}
								</Col>
							</Row>
						}
						</>
					}
			</div>
		</KycLayout>
			 
	)
}

export default KycUbosListing
