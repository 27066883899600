import { FC } from 'react'
import { Row, Col, Tag, Collapse } from 'antd' 
import Text, { TextProps } from 'antd/es/typography/Text'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import { themeColor, themeWeight } from '../../../styles/themeStyles'

const CustomCollapse = styled(props => <Collapse {...props} />)`
	.ant-collapse-item {
		background-color: white;
		transition: all 0.3s;
		border-bottom: 2px solid ${themeColor.grayOpaque}!important;
		.ant-collapse-header {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 30px;
			padding-top: 30px;
			margin-bottom: -1px;
			.ant-btn {
				font-size: 15px;
				right: 0;
				.anticon {
					font-size: 10px;
					span {
						margin-left: 5px;
					}
			}
			.ant-collapse-arrow {
				right: 0;
			}
		}
		&:last-child {
			border-bottom: none!important;
			.ant-collapse-header {
				margin-bottom: -30px;
			}
		}
		&.new {
			background-color: ${themeColor.blueWhite};
			margin-left: -20px;
    		margin-right: -20px;
    		padding: 0 20px;
    		.ant-typography {
    			color: ${themeColor.primaryColor}
    		}
		}
		
	}
	.ant-collapse-content .ant-collapse-content-box {
			padding-left: 0!important;
			padding-right: 0!important;
		}
`

const CustomRow = styled(Row)`
	&.archived {
		color: ${themeColor.grayLight};
		h3 {
			color: ${themeColor.grayBasic}
		}
	}
`

interface CProps {
	archived?: boolean,
	name: string,
	role: string
}

const CollapsePanel: FC<CProps> = ({name, role, archived}) => (
  	<CustomRow justify="space-between" className={archived ? 'archived' : ''}>
	    <Col>
	        <Title level={3}>{name}{archived && <Tag style={{'position': 'relative', 'left': '8px', 'top': '-5px'}}>Archived</Tag>}</Title>
	        <CollapseSubtitle>{role}</CollapseSubtitle>
	    </Col>
	</CustomRow>
)

const CollapseSubtitle = styled(Text)`
	font-weight: ${themeWeight.bold};
	font-size: 12px;
`


export { CustomCollapse, CollapsePanel, CollapseSubtitle };