import Title from 'antd/es/typography/Title'
import moment from 'moment'
import { moment as momentDate } from '../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Space, Row, Col, Spin, Statistic, Timeline, Divider, message, Popconfirm } from 'antd'
import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { store, dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { companyFxAccountCreate } from '../../../store/kyc-backend/asyncThunks/companyFxAccountCreate'
import { individualFxAccountCreate } from '../../../store/kyc-backend/asyncThunks/individualFxAccountCreate'
import { individualFxAccountCreateClear, companyFxAccountCreateClear } from '../../../store/kyc-backend/slices/fxAccountSlice'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { businessRelationGet } from '../../../store/kyc-backend/asyncThunks/businessRelationGet'
import { businessRelationIndividualGet } from '../../../store/kyc-backend/asyncThunks/businessRelationIndividualGet'
import { businessRelationCreate } from '../../../store/kyc-backend/asyncThunks/businessRelationCreate'
import { businessRelationArchive } from '../../../store/kyc-backend/asyncThunks/businessRelationArchive'
import { businessRelationIndividualArchive } from '../../../store/kyc-backend/asyncThunks/businessRelationIndividualArchive'
import { businessRelationIndividualCreate } from '../../../store/kyc-backend/asyncThunks/businessRelationIndividualCreate'
import { companyDocsGet } from '../../../store/kyc-backend/asyncThunks/companyDocsGet'
import { companyDocArchive } from '../../../store/kyc-backend/asyncThunks/companyDocArchive'
import { companyDocCreate } from '../../../store/kyc-backend/asyncThunks/companyDocCreate'
import { companyDocUrlGet } from '../../../store/kyc-backend/asyncThunks/companyDocUrlGet'
import { individualBackDocsGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocsGet'
import { individualBackDocUrlGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocUrlGet'
import { individualBackDocCreate } from '../../../store/kyc-backend/asyncThunks/individualBackDocCreate'
import { individualBackDocArchive } from '../../../store/kyc-backend/asyncThunks/individualBackDocArchive'
import { companyDocsGetClear, companyDocCreateClear, companyDocUrlGetClear, companyDocArchiveClear } from '../../../store/kyc-backend/slices/companyDocsSlice'
import { individualBackDocsGetClear, individualBackDocCreateClear, individualBackDocUrlGetClear, individualBackDocArchiveClear } from '../../../store/kyc-backend/slices/individualDocsSlice'
import { businessRelationGetClear,  businessRelationCreateClear, businessRelationIndividualGetClear, businessRelationIndividualCreateClear, businessRelationIndividualArchiveClear, businessRelationArchiveClear } from '../../../store/kyc-backend/slices/businessRelationSlice'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { SuccessInfo } from '../../kyc-front/ui-elements/SuccessInfo'
import { uppercaseFirstLetter } from '../../../utilities/nameParsers'
import { themeColor } from '../../../styles/themeStyles'
import FormUpload from '../../kyc-front/ui-elements/FormUpload'
import styled from 'styled-components'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'


const BusinessRelation: FC = () => {
	const envs = useSelector((state) => state.general.envs);
	const businessRelation = useSelector((state) => state.admin.businessRelation);
	const individualProfile = useSelector((state) => state.admin.backIndividual);
	const companyProfile = useSelector((state) => state.admin.backCompany);
	const fxAccount = useSelector((state) => state.admin.fxAccount);
	const isBusiness = envs.accountType === 'BUSINESS';
	const clientDocs = useSelector((state) => isBusiness ? state.admin.backCompanyDocs.companyDocs : state.admin.backIndividualDocs.individualDocs);
	const clientDocArchived = useSelector((state) => isBusiness ? state.admin.backCompanyDocs.companyDocArchived : state.admin.backIndividualDocs.individualDocArchived)
	const newDoc = useSelector((state) => isBusiness ? state.admin.backCompanyDocs.newCompanyDoc : state.admin.backIndividualDocs.newIndividualDoc)
    const docUrlResp = useSelector((state) => isBusiness ? state.admin.backCompanyDocs.companyDocUrl : state.admin.backIndividualDocs.individualDocUrl)
	const [dataLoaded, setDataLoaded] = useState(false);
	const [gettingBackDocs, setGettingBackDocs] = useState(false);
	let [popupDeactivateVisible, setPopupDeactivateVisible] = useState(false);
	let [deactivating, setDeactivating] = useState(false);
	let [start, setStart] = useState(false);
	const adminProfile = useSelector((state) => state.admin.admin);
	let relation = isBusiness ? businessRelation.businessRelationCompany : businessRelation.businessRelationIndividual;
	let docsListing, personType, docCreate, docClear, docsGet, docGetClear, docsGetParams, docUrlGet, docUrlClear, docDelete, docDeleteClear, uploadType;
	if(isBusiness) {
		//@ts-ignore
		docsListing = clientDocs[0]?.value 
		personType='companyId'
		docCreate=companyDocCreate
		docClear=companyDocCreateClear
		docsGet=companyDocsGet
		docGetClear=companyDocsGetClear
		docsGetParams={companyId: envs.profileId}
		docUrlGet=companyDocUrlGet
		docUrlClear=companyDocUrlGetClear
		docDelete=companyDocArchive
		docDeleteClear=companyDocArchiveClear
		uploadType='company'
	} else {
		docsListing = clientDocs; 
		personType='individualId'
		docCreate=individualBackDocCreate
		docClear=individualBackDocCreateClear
		docsGet=individualBackDocsGet
		docGetClear=individualBackDocsGetClear
		docsGetParams={individualId: envs.profileId} 
		docUrlGet=individualBackDocUrlGet
		docUrlClear=individualBackDocUrlGetClear
		docDelete=individualBackDocArchive
		docDeleteClear=individualBackDocArchiveClear
		uploadType='individual'
	}
	useEffect(() => {
		if(dataLoaded === false && envs.accountType !== '') {
			if(isBusiness) {
	        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
	            dispatch(businessRelationGet({params: {companyId: envs.profileId}}));
	        }
	        if(envs.accountType === 'PRIVATE') {
	        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
	            dispatch(businessRelationIndividualGet({params: {individualId: envs.profileId}}));
	        }
	        setDataLoaded(true);
		}
        
    }, [envs.accountType]);
    useEffect(() => {
		dispatch(setFormUpdated({updated: false, form: []}));
  		return () => {
            dispatch(businessRelationGetClear({}));
            dispatch(businessRelationCreateClear({}));
            dispatch(businessRelationArchiveClear({}));
            dispatch(businessRelationIndividualGetClear({}));
            dispatch(businessRelationIndividualCreateClear({}));
            dispatch(businessRelationIndividualArchiveClear({}));
            setStart(false);
            
        }
    }, []);
    useEffect(() => {
  		if(gettingBackDocs === true) {
  			setGettingBackDocs(false);
  		}
    }, [gettingBackDocs]);
    useEffect(() => {
    	if(isBusiness) {
	    	let stateEl = businessRelation.newBusinessRelationCompany;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
			} else {
				//@ts-ignore
				if(stateEl?.businessRelation?.isActive) {
					message.success(uppercaseFirstLetter('Relation succesfully started'));
					dispatch(businessRelationGet({params: {companyId: envs.profileId}}));
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
					dispatch(businessRelationCreateClear({}));
					setStart(true);
				}
			}
		}
        
    }, [businessRelation.newBusinessRelationCompany]);
    useEffect(() => {
    	if(!isBusiness) {
	    	let stateEl = businessRelation.newBusinessRelationIndividual;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
			} else {
				//@ts-ignore
				if(stateEl?.businessRelation?.isActive) {
					message.success(uppercaseFirstLetter('Relation started succesfully'));
					dispatch(businessRelationIndividualGet({params: {individualId: envs.profileId}}));
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
	            	dispatch(businessRelationIndividualCreateClear({}));
	            	setStart(true);
				}
			}
		}
        
    }, [businessRelation.newBusinessRelationIndividual]);
    useEffect(() => {
    	if(isBusiness) {
	    	let stateEl = businessRelation.archivedBusinessRelation;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
			} else {
				//@ts-ignore
				if(stateEl?.businessRelation?.isActive === false) {
					if(deactivating === true) {
						message.success(uppercaseFirstLetter('Relation deactivated succesfully'));
					} else {
						setDeactivating(false);
					}
	            	dispatch(businessRelationArchiveClear({}));
					dispatch(businessRelationGet({params: {companyId: envs.profileId}}));
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
					dispatch(companyDocsGet({params: {companyId: envs.profileId}}));
					setGettingBackDocs(true);
				}
			}
		}
        
    }, [businessRelation.archivedBusinessRelation]);
    useEffect(() => {
    	if(!isBusiness) {
	    	let stateEl = businessRelation.archivedBusinessRelationIndividual;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
			} else {
				//@ts-ignore
				if(stateEl?.businessRelation?.isActive === false) {
					if(deactivating === true) {
						message.success(uppercaseFirstLetter('Relation deactivated succesfully'));
					} else {
						setDeactivating(false);
					}
	            	dispatch(businessRelationIndividualArchiveClear({}));
					dispatch(businessRelationIndividualGet({params: {individualId: envs.profileId}}));
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
					dispatch(individualBackDocsGet({params: {individualId: envs.profileId}}));
					setGettingBackDocs(true);
				}
			}
		}
        
    }, [businessRelation.archivedBusinessRelationIndividual]);
    useEffect(() => {
    	if(isBusiness) {
	    	let stateEl = fxAccount.accountCompany;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
				dispatch(companyFxAccountCreateClear({}));
			} else {
				//@ts-ignore
				if(stateEl) {
					message.success(uppercaseFirstLetter('FX Account succesfully created'));
					dispatch(businessRelationGet({params: {companyId: envs.profileId}}));
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
					dispatch(companyFxAccountCreateClear({}));
					//setStart(true);
				}
			}
		} else {
			let stateEl = fxAccount.accountIndividual;
	    	//@ts-ignore
			if(stateEl.errors) {
				//@ts-ignore
				message.error(uppercaseFirstLetter(stateEl.errors));
				dispatch(individualFxAccountCreateClear({}));
			} else {
				//@ts-ignore
				if(stateEl) {
					message.success(uppercaseFirstLetter('FX Account succesfully created'));
					dispatch(businessRelationGet({params: {companyId: envs.profileId}}));
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
					dispatch(individualFxAccountCreateClear({}));
					//setStart(true);
				}
			}
		}
        
    }, [fxAccount]);

    const startBusinessRelation = () => {
    	if(isBusiness) {
            dispatch(businessRelationCreate({params: {companyId: envs.profileId}, data: {startAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss.00000000')}}))
        }
        if(envs.accountType === 'PRIVATE') {
            dispatch(businessRelationIndividualCreate({params: {individualId: envs.profileId}, data: {startAt: moment.utc().format('YYYY-MM-DDTHH:mm:ss.00000000')}}))
        }
    }
    const revokeBusinessRelation = () => {
    	if(isBusiness) {
            return dispatch(businessRelationArchive({params: {companyId: envs.profileId}, data: null}))
        } else {
            return dispatch(businessRelationIndividualArchive({params: {individualId: envs.profileId}, data: null}))
        }
    }
    const toggleBusinessRelation = () => {
    	//@ts-ignore
    	if(relation.errors || relation.businessRelation.isActive === false) {
    			startBusinessRelation();
    			//@ts-ignore
    			//revokeBusinessRelation().then(startBusinessRelation());
    		
    	} else {
    		revokeBusinessRelation();
    	}
    }
    const handleDeactivateVisibleChange = () => {
        setPopupDeactivateVisible(!popupDeactivateVisible);
    }
    const cancelDeactivate = () => {
        setPopupDeactivateVisible(false);
    }
    const confirmDeactivate = () => {
    	setDeactivating(true);
        revokeBusinessRelation();
    }
    const startFxAccount = () => {
    	if(isBusiness) {
    		dispatch(companyFxAccountCreate({params: { companyId: envs.profileId}, data: null }));
    	}
    	if(envs.accountType === 'PRIVATE') {
    		dispatch(individualFxAccountCreate({params: { individualId: envs.profileId}, data: null}));
    	}
    }
	return (
		<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'relation'}}>
			{/*@ts-ignore*/}
			{start ? (
				<SuccessInfo linkAction={() => setStart(false)} linkEl={envs.routeSuffix + '/' + envs.profileId + '/relation'} linkText="See relation details">
					<p>Business Relation started successfully</p>
				</SuccessInfo>
			) : (
			<>
				<Row gutter={30}>
					<Col span={24}>
						<Text style={{'fontSize': '38px', 'position': 'relative', 'top' : '-35px', 'fontWeight':'bold'}}>Current status: 
							{/*@ts-ignore*/}
							<Text type={relation.errors ? "secondary" : relation?.businessRelation?.isActive ? "success" : "secondary"}><b> {relation.errors ? 'Not initialized' : relation?.businessRelation?.isActive ? 'Active' : 'Not active'}</b></Text>
						</Text>
						<br />
						<Text style={{'fontSize': '30px', 'position': 'relative', 'fontWeight':'bold'}}>FX Account: 
							{/*@ts-ignore*/}
							<Text type={companyProfile.profile.profile?.externalAccountId ||  individualProfile.individual?.externalAccountId ? "success" :  "secondary"}><b> {companyProfile.profile.profile?.externalAccountId ||  individualProfile.individual.externalAccountId ? 'Created' : 'Not created'}</b></Text>

						</Text>
						<Divider style={{marginBottom: 0}} />
					</Col>
				</Row>
	            <Row>
	            	{/*@ts-ignore*/}
	            	<Col span={relation?.businessRelation?.periods.length ? 12 : 24}>
	        			{envs.accountType !== '' && gettingBackDocs === false &&
	        				<>
	        					{/*@ts-ignore*/}
								<FormUpload name='Signed KYC documents' disabled={false} adminOnly={true} docs={docsListing} docType='KYCSignedDeclaration' uploadAdmin={true} personId={envs.profileId} personType={personType} newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docArchived={clientDocArchived} docDelete={docDelete} docDeleteClear={docDeleteClear} type={uploadType} businessRelation={true} />
							</>
						}
	            	</Col>
	            	{/*@ts-ignore*/}
	            	{relation?.businessRelation?.periods.length && 
		            	<Col span={12} style={{paddingLeft: 45}}>
	            			<>
		            			<Title level={4} style={{'marginBottom': '20px', 'marginTop': '15px'}}>History of Business Relations: </Title>
						        <Timeline mode="left">
						        	{/*@ts-ignore*/}
						        	{[...relation.businessRelation.periods].reverse().map((period, i) => (
						        		<Timeline.Item key={i} dot={period.endAt ? <CloseCircleOutlined style={{ color: 'gray' }}  /> : <CheckCircleOutlined style={{ fontSize: '16px', color: themeColor.green }} />}>
						        			<p>{period.endAt ? <>Duration: <b>{momentDate(period.startAt).format('YYYY-MM-DD HH:mm:ss')} - {moment(period.endAt).format('YYYY-MM-DD HH:mm:ss')}</b></> : <>Active since: <b>{moment(period.startAt).format('YYYY-MM-DD HH:mm:ss')}</b></>}</p>
						        			{/*@ts-ignore*/}
						        			<p>Created at {momentDate(period.createdAt).format('YYYY-MM-DD HH:mm:ss')} by <b>{uppercaseFirstLetter(adminProfile?.profiles.find(el => el.adminId === period.createdBy).username)}</b></p> 
						        			{/*@ts-ignore*/}
						        			{period.endedBy && <p>Ended by <b>{uppercaseFirstLetter(adminProfile?.profiles.find(el => el.adminId === period.endedBy).username)}</b></p>}
						        		</Timeline.Item>
						        	))}
								 </Timeline>
							  </>
		            	</Col>
		            }
	            	
	            </Row>
	            <Row justify="end">
	            	<Col style={{marginBottom: 15}}>
	            		{/*@ts-ignore*/}
	            		{relation?.businessRelation?.isActive ? (
	            			<>
			            		<Popconfirm
			                        title="Are You sure you want to deactivate this Business Relation?"
			                        visible={popupDeactivateVisible}
			                        onVisibleChange={handleDeactivateVisibleChange}
			                        onConfirm={confirmDeactivate}
			                        onCancel={cancelDeactivate}
			                        okText="Yes, deactivate"
			                        cancelText="Cancel"
			                        icon={<QuestionCircleOutlined />}
			                    >
									<ButtonUpper btnType={'primary'} ghost>Deactivate business relation</ButtonUpper>
			            		</Popconfirm>
			            		{!individualProfile?.individual?.externalAccountId && !companyProfile?.profile?.profile?.externalAccountId &&
				            		<span style={{marginLeft: 10}}>
				            			<ButtonUpper onClick={startFxAccount} btnType={'primary'}>Create FX Account</ButtonUpper>
				            		</span>
				            	}
		            		</>
		            		) : (
		            			<ButtonUpper btnType={'primary'} onClick={toggleBusinessRelation}>Start Business Relation</ButtonUpper>
	            		)}
	            	</Col>
	            </Row>
            </>
            )}
		</KycLayout>
			 
	)
}

export default BusinessRelation
