import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type ProfilesResponse = Components.Schemas.IndividualProfile;
type PathParameters = Paths.GetIndividualProfiles.QueryParameters;

export const individualProfilesBackGet: AsyncThunkActionType<
  ProfilesResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("individualProfilesBackGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.getIndividualProfiles(data.params, null, data?.config);
    return response.data as ProfilesResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
