import { Table, Tag, Space, Button, Typography, Input, Row, Col, List } from 'antd'
import _ from 'lodash'
import type { InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/lib/table/interface'
import type { ColumnsType, ColumnType, TableProps } from 'antd/lib/table'
import { SearchOutlined, FilterOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import { FC, useEffect, useState, useRef, ReactNode } from 'react'
import { themeColor } from '../../../styles/themeStyles'
import Highlighter from 'react-highlight-words'

const { Text } = Typography;

const TableElementCustom = styled.div`
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        font-size: 15px;
        margin-top: -5px;
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
        padding: 16px 8px;
    }
    .ant-table-filter-trigger {
        margin-right: -5px;
    }
    .ant-btn-primary {
        padding: 1px 8px;
    }
`

const ListItemCustom = styled(List.Item)`
    &:hover, &.selected {
        background-color: ${themeColor.grayWhite};
        cursor: pointer;
        //color: #fff;
    }
`

interface DataType {
    title: string,
    dataIndex: string,
    key: string,
    render: any
}

type DataIndex = keyof DataType;

interface IProps {
    sortersListProps: string[],
    sortersState: any,
    children: (props: any) => ReactNode
}


const TableElement: FC<IProps> = ({sortersListProps, sortersState, children}) => {
    const envs = useSelector((state) => state.general.envs);
    const {apiURL, admin, adminSuffix, type, env, routeSuffix, profileId, accountType} = envs;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredColumn, setFilteredColumn] = useState('');
    const [defaultCurrent, setDefaultCurrent] = useState(1);
    //const [customColumns, setCustomColumns] = useState({});
    const sortersList = sortersListProps;
    const [sorters, setSorters] = useState(sortersState);
    const [filters, setFilters] = useState({
        name: false,
        accountType: '',
        reviewStatus: '',
        businessRelation: '',
        productType: '',
        fxAccount: false
    })
    const navEl = useSelector((state) => state.general.nav);
    const searchInput = useRef<InputRef>(null);
    const filterInput = useRef<InputRef>(null);
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
        reset?: boolean
    ) => {
        confirm();
        if(!reset) {
            //@ts-ignore
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
        } else {
            setSearchText('');
            setSearchedColumn('');
        }
        
    };
    const handleFilter = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setFilteredColumn(dataIndex);
    };
    const handleReset = (selectedKeys: any[],  confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex, clearFilters?: () => void, ) => {
        //@ts-ignore
        clearFilters();
        handleSearch([''], confirm, dataIndex, true);
        
    };
    const handleFilterReset = (selectedKeys: any[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex, clearFilters?: () => void, ) => {
        //@ts-ignore
        clearFilters();
        handleFilter(selectedKeys as string[], confirm, dataIndex)

    };
    let navigate = useNavigate();
    let location = useLocation();
    useEffect(() => {
        setPageToState();
        setSortToState();
        setFilterToState();
        //settingCustomColumns();
    }, []);
    useEffect(() => {
        setPageToState();
        setSortToState();
        setFilterToState();
    }, [location.search]);
    /*const settingCustomColumns = () => {
        let newColumns = [...columns];
        let modifiedIndex = -1;
        let filteredCol = newColumns.find((el: any, i) => {
            if(el.dataIndex === 'name') {
                modifiedIndex = i;
                return true;
            }
        });
        if(modifiedIndex > -1) {
            //@ts-ignore
            newColumns[modifiedIndex] = {...filteredCol, ...getColumnSearchProps('name')};
        }
        setCustomColumns(newColumns);
    }*/
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filteredValue: filters.name ? [filters.name] : undefined,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
              <div style={{ padding: 8 }} key={dataIndex}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: '100%' }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(selectedKeys, confirm, dataIndex, clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
              </div>
        )},
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
          ) : (
            text
          ),
    });
    const getColumnFilterProps = (dataIndex: DataIndex, filtersValues: any): ColumnType<DataType> => ({
        //@ts-ignore
        filteredValue: filters[dataIndex] ? [filters[dataIndex]] : undefined,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            const handleFilterClick = (item: any) => {
                setSelectedKeys([item.value]);
                handleFilter(selectedKeys as string[], confirm, dataIndex);
            }
            return (
              <div style={{ padding: 0 }} key={dataIndex}>
                <List
                  size="small"
                  bordered
                  dataSource={filtersValues}
                  //@ts-ignore
                  renderItem={item => <ListItemCustom className={selectedKeys[0] === item.value ? 'selected' : ''} onClick={() => handleFilterClick(item)}>{item.text}</ListItemCustom>}
                />
                <Space style={{padding: 8, justifyContent: 'center'}}>
                    <Button
                        onClick={() => handleFilterReset(selectedKeys, confirm, dataIndex, clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
              </div>
            )
        },
         filterIcon: (filtered: boolean) => (
            <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => 
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => filterInput.current?.select(), 100);
            }
        },
        render: (text: any) =><>text</>
    });
    const setPageToState = () => {
        let paramsEl = new URLSearchParams(location.search);
        let paramsPage = paramsEl.get('page');
        if(paramsPage) {
            setDefaultCurrent(parseInt(paramsPage));
        }
    }
    const setSortToState = () => {
        let paramsEl = new URLSearchParams(location.search);
        //@ts-ignore
        setSorters((prevState) => {
            let newSorter = {};
            _.forEach(prevState, (elem, nameEl) => {
                //@ts-ignore
                newSorter[nameEl] = paramsEl.get(nameEl) ? paramsEl.get(nameEl) : false
            })
            return ({
              ...newSorter,
            })
        });
    }
    const setFilterToState = () => {
        let paramsEl = new URLSearchParams(location.search);
        //@ts-ignore
        setFilters((prevState) => {
            let newFilter = {};
            _.forEach(prevState, (elem, nameEl) => {
                //@ts-ignore
                newFilter[nameEl] = paramsEl.get(nameEl) ? paramsEl.get(nameEl) : false
            });
            return ({
              ...newFilter,
            });
        });
    }
    const handlePaginationChange = (el: any) => {
        let paramsEl = new URLSearchParams(location.search);
        let paramsPage = paramsEl.set('page', el);
        //setDefaultCurrent(el);
        //@ts-ignore
        navigate(location.pathname + paramsEl ? '?' + paramsEl.toString() : '');
    }
    const handleSortChange = (name: string, order: string) => {
        let paramsEl = new URLSearchParams(location.search);
        sortersList.forEach((el) => paramsEl.delete(el));
        paramsEl.set(name, order ? order : '');
        //@ts-ignore
        navigate(location.pathname + paramsEl ? '?' + paramsEl.toString() : '');
    }
    const handleFilterChange = (filters: any) => {
        let paramsEl = new URLSearchParams(location.search);
        _.forEach(filters, (el, val) => {
            if(el) {
                paramsEl.set(val, el && el !== '' ? el[0] : '');
            } else {
                paramsEl.delete(val);
            }
            
        });
        //@ts-ignore
        navigate(location.pathname + paramsEl ? '?' + paramsEl.toString() : '');
    }
    const onTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        switch(extra.action) {
            case 'paginate':
                handlePaginationChange(pagination.current);
                return;
            case 'sort':
                //@ts-ignore
                handleSortChange(sorter?.columnKey, sorter?.order);
                return;
            case 'filter':
                handleFilterChange(filters);
                return;
            default:
                return
        }
    }
    const basicFormProps = {getColumnSearchProps, getColumnFilterProps, onTableChange, sorters, filters, defaultCurrent};
	return (
        <TableElementCustom>
            <div>
                {children(basicFormProps)}
            </div>
        </TableElementCustom>
	)
}

export default TableElement;
