import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type MessageRequest = Components.Schemas.InternalMessage;
type MessageResponse = Components.Schemas.CompanyInternalMessageEntry;
type PathParameters = Paths.CreateCompanyInternalMessages.PathParameters;

export const companyBackMessagePost: AsyncThunkActionType<
  MessageResponse[],
  AsyncThunkDataType<PathParameters, MessageRequest, "params">
> = createAsyncThunk("companyBackMessagePost", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CreateCompanyInternalMessages(data.params, data.data, data?.config);
    return response.data as MessageResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
