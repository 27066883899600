import { Field, ErrorMessage } from 'formik'
import { Row, Col, Radio, Input } from 'antd'
import React, { FC } from 'react'
import { themeColor } from '../../../styles/themeStyles'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import ApproveInput from "../../kyc-backend/ui-elements/Approve"

const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

const SubLabel = styled.label`
    color: ${themeColor.gray};
    display: block;
`

const LabelEl = styled.label`
    padding: 0;
    margin: 0;
    &.checkbox-space {
        margin-top: 10px;
    }
`

const FormControl = styled.label`
    display: grid;
    grid-template-columns: 16px auto;
    gap: 8px;
    line-height: 16px;

    &--disabled {
      color: var(--form-control-disabled);
      cursor: not-allowed;
    }
    &.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }

    input[type="checkbox"], input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--form-background);
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 16px;
      height: 16px;
      border: 1px solid ${themeColor.gray};
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      &:disabled {
          background-color: ${themeColor.grayWhite}
      }
    }

    input[type="checkbox"]::before, input[type="radio"]::before {
      content: "";
      width: 10px;
      height: 10px;
      clip-path: polygon(18% 45%, 42% 73%, 89% 14%, 98% 14%, 42% 84%, 9% 45%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: ${themeColor.grayBlack};
    }

    input[type="checkbox"]:checked::before, input[type="radio"]:checked::before  {
      transform: scale(1);
    }

    input[type="checkbox"]:focus, input[type="radio"]:focus {
      outline: 1px solid ${themeColor.primaryColor};
    }

    input[type="checkbox"]:disabled, input[type="radio"]:disabled {
      --form-control-color: var(--form-control-disabled);
      color: var(--form-control-disabled);
      cursor: not-allowed;
    }
`

const CheckRow = styled(Row)`
    &.checkContainer {
        margin-top: 10px;
    }
`
interface Options {
    [index: number]: {
        label: string,
        value: string | boolean
    }
}

interface CProps {
    label?: string,
    id:  string,
    error?: string,
    type: 'checkbox' | 'radio',
    approveType: 'ap' | 'ubo' | 'company' | 'individual',
    subLabel?: string,
    disabled?: boolean,
    options: { label: string, value: string }[],
    className?: string,
    onChange?: (value: any) => void,
    onSwitchChange?: (value: any) => void,
    showApprove?: boolean,
    showAdditionalApprove?: boolean,
    schemaParent?: string,
    schemaName?: string,
    personId?: any
}

interface SProps {
    label?: string,
    id:  string,
    error?: string,
    type: 'checkbox' | 'radio',
    disabled?: boolean,
    value?: string | boolean,
    onChange?: (value: any) => void
}

interface SFProps {
    label?: string,
    id:  string,
    type: 'checkbox' | 'radio',
    disabled?: boolean,
    value: string | number | readonly string[] | undefined,
    options: { label: string, value: string }[],
    onChange?: (value: any) => void
}


export const FormCheckboxRadio: FC<CProps> = ({
    label, id, error, type, disabled, options, subLabel, className, onChange, showApprove, showAdditionalApprove, onSwitchChange, schemaParent, schemaName, approveType, personId
}) => {
    const envs = useSelector((state) => state.general.envs);
	return (
        <>
            {(label || ApproveInput) && showApprove !== false ? (
                <LabelEl style={{'width': '100%'}} className={className ? 'ant-form-item-label ' + className : 'ant-form-item-label'}>
                    <Row justify="space-between">
                        {label &&
                            <Col>{label}</Col>
                        }
                        {envs.admin &&
                            <>
                                {showAdditionalApprove === false ? <></> : <ApproveInput onSwitchChange={onSwitchChange} name={id} schemaParent={schemaParent} schemaName={schemaName} type={approveType} personId={personId} />}
                            </>
                        }
                    </Row>
                </LabelEl>
            ) : (<></>)}
            {subLabel &&
                <SubLabel className='ant-form-item-label'>{subLabel}</SubLabel>
            }
            <CheckRow role="group" gutter={15} className='checkContainer'>
                {options.map(function(option, i) {
                    return (
                        <Col key={option.value + '' + i}>
                            <FormControl className={error?.length ? "error" : ""}>
                                <Field type={type} name={id} value={option.value} onFocus={onChange} disabled={disabled} />
                                {option.label}
                            </FormControl>
                        </Col>
                    )
                })}
            </CheckRow>
            <ErrorContainer>
                <ErrorMessage name={id} />
            </ErrorContainer>
       </>
			 
	)
}

export const FormCheckboxRadioStandalone: FC<SProps> = ({
    label, id, error, type, disabled, value, onChange
}) => {
    return (
        <Row>
            <Col>
                <FormControl className={error?.length ? "error" : ""}>
                    <Field type={type} name={id} value={value} onFocus={onChange} disabled={disabled}/>
                    {label}
                </FormControl>
                <ErrorContainer>
                    <ErrorMessage name={id} />
                </ErrorContainer>
           </Col>
       </Row>
             
    )
}

export const FormCheckboxRadioFormStandalone: FC<SFProps> = ({
    label, id, type, disabled, value, onChange, options
}) => {
    return (
        <Row>
            <Col>
                <CheckRow role="group" gutter={15} className='checkContainer'>
                    {options.map(function(option, i) {
                        return (
                            <Col key={option.value + '' + i}>
                                <FormControl>
                                    <Input type={type} name={id} value={option.value} onFocus={onChange} disabled={disabled} checked={value === option.value} />
                                    {option.label}
                                </FormControl>
                            </Col>
                        )
                    })}
                </CheckRow>
           </Col>
       </Row>
             
    )
}
