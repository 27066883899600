import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { authorizedPersonDocsBackGet } from "../asyncThunks/authorizedPersonDocsBackGet";
import { authorizedPersonDocUrlBackGet } from "../asyncThunks/authorizedPersonDocUrlBackGet";
import { authorizedPersonDocBackCreate } from "../asyncThunks/authorizedPersonDocBackCreate";
import { authorizedPersonDocBackArchive } from "../asyncThunks/authorizedPersonDocBackArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type AuthorizedPersonDocsResponse = Components.Schemas.AuthorizedPersonDocument;
export type UrlResponse = Paths.GetAuthorizedPersonDocument.Responses.$200
export type ArchiveReponse = Paths.ArchiveAuthorizedPersonDocument.Responses.$200;
type ErrorResponse = {
    error: boolean,
    data: any
}

type State = {
    authorizedPersonDocs: AuthorizedPersonDocsResponse[];
    authorizedPersonDocUrl: {id: string, value: UrlResponse}[];
    newAuthorizedPersonDoc: AuthorizedPersonDocsResponse | ErrorResponse,
    authorizedPersonDocArchived: {id: string, value: ArchiveReponse}
};

const initialState: State = {
    newAuthorizedPersonDoc: {
        documentId: '',
        companyId: '',
        apId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    authorizedPersonDocs: [],
    authorizedPersonDocUrl: [],
    authorizedPersonDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    authorizedPersonDocBackCreateClear: CaseReducerType<State, object>;
    authorizedPersonDocUrlBackGetClear: CaseReducerType<State, object>;
    authorizedPersonDocsBackGetClear: CaseReducerType<State, object>;
    authorizedPersonDocBackArchiveClear: CaseReducerType<State, object>;
};

export const authorizedPersonDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "authorizedPersonBackDocs",
    initialState,
    reducers: {
        authorizedPersonDocBackCreateClear: (state, { payload }) => {
            state.newAuthorizedPersonDoc = initialState.newAuthorizedPersonDoc
        },
        authorizedPersonDocUrlBackGetClear: (state, { payload }) => {
            state.authorizedPersonDocUrl = initialState.authorizedPersonDocUrl
        },
        authorizedPersonDocsBackGetClear: (state, { payload }) => {
            state.authorizedPersonDocs = initialState.authorizedPersonDocs
        },
        authorizedPersonDocBackArchiveClear: (state, { payload }) => {
            state.authorizedPersonDocArchived = initialState.authorizedPersonDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authorizedPersonDocsBackGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocs.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocs.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
        builder.addCase(authorizedPersonDocBackCreate.fulfilled, (state, action) => {
            state.newAuthorizedPersonDoc = action.payload;
        });
        builder.addCase(authorizedPersonDocBackCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newAuthorizedPersonDoc = action?.payload;
        });
        builder.addCase(authorizedPersonDocBackArchive.fulfilled, (state, action) => {
            state.authorizedPersonDocArchived = action.payload;
        });
        builder.addCase(authorizedPersonDocUrlBackGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.authorizedPersonDocUrl.findIndex((el) => el.id == action.meta.arg.params.authorizedPersonId);
            if (elInd > -1) {
                //@ts-ignore
                state.authorizedPersonDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.authorizedPersonDocUrl.push({id: action.meta.arg.params.authorizedPersonId, value: action.payload})
            }
        });
    },
});

export const { authorizedPersonDocBackCreateClear, authorizedPersonDocUrlBackGetClear, authorizedPersonDocsBackGetClear, authorizedPersonDocBackArchiveClear } = authorizedPersonDocsSlice.actions;
export default authorizedPersonDocsSlice.reducer;
