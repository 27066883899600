import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type DocsResponse = Components.Schemas.AuthorizedPersonDocumentEntry;
type PathParameters = Paths.AuthorizedPersonDocuments.PathParameters;

export const authorizedPersonDocsGet: AsyncThunkActionType<
  DocsResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("authorizedPersonDocsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.AuthorizedPersonDocuments(data.params, null, data?.config);
    return response.data as DocsResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
