import Title from 'antd/es/typography/Title'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Space, Row, Col, Spin } from 'antd'
import { setAuth } from '../../../store/kyc-front/slices/authSlice'
import { companyCreate } from '../../../store/kyc-front/asyncThunks/companyCreate'
import { individualCreate } from '../../../store/kyc-front/asyncThunks/individualCreate'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { store, dispatch } from '../../../store/store'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { camelCaseToName } from '../../../utilities/nameParsers'
import styled from 'styled-components'
import KycDocsInfo from '../KycDocsInfo'
import { KycDocumentsSteps } from '../KycDocumentsSteps';
import KycNextSteps from '../KycNextSteps'
import { themeColor, themeSize } from '../../../styles/themeStyles'
import KycLayout from '../kyc-layout/KycLayout'
import KycPrint from '../KycPrint'


const TextMain = styled(Text)`
	font-size: ${themeSize.heading5};
	line-height: 32px;
`

const TextCustom = styled(Text)`
	&.ant-typography.ant-typography-success {
		color: ${themeColor.green}!important;
	}
`

const KycIntro: FC = () => {
	const profile = useSelector((state) => state.user.profile)
	const individual = useSelector((state) => state.user.individual)
	const envs = useSelector((state) => state.general.envs)
	const printRef = useRef(null)
  	const { userProfile } = profile;
  	const companyGetStatus = useActionStatus(profileGet);
  	const individualGetStatus = useActionStatus(individualGet);
  	const status = envs.accountType === 'BUSINESS' ? userProfile?.profile?.reviewStatus : individual?.individual?.reviewStatus;
  	const review = status === 'SubmittedForReview';
  	const partiallyFilled = status === 'NewPartiallyFilled';
  	const approved = status === 'Approved';
  	useEffect(() => {
  		if(envs.accountType === 'BUSINESS') {
  			dispatch(profileGet({ params: { companyId: envs.profileId} }));
  		} else {
  			dispatch(individualGet());
  		}
    }, []);
	return (
		<KycLayout pageName='Intro' heading='Welcome to KYC' nav={{next: "/step1", step: 0, nextName: status === 'NewReviewStatus' ? "Get started" : "Next step", nextButton: "primary", status: status}}>
			{!individual.individual.businessRelation.isActive && !profile?.userProfile?.profile?.businessRelation.isActive &&
				<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0'}}>
					<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Current KYC status: 
						<TextCustom type={status === 'SubmittedForReview' || status === 'Approved' ? "success" : status === 'NewReviewStatus' || status === 'NewPartiallyFilled' ? "secondary" : "secondary"}><b> {camelCaseToName(status)} </b></TextCustom>
					</Text>
				</Col>
			}
			{companyGetStatus.isFullfilled || individualGetStatus.isFullfilled ? (
				<>
					{individual?.individual?.businessRelation.isActive || profile?.userProfile?.profile?.businessRelation.isActive ? (
						<>
							<Col span={24} style={{'marginBottom': '20px', 'paddingLeft': '0'}}>
								<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Business Relation: 
									<TextCustom type='success'><b> Active </b></TextCustom>
								</Text>
							</Col>
							<Title level={4}>
								Your Bitclear Applications are Active
							</Title>
							<TextMain>
								We've recieved Your documents and approved them<br />
								All functionalities of Bitclear Applications have been unlocked for You<br />
								<br />
							</TextMain>
							<KycPrint />
						</>
					) : (
						<>
							{review ? (
								<>
									<Title level={4}>
										Our team is currently reviewing Your form. We will contact You shortly
									</Title>
									
									<KycNextSteps />
								</>
							) : (
								<>
									{approved ? (
										<>

											<Title level={4}>
												Your KYC is approved and ready.
											</Title>
											{/*<TextMain>
												Your form has been succesfully approved by Bitclear team<br />
												All functionalities of Bitclear FX app has been unlocked for You<br />
												<br />
											</TextMain>*/}
											<TextMain>
												If you have not already done so, download the approved KYC, print it, sign it and send it along with <b>all the uploaded documents</b> by post or courier to the following address:<br />
												<br />
												Bitclear Aktiengesellschaft<br />
												PO Box 534<br />
												Industriestrasse 26<br />
												9491 Ruggell, Liechtenstein<br />
											</TextMain>
											<KycPrint />
										</>

										) : (
										<>
											<Title level={4}>Hi there!</Title>
											<div>
												<TextMain>
													To protect our clients we have implemented a strict "Know Your Client" process. In order to go through the process smoothly, please prepare the below-mentioned documents.
												</TextMain>
											</div>
											<KycDocumentsSteps />
											<KycDocsInfo />
											<Title level={5}>Please be aware that you need to send the original documents to Bitclear AG.</Title>
										</>
									)}
								
								</>
							)}
						</>
					)}
					
				</>
			) : (
				<Spin size='large' />
			)}
			
	      	
		</KycLayout>
			 
	)
}

export default KycIntro
