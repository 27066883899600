import { FC } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import KycUbosListing from '../listings/KycUbosListing'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { authorizedPersonsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonsGet'
import { ubosGet } from '../../../store/kyc-front/asyncThunks/ubosGet'
import { uboCreate } from '../../../store/kyc-front/asyncThunks/uboCreate'
import { uboUpdate } from '../../../store/kyc-front/asyncThunks/uboUpdate'
import { uboCreateClear, uboUpdateClear, uboArchiveClear } from '../../../store/kyc-front/slices/ubosSlice'
import { uboDocArchiveClear } from '../../../store/kyc-front/slices/uboDocsSlice'
import { uboArchive } from '../../../store/kyc-front/asyncThunks/uboArchive'
import { uboDocsGet } from '../../../store/kyc-front/asyncThunks/uboDocsGet'
import { uboDocCreate } from '../../../store/kyc-front/asyncThunks/uboDocCreate'
import { uboDocUrlGet } from '../../../store/kyc-front/asyncThunks/uboDocUrlGet'
import { uboDocArchive } from '../../../store/kyc-front/asyncThunks/uboDocArchive'
import { ubosValidate } from '../../../store/kyc-front/asyncThunks/ubosValidate'
import { ubosValidateClear } from '../../../store/kyc-front/slices/statusSlice'


const KycUbos: FC = () => {
	const company = useSelector((state) => state.user.profile);
	const authorizedPersons = useSelector((state) => state.user.authorizedPersons);
	const ubos = useSelector((state) => state.user.ubos);
	return (
		<KycUbosListing 
			profile={company.userProfile} 
			profileGet={profileGet} 
			authorizedPersons={authorizedPersons} 
			authorizedPersonsGet={authorizedPersonsGet} 
			ubos={ubos} 
			ubosGet={ubosGet} 
			uboCreate={uboCreate} 
			uboUpdate={uboUpdate} 
			uboClearCreate={uboCreateClear} 
			uboClearUpdate={uboUpdateClear}
			uboArchive={uboArchive}
			uboClearArchive={uboArchiveClear}
			uboDocsGet={uboDocsGet}
    		uboDocCreate={uboDocCreate}
    		docUrlGet={uboDocUrlGet}
    		uboDocArchive={uboDocArchive}
			uboDocArchiveClear={uboDocArchiveClear}
			validationAction={ubosValidate} 
           	validationActionClear={ubosValidateClear}
		/>	 
	)
}

export default KycUbos
