import { Spin, Divider, Row, Col } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom'
import { FC, ReactNode} from 'react'
import { themeColor, themeWeight } from '../../../styles/themeStyles'
import { PageContent } from '../../layout/PageContent'
import { ButtonUpper } from './Buttons'
import styled from 'styled-components'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 30px;
`

const SuccessIconStyled = styled.div`
	margin-bottom: 30px;
	font-size: 21px;
	.swal2-icon {
		margin-top: -20px;
		&.swal2-success {
			color: ${themeColor.green};
			border-color: ${themeColor.green};
			[class^=swal2-success-line] {
				&[class$=tip] {
					background-color: ${themeColor.green}
	    		}
	    		&[class$=long] {
    				background-color: ${themeColor.green}
    			}
    		}
    	}
	}
	
`

const MySwal = withReactContent(Swal) //required for success icon styles

interface SProps {
	linkEl: any,
	linkText: any,
	linkAction?: () => void,
	children: ReactNode,
	additionalContent?: ReactNode
}

export const SuccessIcon: FC = () => (
	<SuccessIconStyled>
		<div className="swal2-icon swal2-success swal2-icon-show">
			<div className="swal2-success-circular-line-left" ></div>
		  	<span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
		 	<div className="swal2-success-ring"></div> <div className="swal2-success-fix"></div>
		  	<div className="swal2-success-circular-line-right"></div>
	  	</div>
	</SuccessIconStyled>
)

export const SuccessInfo: FC<SProps> = ({children, linkEl, linkText, linkAction, additionalContent}) => (

			<div>
					<Row justify='center'>
						<CenteredCol>
							<SuccessIcon />
							<p><b>Congratulations!</b></p>
							{children}
						</CenteredCol>
					</Row>
					{additionalContent}
					<Row justify='center' style={{'marginTop': '30px'}}>
						<CenteredCol>
							<Link onClick={linkAction} to={linkEl}><ButtonUpper btnType='default'>{linkText}</ButtonUpper></Link>
						</CenteredCol>
					</Row>
			</div>
)


