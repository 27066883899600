import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Row, Col, Divider } from 'antd'
import { store, dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { companyBusinessProfileGet } from '../../../store/kyc-backend/asyncThunks/companyBusinessProfileGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { SuccessInfo } from '../../kyc-front/ui-elements/SuccessInfo'
import BasicFormWrapper from '../../kyc-front/ui-elements/BasicForm'
import { DividerFirst } from '../../kyc-front/ui-elements/Dividers'
import { themeColor } from '../../../styles/themeStyles'
import styled from 'styled-components'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'


const WorldCheck: FC = () => {
	const envs = useSelector((state) => state.general.envs);
	const isBusiness = envs.accountType === 'BUSINESS';
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if(dataLoaded === false && envs.accountType !== '') {
			if(isBusiness) {
	        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
	        	dispatch(companyBusinessProfileGet({params: {companyId: envs.profileId}}));
	        }
	        if(envs.accountType === 'PRIVATE') {
	        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
	        }
	        setDataLoaded(true);
		}
        
    }, [envs.accountType]);
	return (
		<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'world-check'}}>
			<Row gutter={30}>
				<Col span={24}>
					<Text style={{'fontSize': '38px', 'position': 'relative', 'top' : '-35px', 'fontWeight':'bold'}}>{isBusiness ? 'Company' : 'Individual'}  World Check</Text>
					<Divider style={{marginBottom: 0}} />

				</Col>
			</Row>
		</KycLayout>
			 
	)
}

export default WorldCheck
