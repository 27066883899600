import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { APResponse } from "../slices/authorizedPersonsSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type UpdateAPRequest = Components.Schemas.UpdateAuthorizedPerson;
type PathParameters = Paths.UpdateAuthorizedPerson.PathParameters;

export const authorizedPersonBackUpdate: AsyncThunkActionType<
  APResponse,
  AsyncThunkDataType<PathParameters, UpdateAPRequest, "data">
> = createAsyncThunk(
  "authorizedPersonBackUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.UpdateAuthorizedPerson(params, data, config);
      return response.data as APResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
