import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { uboDocsGet } from "../asyncThunks/uboDocsGet";
import { uboDocUrlGet } from "../asyncThunks/uboDocUrlGet";
import { uboDocCreate } from "../asyncThunks/uboDocCreate";
import { uboDocArchive } from "../asyncThunks/uboDocArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type UboDocsResponse = Components.Schemas.BeneficialOwnerDocumentEntry;
export type UrlResponse = Paths.GetBeneficialOwnerDocument.Responses.$200;
export type ArchiveReponse = Paths.ArchiveBeneficialOwnerDocumentEndpoint.Responses.$200;

type State = {
    uboDocs: UboDocsResponse[];
    uboDocUrl: {id: string, value: UrlResponse}[];
    newUboDoc: UboDocsResponse,
    uboDocArchived: {id: string, value: ArchiveReponse}
};

const initialState: State = {
    newUboDoc: {
        documentId: '',
        companyId: '',
        uboId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    uboDocs: [],
    uboDocUrl: [],
    uboDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    uboDocCreateClear: CaseReducerType<State, object>;
    uboDocsGetClear: CaseReducerType<State, object>;
    uboDocUrlClear: CaseReducerType<State, object>;
    uboDocArchiveClear: CaseReducerType<State, object>;
};

export const uboDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "uboDocs",
    initialState,
    reducers: {
        uboDocCreateClear: (state, { payload }) => {
            state.newUboDoc = initialState.newUboDoc
        },
        uboDocUrlClear: (state, { payload }) => {
            state.uboDocUrl = initialState.uboDocUrl
        },
        uboDocsGetClear: (state, { payload }) => {
            state.uboDocs = initialState.uboDocs
        },
        uboDocArchiveClear: (state, { payload }) => {
            state.uboDocArchived = initialState.uboDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(uboDocsGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocs.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocs.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
        builder.addCase(uboDocCreate.fulfilled, (state, action) => {
            state.newUboDoc = action.payload;
        });
        builder.addCase(uboDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newUboDoc = action?.payload;
        });
        builder.addCase(uboDocArchive.fulfilled, (state, action) => {
            state.uboDocArchived = action.payload;
        });
        builder.addCase(uboDocUrlGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocUrl.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocUrl.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
    }
});

export const { uboDocCreateClear, uboDocUrlClear, uboDocsGetClear, uboDocArchiveClear } = uboDocsSlice.actions;
export default uboDocsSlice.reducer;
