import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FC, useEffect } from 'react'

import { useSelector } from '../hooks/useSelector'
import { dispatch } from '../store/store'

import { setFormUpdated } from '../store/kyc-front/slices/navSlice'

import { individualProfileBackUpdate } from '../store/kyc-backend/asyncThunks/individualProfileBackUpdate'
import { individualUpdate } from '../store/kyc-front/asyncThunks/individualUpdate'

import { companyBackUpdate } from '../store/kyc-backend/asyncThunks/companyBackUpdate'
import { companyUpdate } from '../store/kyc-front/asyncThunks/companyUpdate'

import { authorizedPersonBackUpdate } from '../store/kyc-backend/asyncThunks/authorizedPersonBackUpdate'
import { authorizedPersonUpdate } from '../store/kyc-front/asyncThunks/authorizedPersonUpdate'

import { uboBackUpdate } from '../store/kyc-backend/asyncThunks/uboBackUpdate'
import { uboUpdate } from '../store/kyc-front/asyncThunks/uboUpdate'

import { signatoriesUpdate } from '../store/kyc-front/asyncThunks/signatoriesUpdate'

interface FProps {
    onConfirm: boolean,
    onConfirmLink: string
}

export const FormSaver: FC<FProps> = ({onConfirm, onConfirmLink}) => {
    const nav = useSelector((state) => state.general.nav);
    let {formUpdated, currentEditedForm} = nav;
    const envs = useSelector((state) => state.general.envs);
    let formUpdatedValues: any[] = [];
    let navigate = useNavigate();
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
    useEffect(() => {
        if(onConfirm === true && onConfirmLink !== '') {
            confirm(onConfirmLink);
        }
    }, [onConfirm]);
    const confirm = (link: string) => {
        formUpdated.form.forEach(el => {
            currentEditedForm.forEach(form => {
                if(el === form.id) {
                    formUpdatedValues.push(form)
                }
            })
        })
        let transformBools = true;
        if(formUpdatedValues.length) {
            //@ts-ignore
            formUpdatedValues.forEach(formUpdatedValue => {
                if(formUpdated.updated && !_.isEmpty(formUpdatedValue.values)) {
                    let submitValues = {...formUpdatedValue.values};
                    if(transformBools) {
                        Object.entries(submitValues).forEach(([i, value]) => {
                            if(value === 'true') {
                                // @ts-ignore
                                submitValues[i as keyof typeof submitValues] = true;
                            }
                            if(value === 'false') {
                                // @ts-ignore
                                submitValues[i as keyof typeof submitValues] = false;
                            }
                        });
                    }
                    let dispatchSubmit;
                    let params; 
                    if(formUpdated.formName === 'apForm') {
                        dispatchSubmit = envs.admin ? authorizedPersonBackUpdate : authorizedPersonUpdate
                        params = {companyId: envs.profileId, authorizedPersonId: formUpdatedValue.id}
                    } else if (formUpdated.formName === 'uboForm') {
                        dispatchSubmit = envs.admin ? uboBackUpdate : uboUpdate
                        params = {companyId: envs.profileId, beneficialOwnerId: formUpdatedValue.id}
                    } else {
                        switch(formUpdated.form[0]) {
                            case 'individualInfoForm':
                              //@ts-ignore
                              dispatchSubmit = envs.admin ? individualProfileBackUpdate : individualUpdate
                              params = {individualId: envs.profileId}
                              break
                            case 'dataIndividualValidationForm':
                                dispatchSubmit = envs.admin ? individualProfileBackUpdate : individualUpdate
                                 params = {individualId: envs.profileId}
                                break
                            case 'companyInfoForm':
                                dispatchSubmit = envs.admin ? companyBackUpdate : companyUpdate
                                params = {companyId: envs.profileId}
                                break
                            case 'dataValidationForm': 
                                dispatchSubmit = envs.admin ?  undefined : signatoriesUpdate
                                params = {companyId: envs.profileId}
                                break
                            default:
                                dispatchSubmit = undefined
                                break

                        }
                    }
                    if(dispatchSubmit) {
                        dispatch(
                            //@ts-ignore
                            dispatchSubmit({
                                //@ts-ignore
                                params: params,
                                //@ts-ignore
                                data: submitValues
                            }),
                        ).then(() => navigate(isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + link : envs.routeSuffix + link))
                        
                    } 
                    dispatch(setFormUpdated({updated: false, form: []}));
                  
                }
            })
        }
    }
    return <></>
}