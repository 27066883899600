import React, {FC} from 'react'
import { PrivateRoute } from 'routes/PrivateRoute'
import { UserAdminRoute } from './UserAdminRoute'
import { AdminRoute } from 'routes/AdminRoute'
import { LazyComponent } from 'modules/layout/LazyComponent'
import { useIsUserLogged } from 'modules/keycloak'
import { createBrowserRouter, RouteObject, Outlet, useParams } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import KycKeycloakFront from 'modules/kyc-front/KycKeycloakFront'
import KycKeycloakBack from 'modules/kyc-backend/KycKeycloakBack'
import Login from 'modules/kyc-front/Login'
import KycRegister from 'modules/kyc-front/KycRegister'
import { Navigate } from 'react-router-dom'
import Error403 from 'modules/kyc-front/Error403'
import KycIntro from 'modules/kyc-front/steps/KycIntro'
import KycCompanyInfo from 'modules/kyc-front/steps/KycCompanyInfo'
import KycAdminCompanyInfo from 'modules/kyc-backend/steps/KycCompanyInfo'
import KycIndividualInfo from 'modules/kyc-front/steps/KycIndividualInfo'
import KycAdminIndividualInfo from 'modules/kyc-backend/steps/KycIndividualInfo'
import KycAuthorizedPersons from 'modules/kyc-front/steps/KycAuthorizedPersons'
import KycAdminAuthorizedPersons from 'modules/kyc-backend/steps/KycAuthorizedPersons'
import KycUbos from 'modules/kyc-front/steps/KycUbos'
import KycAdminUbos from 'modules/kyc-backend/steps/KycUbos'
import KycIndividualDeclarations from 'modules/kyc-front/steps/KycIndividualDeclarations'
import KycAdminIndividualDeclarations from 'modules/kyc-backend/steps/KycIndividualDeclarations'
import KycDataValidation from 'modules/kyc-front/steps/KycDataValidation'
import KycAdminDataValidation from 'modules/kyc-backend/steps/KycDataValidation'
import KycInbox from 'modules/kyc-front/steps/KycInbox'
import ClientListing from 'modules/kyc-backend/views/ClientListing'
import ClientArchivedListing from 'modules/kyc-backend/views/ClientArchivedListing'
import AdminMessages from 'modules/kyc-backend/views/AdminMessages'
import BusinessRelation from 'modules/kyc-backend/views/BusinessRelation'
import BusinessProfile from 'modules/kyc-backend/views/BusinessProfile'
import RiskProfile from 'modules/kyc-backend/views/RiskProfile'
import WorldCheck from 'modules/kyc-backend/views/WorldCheck'
import Transactions from 'modules/kyc-backend/views/Transactions'
import KycAdminSummary from 'modules/kyc-backend/steps/KycAdminSummary'

export const PATH = {
	LOGIN: '/user/login',
	ADMIN_LOGIN: '/admin/login',
	REGISTER: '/user/register'
}
export const BASE_PATH = `/user`
export const ADMIN_BASE_PATH = `/admin`

interface UProps {
	companyComponent: any,
	individualComponent?: any,
	isAdmin?: boolean
}

interface LProps {
	component: any
}


const LoggedOutRoute: FC<LProps> = ({ component }) => {
	const { isUserLogged } = useIsUserLogged()
    return isUserLogged ? <Navigate to="/BASE_PATH" /> : component;
}

const UserTypeRoute: FC<UProps> = ({ companyComponent, individualComponent, isAdmin }) => {
    const envs = useSelector((state) => state.general.envs);
    let isBusiness = envs.accountType === 'BUSINESS';
    let isPrivate = envs.accountType === 'PRIVATE';
    return isBusiness ? companyComponent : individualComponent ? individualComponent : <Navigate to={isAdmin ? ADMIN_BASE_PATH : BASE_PATH} />;
}
const RedirectToSummary: FC = () => {
	let { id } = useParams();
	return <Navigate to={`${ADMIN_BASE_PATH}/${id}/summary`} />;
};

export const appRoutes = createBrowserRouter(
	[
		{
			path: PATH.LOGIN + '/:redirect?',
			element: <Login isBackend={false} />,
			index: true,
		},
		{
			path: PATH.ADMIN_LOGIN + '/:redirect?',
			element: <Login isBackend={true} />,
			index: true,
		},
		{
			path: PATH.REGISTER,
			element: <LoggedOutRoute component={<KycRegister />} />
		},
		{
			path: '/403',
			element: <Error403 />
		},
		{
			path: BASE_PATH,
			element: <KycKeycloakFront />,
			children: [
				{
					path: BASE_PATH,
					element: <Navigate to={BASE_PATH + "/summary"} />,
				},
				{
					path: BASE_PATH + '/summary',
					element: <PrivateRoute><KycIntro /></PrivateRoute>,
				},
				{
					path: BASE_PATH + '/step1',
					element: <UserTypeRoute companyComponent={<PrivateRoute><KycCompanyInfo /></PrivateRoute>} individualComponent={<PrivateRoute><KycIndividualInfo /></PrivateRoute>} />
				},
				{
					path: BASE_PATH + '/step2',
					element: <UserTypeRoute companyComponent={<PrivateRoute><KycAuthorizedPersons /></PrivateRoute>} individualComponent={<PrivateRoute><KycIndividualDeclarations /></PrivateRoute>} />
				},
				{
					path: BASE_PATH + '/step3',
					element: <UserTypeRoute companyComponent={<PrivateRoute><KycUbos /></PrivateRoute>} />
				},
				{
					path: BASE_PATH + '/step4',
					element: <UserTypeRoute companyComponent={<PrivateRoute><KycDataValidation /></PrivateRoute>} />
				},
				{
					path: BASE_PATH + '/inbox',
					element: <PrivateRoute><KycInbox /></PrivateRoute>
				}
			],
		},
		{
			path: ADMIN_BASE_PATH,
			element: <KycKeycloakBack />,
			children: [
				{
					path: ADMIN_BASE_PATH,
					element: <PrivateRoute><AdminRoute><ClientListing /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/archived',
					element: <PrivateRoute><AdminRoute><ClientArchivedListing /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/messages',
					element: <PrivateRoute><AdminRoute><AdminMessages /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/relation',
					element: <PrivateRoute><UserAdminRoute><BusinessRelation /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/business-profile',
					element: <PrivateRoute><UserAdminRoute><BusinessProfile /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/business-profile/*',
					element: <PrivateRoute><UserAdminRoute><BusinessProfile isArchived={true} /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/risk-profile',
					element: <PrivateRoute><UserAdminRoute><RiskProfile /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/risk-profile/*',
					element: <PrivateRoute><UserAdminRoute><RiskProfile isArchived={true} /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/world-check',
					element: <PrivateRoute><UserAdminRoute><WorldCheck /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/transactions',
					element: <PrivateRoute><UserAdminRoute><Transactions /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/summary',
					element: <PrivateRoute><UserAdminRoute><KycAdminSummary /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/step1',
					element: <UserTypeRoute isAdmin={true} companyComponent={<PrivateRoute><UserAdminRoute><KycAdminCompanyInfo /></UserAdminRoute></PrivateRoute>} individualComponent={<PrivateRoute><UserAdminRoute><KycAdminIndividualInfo /></UserAdminRoute></PrivateRoute>} />
				},
				{
					path: ADMIN_BASE_PATH + '/:id/step2',
					element: <UserTypeRoute isAdmin={true} companyComponent={<PrivateRoute><UserAdminRoute><KycAdminAuthorizedPersons /></UserAdminRoute></PrivateRoute>} individualComponent={<PrivateRoute><UserAdminRoute><KycAdminIndividualDeclarations /></UserAdminRoute></PrivateRoute>} />
				},
				{
					path: ADMIN_BASE_PATH + '/:id/step3',
					element: <UserTypeRoute isAdmin={true} companyComponent={<PrivateRoute><UserAdminRoute><KycAdminUbos /></UserAdminRoute></PrivateRoute>} />
				},
				{
					path: ADMIN_BASE_PATH + '/:id/step4',
					element: <UserTypeRoute isAdmin={true} companyComponent={<PrivateRoute><UserAdminRoute><KycAdminDataValidation /></UserAdminRoute></PrivateRoute>} />
				},
				{
					path: ADMIN_BASE_PATH + '/:id/*',
					element: <RedirectToSummary />
				}
			],
		},
		{
			path: '*',
			element: <Navigate to={BASE_PATH} />,
		},
	],
	{
		basename: '/',
		future: { v7_normalizeFormMethod: true },
	},
)





