import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type UbosResponse = Components.Schemas.BeneficialOwnerProfile;
type PathParameters = Paths.BeneficialOwners.PathParameters;

export const ubosBackGet: AsyncThunkActionType<
  UbosResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("ubosBackGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.BeneficialOwners(data.params, null, data?.config);
    return response.data as UbosResponse[];
    //return response
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
