export const camelCaseToName = (elem: any | undefined) => {
    if(elem) {
        if(elem === 'estimatedAmountOfEUR') {
            return 'Estimated Amount Of EUR'
        } 
        if(elem === 'annualTurnoverEUR') {
            return 'Annual Turnover EUR'
        }
        if(elem === 'IDCardFront') {
            return 'ID Card Front'
        }
        if(elem === 'IDCardBack') {
            return 'ID Card Back'
        }
        if(elem === 'PassportFront') {
            return 'Passport'
        }
        if(elem === 'ProofOfAddress') {
            return 'Proof of address'
        }
        if(elem === 'OtherDocumentKind') {
            return 'Other document'
        }
        let result = elem?.replace(/([A-Z])/g, " $1")
        return result?.charAt(0).toUpperCase() + result?.slice(1)
    }
    
}

export const uppercaseFirstLetter = (elem: any | undefined) => {
    return elem?.charAt(0).toUpperCase() + elem?.slice(1)
}

export const nameParse = (name:string) => {
    let paramName = '';
    switch(name) {
        case 'missing-back-of-the-id-card':
            paramName = 'Back of ID card'
            break
        case 'missing-front end back-of-id-card':
            paramName = 'Back and front of ID card'
            break
        case 'missing-passport-document':
            paramName = 'Passport upload'
            break
        case 'proof-of-address':
            paramName = 'Proof Of address'
            break
        case 'estimatedAmountOfEUR':
            paramName = 'Estimated Amount Of EUR'
            break
        case 'estimatedAmountOfBTC':
            paramName = 'Estimated Amount Of BTC'
            break
        case 'annualTurnoverEUR':
            paramName = 'Annual Turnover in EUR'
            break
        case 'isUSNationality':
            paramName = 'Is US Nationality'
            break
        case 'IDCard':
            paramName = 'ID Card'
            break
        default:
            paramName = camelCaseToName(name);
    }
    return paramName;
}

export const extractName = (name: string) => {
    let names = name?.split('_');
    let nameEl = '';
    if(names) {
        if(names.length == 1) {
            nameEl = names[0]
        } else {
            if(names[0] === 'identityDocument') {
                nameEl = 'id ' + names[1]
            } else {
               nameEl = names[1] 
            }
        }
        return nameEl
    } else {
        return name
    }
    
}