import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { uboCreate } from "../asyncThunks/uboCreate";
import { uboUpdate } from "../asyncThunks/uboUpdate";
import { ubosGet } from "../asyncThunks/ubosGet";
import { uboArchive } from '../asyncThunks/uboArchive';
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

import { reduce } from "lodash";

export type UboResponse = Components.Schemas.BeneficialOwnerProfile;
export type ArchiveReponse = Paths.ArchiveBeneficialOwner.Responses.$200;

type State = {
  newUbo: UboResponse;
  updatedUbo: UboResponse;
  ubosList: UboResponse[];
  archivedUbo: ArchiveReponse;
};

const emptyUbo = {
    beneficialOwnerId: '',
    sourceOfWealthBusinessActivities: false,
    sourceOfWealthProfessionalOccupation: false,
    sourceOfWealthInheritance: false,
    sourceOfWealthOther: false,
    isPoliticallyExposedPerson: false,
    isUSNationality: false,
    createdAt: ''
}

const initialState: State = {
  newUbo: emptyUbo,
  updatedUbo: emptyUbo,
  ubosList: [],
  archivedUbo: false
};

type CaseReducers<State> = {
  uboCreateClear: CaseReducerType<State, object>;
  uboUpdateClear: CaseReducerType<State, object>;
  uboArchiveClear: CaseReducerType<State, object>;
};

export const ubosSlice = createSlice<State, CaseReducers<State>>({
  name: "ubos",
  initialState,
  reducers: {
    uboCreateClear: (state, { payload }) => {
      state.newUbo = initialState.newUbo
    },
    uboUpdateClear: (state, { payload }) => {
      state.updatedUbo = initialState.updatedUbo
    },
    uboArchiveClear: (state, { payload }) => {
      state.archivedUbo = initialState.archivedUbo
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uboCreate.fulfilled, (state, action) => {
      state.newUbo = action.payload;
    });
    builder.addCase(uboUpdate.fulfilled, (state, action) => {
      state.updatedUbo = action.payload;
    });
    builder.addCase(ubosGet.fulfilled, (state, action) => {
      state.ubosList = action.payload;
    });
    builder.addCase(uboArchive.fulfilled, (state, action) => {
      state.archivedUbo = action.payload;
    });
  }
});

export const { uboCreateClear, uboUpdateClear, uboArchiveClear } = ubosSlice.actions;
export default ubosSlice.reducer;
