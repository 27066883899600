import React, { FC, useEffect, useState } from 'react'
import { Divider, Space, Row, Col, Select, Table, Typography, Tag,Popconfirm, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { companyBusinessProfileSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileSnapshotsGet'
import { individualBusinessProfileSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileSnapshotsGet'
import { companyBusinessProfileGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileGet'
import { individualBusinessProfileGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileGet'
import { companyBusinessProfileArchive } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileArchive'
import { individualBusinessProfileArchive } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileArchive'
import { 
    individualBusinessProfileArchiveClear,
    companyBusinessProfileArchiveClear
} from '../../../../store/kyc-backend/slices/businessProfileSlice'
import TableElement from '../../../kyc-front/ui-elements/TableElement'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { themeColor } from '../../../../styles/themeStyles'

interface CProps {
    handleHistoryShow: any
}


const BusinessProfileHistoryTable: FC<CProps> = ({handleHistoryShow}) => {
    const envs = useSelector((state) => state.general.envs);
    const businessProfile = useSelector((state) => state.admin.businessProfile);
    const isBusiness = envs.accountType === 'BUSINESS';
    const [deleting, setDeleting] = useState(false);
    const snapshotsHistory = isBusiness ? businessProfile.businessProfileCompanySpanshots : businessProfile.businessProfileIndividualSpanshots;
    const { Text } = Typography;
    const navigate = useNavigate();
    const pathParams = useParams();
    const [popupVisible, setPopupVisible] = useState('');
    const profileGettingStatus = useActionStatus(isBusiness ? companyBusinessProfileGet : individualBusinessProfileGet);
    //@ts-ignore
    const approvedExists = snapshotsHistory.find((snap) => snap.isLatestApproved);
    useEffect(() => {
        if(isBusiness) {
            dispatch(companyBusinessProfileSnapshotsGet({ params: { companyId: envs.profileId} }));
            if(!profileGettingStatus.isFullfilled) dispatch(companyBusinessProfileGet({params: {companyId: envs.profileId}}));
        } else {
            dispatch(individualBusinessProfileSnapshotsGet({ params: { individualId: envs.profileId} }));
            if(!profileGettingStatus.isFullfilled) dispatch(individualBusinessProfileGet({params: {individualId: envs.profileId}}))
        }
    }, []);
    useEffect(() => {
        if(deleting) {
            if(businessProfile.businessProfileCompanyArchived) {
                message.success('This element was sucessfully deleted');
                dispatch(companyBusinessProfileSnapshotsGet({ params: { companyId: envs.profileId} }));
                setDeleting(false);
                
            }
        }
    }, [businessProfile.businessProfileCompanyArchived]);
     useEffect(() => {
        if(deleting) {
            if(businessProfile.businessProfileIndividualArchived) {
                message.success('This element was sucessfully deleted');
                dispatch(individualBusinessProfileSnapshotsGet({ params: { individualId: envs.profileId} }));
                setDeleting(false);
                
            }
        }
    }, [businessProfile.businessProfileIndividualArchived]);
	return (
		<div>
                <br />
                {snapshotsHistory.length ? (
                    <TableElement
                        sortersListProps={[]}
                        sortersState={{}}
                    >
                        {({onTableChange}) => {
                            const columns = (showHistory: any) => ([
                              {
                                title: 'Created at',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                render: (created: string) => <span>{moment(created).format('YYYY-MM-DD HH:mm:ss')}</span>,
                              },
                              {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                render: (status: any) => <Tag color={status === 'Approved' ? themeColor.green : status === 'Drafted' ? themeColor.blue : status === 'Archived approved' ? themeColor.greenGray : status === 'Archived draft' ? themeColor.blueGray : themeColor.gray}>{status}</Tag>,
                              },
                              {
                                title: 'Version',
                                dataIndex: 'dataVersion',
                                key: 'dataVersion',
                                render: (version: any) => <span>{version}</span>,
                              },
                              {
                                title: 'Actions',
                                key: 'action',
                                render: (el: any) => (
                                    <Space size="middle">
                                        <ButtonUpper className='btn-sm' btnType='primary' onClick={() => showHistory(el)} disabled={el.current ? true : false}>{el.status === 'Drafted' ? el.current ? 'Editing' : 'Edit' : el.current ? 'Previewing' : 'Preview'}</ButtonUpper>
                                        <Popconfirm
                                            title="Are You sure you want to delete this Business Profile?"
                                            visible={popupVisible === el.id}
                                            onVisibleChange={() => handleConfirmVisibleChange(el.id)}
                                            onConfirm={() => deleteSnapshot(el)}
                                            onCancel={cancelConfirm}
                                            okText="Yes, delete"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper className='btn-sm' btnType='primary' ghost disabled={el.current ? true : el.isLatestApproved ? true : false}>Delete</ButtonUpper>
                                        </Popconfirm>

                                    </Space>
                                ),
                              },
                            ]);
                            const handleConfirmVisibleChange = (id: any) => {
                                setPopupVisible(id);
                            }
                            const cancelConfirm = () => {
                                setPopupVisible('');
                            }
                            const showHistory = (el: any) => {
                                handleHistoryShow(snapshotsHistory.find(snap => snap.snapshotId === el.id));
                                if(el.isApproved || (el.isEditable && approvedExists === undefined)) {
                                    navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile');
                                } else {
                                    navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile/' + el.id);
                                }
                                
                            }
                            const deleteSnapshot = (el: any) => {
                                setPopupVisible('');
                                if(isBusiness) {
                                    dispatch(companyBusinessProfileArchive({params: {companyId: envs.profileId, businessProfileSnapshotId: el.id}, data: null}))
                                } else {
                                    dispatch(individualBusinessProfileArchive({params: {individualId: envs.profileId, businessProfileSnapshotId: el.id}, data: null}))
                                }
                                setDeleting(true);
                            }
                            return (
                                <Table
                                    onChange={onTableChange} 
                                    columns={columns(showHistory)}
                                    dataSource={snapshotsHistory.map((profile, i) => {
                                        //@ts-ignore
                                        return {
                                            createdAt: profile.createdAt, 
                                            //@ts-ignore
                                            dataVersion: profile.dataVersion.BusinessProfileV1 ? 'V1' : '', 
                                            id: profile.snapshotId, 
                                            //@ts-ignore
                                            isApproved: profile.isLatestApproved, 
                                            //@ts-ignore
                                            isEditable: profile.isEditable,
                                             //@ts-ignore
                                            current: profile.snapshotId === pathParams[1] || pathParams[1] === undefined && profile.isLatestApproved || (pathParams[1] === undefined && profile.isEditable && approvedExists === undefined),
                                            //@ts-ignore
                                            status: profile.isLatestApproved ? 'Approved' : profile.isEditable ? 'Drafted' : profile.isApproved ? 'Archived approved' : 'Archived draft'
                                        }
                                    })}
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
        </div>
	)
};

export default BusinessProfileHistoryTable;