import { Spin } from 'antd'
import { FC, useEffect } from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { individualProfileBackUpdate } from '../../../store/kyc-backend/asyncThunks/individualProfileBackUpdate'
import { individualProfileBackUpdateClear } from '../../../store/kyc-backend/slices/individualSlice'
import { individualBackDocsGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocsGet'
import { individualBackDocUrlGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocUrlGet'
import { individualBackDocCreate } from '../../../store/kyc-backend/asyncThunks/individualBackDocCreate'
import { individualBackDocArchiveClear } from '../../../store/kyc-backend/slices/individualDocsSlice'
import { individualBackDocArchive } from '../../../store/kyc-backend/asyncThunks/individualBackDocArchive'
import { individualBackValidate } from '../../../store/kyc-backend/asyncThunks/individualBackValidate'
import { individualBackValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import KycIndividualInfoForm from '../../kyc-front/forms/KycIndividualInfoForm';

const KycIndividualInfo: FC = () => {	
	const individual = useSelector((state) => state.admin.backIndividual);
	const envs = useSelector((state) => state.general.envs);
	useEffect(() => {
		dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
	}, [])
	return (
      	<KycLayout pageName='Step 1' heading='Your personal details' nav={{step: 1, next: "/step2", prev: envs.type === 'standalone' ? '/summary' : "/", navSubpage: 'kyc'}} validationAction={individualBackValidate} validationActionParams={{individualId: envs.profileId}} adminView={true}>
      		{individual.individual.individualId !== '' ? (
				<KycIndividualInfoForm 
					individual={individual} 
					individualGet={individualProfileBackGet} 
					individualUpdate={individualProfileBackUpdate} 
					individualClearUpdate={individualProfileBackUpdateClear} 
					individualDocsGet={individualBackDocsGet}
					individualDocCreate={individualBackDocCreate}
    				docUrlGet={individualBackDocUrlGet}
    				individualDocArchive={individualBackDocArchive}
    				individualDocArchiveClear={individualBackDocArchiveClear}
    				validationAction={individualBackValidate} 
            		validationActionClear={individualBackValidateClear}
				/>
			) : (
				<Spin size="large" />
			)}
		</KycLayout>
			 
	)
}

export default KycIndividualInfo
