import { FC, useEffect, useState } from 'react'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { authorizedPersonsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackGet'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { authorizedPersonDocsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocsBackGet'
import { authorizedPersonDocBackCreate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocBackCreate'
import { authorizedPersonDocBackArchive } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocBackArchive'
import { authorizedPersonDocUrlBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocUrlBackGet'
import { authorizedPersonBackCreate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonBackCreate'
import { authorizedPersonBackArchive } from '../../../store/kyc-backend/asyncThunks/authorizedPersonBackArchive'
import { authorizedPersonBackUpdate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonBackUpdate'
import { authorizedPersonBackCreateClear, authorizedPersonBackUpdateClear, authorizedPersonBackArchiveClear } from '../../../store/kyc-backend/slices/authorizedPersonsSlice'
import { authorizedPersonDocBackCreateClear, authorizedPersonDocBackArchiveClear } from '../../../store/kyc-backend/slices/authorizedPersonDocsSlice'
import { authorizedPersonsBackValidate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackValidate'
import { authorizedPersonsBackValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import { useSelector } from '../../../hooks/useSelector'
import KycAuthorizedPersonsListing from '../../kyc-front/listings/KycAuthorizedPersonsListing'

const KycAuthorizedPersons: FC = () => {
	const company = useSelector((state) => state.admin.backCompany);
	const authorizedPersons = useSelector((state) => state.admin.backAuthorizedPersons);
	return (
      <KycAuthorizedPersonsListing 
      	profile={company.profile} 
      	profileGet={companyProfileBackGet} 
      	docUrlGet={authorizedPersonDocUrlBackGet}
      	authorizedPersons={authorizedPersons} 
      	authorizedPersonsGet={authorizedPersonsBackGet} 
      	authorizedPersonCreate={authorizedPersonBackCreate} 
      	authorizedPersonClearCreate={authorizedPersonBackCreateClear} 
      	authorizedPersonClearUpdate={authorizedPersonBackUpdateClear}
      	authorizedPersonUpdate={authorizedPersonBackUpdate} 
      	authorizedPersonDocCreate={authorizedPersonDocBackCreate}
		authorizedPersonDocCreateClear={authorizedPersonDocBackCreateClear}
      	authorizedPersonDocsGet={authorizedPersonDocsBackGet} 
      	authorizedPersonDocArchiveClear={authorizedPersonDocBackArchiveClear}
      	authorizedPersonDocArchive={authorizedPersonDocBackArchive}
      	authorizedPersonArchive={authorizedPersonBackArchive}
      	authorizedPersonClearArchive={authorizedPersonBackArchiveClear}
      	validationAction={authorizedPersonsBackValidate} 
        validationActionClear={authorizedPersonsBackValidateClear}
    />
	)
}

export default KycAuthorizedPersons
