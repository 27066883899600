import { FC } from 'react'
import styled from 'styled-components'

const Print = styled.div`
	font-size: 11px!important;
	bottom: 100%;
	position: fixed;
	label, .ant-col {
		font-size: 11px!important;
	}
	.ant-space {
		gap: 6px!important;
	}
	.ant-space-item {
		margin-bottom: 0!important;
	}
	h2.ant-typography {
		font-size: 18px;
	}
	h3.ant-typography {
		font-size: 16px;
	}
	h4.ant-typography {
		font-size: 14px;
		margin-top: 2px;
    	margin-bottom: 5px;
		&.title4 {
			margin-top: 8px;
			margin-bottom: 6px;
		}
	}
	.ant-form-item, .ant-input, .ant-form-item-label {
		font-size: 11px!important;
	}
	.ant-space-item .ant-typography b {
		font-size: 11px!important;
	}
	.checkbox-wrapper label, .netWorthPrintIndividual label {
		font-size: 11px!important;
	}
	.sowIndividualPrint .ant-col, .netWorthPrintIndividual .ant-col {
		font-size: 11px!important;
	}
	.ant-form-item-label {
		&.checkbox-space {
	        margin-top: 0;
	    }
	}
	.ant-input, .ant-picker {
		height: 32px;
		border-color: #c4c4c4;
	}
	h3.ant-typography , h4.ant-typography {
		font-weight: bold
	}
	button {
		display: none!important;
	}
	.form-nav {
		display: none!important;
	}
	.hide-print {
		display: none!important;
	}
	.ant-divider {
		border-width: 1px;
		border-color: #f0f0f0;
		margin-top: 0;
		margin-bottom: 5px;
		&:first-child, &:last-child {
			display: none;
		}
		//display: none;
		&.divider-first {
			margin-top: 0;
		}
	}

	.ant-input {
		padding-top: 0;
		padding-bottom: 0;
		background-color: #ffffff;
		line-height: 35px!important;
		display: none;
	}
	.ant-select, .ant-picker.ant-picker-disabled, .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: #ffffff!important;
	}
	.ant-select {
		font-size: 11px;
		.ant-select-selector {
			min-height: 32px;
			border-color: #c4c4c4;
			.ant-select-selection-search-input {
				height: 36px;
			}
			.ant-select-selection-item {
				line-height: 30px;
			}
		}
		.ant-select-arrow {
			display: none!important;
		}
	}
	.ant-select-multiple {
		.ant-select-selector {
			height: 32px;
			.ant-select-selection-item {
				line-height: 18px;
				color: #000;
			}
		}
	}
	.ant-select-selection-overflow {
		top: -5px;
	}
	.ant-picker, .ant-picker-input > input{
		font-size: 11px;
		height: 32px;
		padding-top: 4px;
		line-height: 28px;
	}
	.ant-picker-suffix {
		display: none;
	}
	.well {
		background-color: transparent;
		padding: 5px 0;
		margin: 0;
	}
	.ant-input.input-textarea {
    	display: none;
	}
	.ant-upload, .hide-print, .dragger-header, .dragger-body {
		display: none!important;
	}
	.print-wrapper {
		display: block;
	}
	.empty {
		display: none;
	}
	.page-break {
		page-break-before: always!important;
		//margin-bottom: -15px;
	}
	.page-break-ubo {
		margin-bottom: 210px;
		page-break-before: always!important;
	}
	.person-type {
		font-size: 13px;
    	font-weight: normal;
    	margin-left: 5px;
	}
	.anticon-question-circle, .anticon-close {
		display: none;
	}
	.marginUboHeaderPrint {
		margin-top: 95px!important;
		margin-bottom: 0!important;
	}
	.marginIndividualDeclarationPrint {
		margin-top: 40px!important;
	}
	.sowDescriptionPrint {
		margin-top: -5px;
	}
	.sowDescriptionIndividualPrint {
		margin-top: -4px;
	}
	.netWorthPrint {
		margin-top: 10px!important;
	}
	.netWorthPrintIndividual {
		padding-bottom: 4px;
	}
	.marginUboPrint {
		//margin-bottom: 30px!important; //dont change
	}
	.print10 {
		margin-top: 10px;
	}
	.print0 {
		margin-top: 0;
	}
	.print20 {
		margin-top: 20px;
		margin-bottom: 35px;
	}
	.non-print10 {
		margin-top: 0;
	}
	.next-steps {
		display: none;
	}
	#dataValidationForm .ant-divider, #dataIndividualValidationForm .ant-divider {
		display: none;
	}
	#documentDates {
		margin-bottom: -8px;
	}
	#nationalityDocument {
		margin-top: 10px; 
	}
	.companyMargin {
		//height: 200px;
	}
	.companyMarginDescription {
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.companyAddressMarginDescription {
		margin-top: -5px;
		margin-bottom: 0;
	}
	.companySubLabelMargin1 {
		margin-top: 0;
	}
	.companySubLabelMargin2 {
		margin-top: -8px;
	}
	.checkboxCompanyAddress {
		padding-bottom: 0;
	}
	.textarea-small-print {
		.textarea {
			min-height: 290px;
		}
	}
	&.private .checkContainer label {
		margin-bottom: 10px;
	}
	.ant-select-selection-item {
		font-size: 11px;
	}
	#fundingOfBusinessDescriptionTextarea {
		margin-top: 5px;
	}
	.ant-select-multiple .ant-select-selection-item {
		border: 0!important;
    	background: transparent!important;
    	padding: 2px 0 0!important;
    	margin-right: 0;
    	.ant-select-selection-item-content:after {
    		content: ","!important;
    		display: inline-block!important;
    	}
    }
    .ant-select-selection-overflow-item {
    	&:nth-last-child(2) {
    		.ant-select-selection-item-content:after {
    			content: ""!important;
    		}
    	}
	}
	@media not all and (min-resolution:.001dpcm) //target safari
	{ @supports (-webkit-appearance:none) { 
		.page-break {
			margin-bottom: -10px;
		}
		.marginUboPrint {
			//margin-bottom: 406px!important;
		}
		.marginUboHeaderPrint {
			margin-top: 49px!important;
		}
		.print20 {
			margin-bottom: 45px;
		}
		.marginIndividualDeclarationPrint {
			margin-top: -4px!important;
		}
		.companyAddressMarginDescription {
			margin-top: -5px;
			margin-bottom: 0;
		}
	}}

`


export default Print
