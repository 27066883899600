import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale/en_GB'
import { antdTheme } from 'styles/antdTheme'
import { GlobalStyles } from 'styles/GlobalStyles'
import App from './App';
import './styles/font/poppins.css'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store/store'
import { persistor as backPersistor, store as backStore } from './store/store'
import { ThemeProvider } from 'styled-components'
import 'utilities/overrideObjectToString'
import 'antd/dist/reset.css';

ReactDOM.render(
	<StrictMode>
		<Provider store={process.env.REACT_APP_KYC === 'admin' ? backStore : store}>
			<PersistGate persistor={process.env.REACT_APP_KYC === 'admin' ? backPersistor : persistor} loading={<></>}>
				<ThemeProvider theme={{}}>
					<ConfigProvider locale={en} theme={antdTheme}>
						<GlobalStyles />
						
						<App />
					</ConfigProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</StrictMode>,
	document.getElementById('root'),
)
