import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { companyDocsGet } from "../asyncThunks/companyDocsGet";
import { companyDocUrlGet } from "../asyncThunks/companyDocUrlGet";
import { companyDocCreate } from "../asyncThunks/companyDocCreate";
import { companyDocArchive } from "../asyncThunks/companyDocArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type CompanyDocsResponse = Components.Schemas.CompanyDocument;
export type UrlResponse = Paths.GetCompanyDocument.Responses.$200;
export type ArchiveReponse = Paths.ArchiveCompanyDocument.Responses.$200;

type State = {
    companyDocs: CompanyDocsResponse[];
    companyDocUrl: {id: string, value: UrlResponse}[];
    newCompanyDoc: CompanyDocsResponse;
    companyDocArchived: {id: string, value: ArchiveReponse}
};

const initialState: State = {
    newCompanyDoc: {
        documentId: '',
        companyId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    companyDocs: [],
    companyDocUrl: [],
    companyDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    companyDocCreateClear: CaseReducerType<State, object>;
    companyDocsGetClear: CaseReducerType<State, object>;
    companyDocUrlGetClear: CaseReducerType<State, object>;
    companyDocArchiveClear: CaseReducerType<State, object>;
};

export const companyDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "companyDocs",
    initialState,
    reducers: {
        companyDocCreateClear: (state, { payload }) => {
          state.newCompanyDoc = initialState.newCompanyDoc
        },
        companyDocUrlGetClear: (state, { payload }) => {
          state.companyDocUrl = initialState.companyDocUrl
        },
        companyDocsGetClear: (state, { payload }) => {
          state.companyDocs = initialState.companyDocs
        },
        companyDocArchiveClear: (state, { payload }) => {
          state.companyDocArchived = initialState.companyDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(companyDocsGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.companyDocs.findIndex((el) => el.id == action.meta.arg.params.companyId);
            if (elInd > -1) {
                //@ts-ignore
                state.companyDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.companyDocs.push({id: action.meta.arg.params.companyId, value: action.payload})
            }
        });
        builder.addCase(companyDocCreate.fulfilled, (state, action) => {
            state.newCompanyDoc = action.payload;
        });
        builder.addCase(companyDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newCompanyDoc = action?.payload;
        });
        builder.addCase(companyDocArchive.fulfilled, (state, action) => {
            state.companyDocArchived = action.payload;
        });
        builder.addCase(companyDocUrlGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.companyDocUrl.findIndex((el) => el.id == action.meta.arg.params.companyId);
            if (elInd > -1) {
                //@ts-ignore
                state.companyDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.companyDocUrl.push({id: action.meta.arg.params.companyId, value: action.payload})
            }
        });
    }
});

export const { companyDocCreateClear, companyDocUrlGetClear, companyDocsGetClear, companyDocArchiveClear } = companyDocsSlice.actions;
export default companyDocsSlice.reducer;
