import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type CreateIndividualDocsRequest = Components.Schemas.IndividualDocumentForm;
type resp = Components.Schemas.IndividualDocumentEntry;

export const individualBackDocCreate: AsyncThunkActionType<
  resp,
  AsyncThunkDataType<null, CreateIndividualDocsRequest, "data">
> = createAsyncThunk(
    "individualBackDocCreate",
    async ({ params, data, config }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await api.UploadIndividualDocument(params, data, config);
            return response.data as resp;
        } catch (err: any) {
            return rejectWithValue({
                error: true,
                status: err?.response.status, 
                data: err?.response.data,
                ...config
            });
        }
    }
);
