import { createSlice } from "@reduxjs/toolkit"
import { uniqBy } from "lodash"
import { CaseReducerType } from "../../store"
import { Components } from '../../../api/kyc/generated/client'

type ReviewStatusType = Components.Schemas.ReviewStatusType;

type State = {
    route?: string,
    formName?: string,
    error?: {
      message: string,
      slug: string,
      ref: string
    }
};

const initialState: State = {};

type CaseReducers<State> = {
  redirectToError: CaseReducerType<State, State>;
};

export const errorsSlice = createSlice<State, CaseReducers<State>>({
  name: "errorsState",
  initialState,
  reducers: {
    redirectToError: (state, { payload }) => {
      state.error = payload.error
      state.formName = payload.formName
      state.route = payload.route
    }
  },
});

export const { redirectToError } = errorsSlice.actions;

export default errorsSlice.reducer;
