import Title from 'antd/es/typography/Title'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import Text from 'antd/es/typography/Text'
import { Row, Col, Statistic, Divider } from 'antd'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualBackDocsGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocsGet'
import { companyAllDocumentsGet } from '../../../store/kyc-backend/asyncThunks/companyAllDocumentsGet'
import { companyAllDocumentsGetClear } from '../../../store/kyc-backend/slices/companySlice'
import { individualBackDocsGetClear } from '../../../store/kyc-backend/slices/individualDocsSlice'
import { authorizedPersonsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackGet'
import { ubosBackGet } from '../../../store/kyc-backend/asyncThunks/ubosBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import FormUpload from '../../kyc-front/ui-elements/FormUpload'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName } from '../../../utilities/nameParsers'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'

const SubText = styled(Text)`
	font-size: 14px;
`
const DividerCustom = styled(Divider)`
	margin: 0;
`
const StatisticCustom = styled(Statistic)`
	margin-top: 25px;
	.ant-statistic-title {
		color: ${themeColor.grayDark};
	}
	.ant-statistic-content-value {
		font-size: 16px;
	}
`
const Summary = styled.p`
	span {
		width: 120px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
		}
	}
`


const KycIntro: FC = () => {
	const profile = useSelector((state) => state.admin.backCompany);
	const individual = useSelector((state) => state.admin.backIndividual);
	const individualDocs = useSelector((state) => state.admin.backIndividualDocs);
	const envs = useSelector((state) => state.general.envs)
	const authorizedPersons = useSelector((state) => state.admin.backAuthorizedPersons)
	const ubos = useSelector((state) => state.admin.backUbos)
	const [detailsLoading, setDetailsLoading] = useState(false);
	//@ts-ignore
  	const profileDetails = profile?.profile;
  	const profileDetailsIndividual = individual?.individual
  	//@ts-ignore
  	const status = envs.accountType === 'BUSINESS' ? profileDetails?.profile?.reviewStatus : profileDetailsIndividual?.reviewStatus;
  	const isPrivate = envs.accountType === 'PRIVATE';
  	const profileArchived = profileDetails.profile.archivedAt || profileDetailsIndividual?.archivedAt ? true : false;
  	useEffect(() => {
  		if(envs.accountType === '') {
  			setDetailsLoading(true);
  		} else {
  			getDetails();
  		}
  		return () => {
            if(envs.accountType === 'BUSINESS') {
                dispatch(companyAllDocumentsGetClear({}))
            }
            if(envs.accountType === 'PRIVATE') {
                dispatch(individualBackDocsGetClear({}))
            }
        }
    }, []);
    useEffect(() => {
    	if(detailsLoading && envs.accountType !== '') {
    		getDetails();
    	}
    }, [envs.accountType]);
    const getDetails = () => {
    	if(envs.accountType === 'BUSINESS') {
  			dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
  			dispatch(authorizedPersonsBackGet({ params: { companyId: envs.profileId} }));
  			dispatch(ubosBackGet({ params: { companyId: envs.profileId} }));
  			dispatch(companyAllDocumentsGet({ params: {companyId: envs.profileId}}));
  		}
  		if(envs.accountType === 'PRIVATE') {
  			dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
  			dispatch(individualBackDocsGet({params: {individualId: envs.profileId}}));
  		}
    }
	return (
		<KycLayout pageName='Intro' heading='KYC Summary' nav={{next: "/step1", step: 0, nextName: "Review KYC", nextButton: "primary", status: status, navSubpage: 'kyc'}} archive={!profileArchived} restore={profileArchived}>
			<Row gutter={30}>
				<Col span={24} style={{'marginBottom': '40px'}}>
					<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Current KYC status: 
						<Text type={profileArchived ? "secondary" : status === 'SubmittedForReview' || status === 'Approved' ? "success" : status === 'NewReviewStatus' || status === 'NewPartiallyFilled' ? "secondary" : "secondary"}><b> {profileArchived ? 'Archived' : camelCaseToName(status)}</b></Text>
					</Text>
				</Col>
				<DividerCustom />
				{envs.accountType === 'BUSINESS' ? (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>Company Info</Title>
						<Summary><span>Company:</span> <b>{profileDetails?.profile?.name}</b></Summary>
						<Summary><span>Country:</span> <b>{profileDetails?.profile?.registeredAddress?.country}</b></Summary>
						<Summary><span>Website:</span> <b>{profileDetails?.description?.website}</b></Summary>
					</Col>
				) : (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>User Info</Title>
						<Summary><span>Name:</span> <b>{profileDetailsIndividual?.profile?.firstName ? profileDetailsIndividual?.profile?.firstName : 'New'} {profileDetailsIndividual?.profile?.lastName ? profileDetailsIndividual?.profile?.lastName : 'Person'}</b></Summary>
						<Summary><span>Email:</span> <b>{profileDetailsIndividual?.profile?.email}</b></Summary>
						<Summary><span>Phone:</span> <b>{profileDetailsIndividual?.profile?.phone}</b></Summary>
					</Col>
				)}
				<Col span={12}>
					<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>KYC Details</Title>
					<Summary>
						<span>Created at:</span>
						<span className='value'><b>{profileDetails?.profile?.createdAt ? moment(profileDetails?.profile?.createdAt).format('YYYY-MM-DD HH:mm:ss') : moment(profileDetailsIndividual?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
					</Summary>
		            {profileDetails?.profile?.approvedAt ? (
		            	<Summary>
		            		<span>Approved at:</span> 
		            		<span className='value'><b>{moment(profileDetails?.profile?.approvedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {profileDetailsIndividual?.approvedAt ? (
		            	<Summary>
		            		<span>Approved at:</span> 
		            		<span className='value'><b>{moment(profileDetailsIndividual?.approvedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {profileArchived ? (
		            	<Summary>
		            		<span>Archived at:</span>
		            		<span className='value'><b>{profileDetails?.profile?.archivedAt ? moment(profileDetails?.profile?.archivedAt).format('YYYY-MM-DD HH:mm:ss') : moment(profileDetailsIndividual?.archivedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {profileDetails.profile.bitclearProductType ? (
		            	<Summary>
		            		<span>Product type:</span>
		            		<span className='value'><b>{profileDetails.profile.bitclearProductType === 'PAY' ? 'PAYGATE' : profileDetails.profile.bitclearProductType}</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {isPrivate ? (
		            	<Summary>
		            		<span>Product type:</span>
		            		<span className='value'><b>FX</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {/*<Summary><span>Approves count:</span> <b>13/38</b></Summary>
		            <Summary><span>Your approves:</span> <b>9/19</b><ExclamationCircleOutlined style={{ width: 'auto', color: 'orange', marginLeft: '5px' }} /></Summary>*/}
			            
	            </Col>
	        </Row>
	        {/*<DividerCustom />*/}
	        {/*}
            <Row style={{'marginBottom': '-40px'}}>
            	<Col span={24}>
            		<Title level={4} style={{'marginBottom': '20px'}}>Latest changes</Title>
			        <Timeline mode="left">
					    <Timeline.Item>Profile created 2022-09-01 18:23:55</Timeline.Item>
					    <Timeline.Item>Profile Submitted for review 2022-09-01 22:21:54</Timeline.Item>
					    <Timeline.Item dot={<ExclamationCircleOutlined style={{ fontSize: '16px', color: 'orange' }} />}>
					      Changes made to KYC 2022-09-02 13:21:33
					    </Timeline.Item>
					    <Timeline.Item>Profile Submitted for review 2022-09-01 22:21:54</Timeline.Item>
					  </Timeline>
            	</Col>
            </Row>*/}
			{envs.accountType === 'BUSINESS' &&
				<>
					<DividerCustom />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Company documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} docs={profile.companyAllDocuments} docType='any' personId={envs.profileId} type='ap' docsGet={companyAllDocumentsGet} docsGetParams={{companyId: envs.profileId}} />
						</Col>
					</Row>
					<DividerCustom />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Contact persons</Title></Col>
						{/*@ts-ignore*/}
						{profile && profile?.profile?.contactPersons && profile?.profile?.contactPersons.length ? (
							<>
								{profile?.profile?.contactPersons.map(person => <ContactModule persons={person} />)}
							</>
						) : (<></>)}
						{authorizedPersons.authorizedPersons.map(person => <ContactModule persons={person} />)}
						{ubos.ubosList.map(person => <ContactModule persons={person} />)}
					</Row>	
				</>
			}	
			{envs.accountType === 'PRIVATE' &&
				<>
					<DividerCustom />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Individual documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} docs={individualDocs.individualDocs} docType='any' personId={envs.profileId} type='ap' docsGet={individualBackDocsGet} docsGetParams={{individualId: envs.profileId}} />
						</Col>
					</Row>
				</>
			}		
		</KycLayout>
			 
	)
}

const ContactModule = (person: any) => {
	let personEl = person.persons;
	if(personEl.archivedAt || personEl.referencedAuthorizedPerson) {
		return <></>
	}
	let fullName = personEl.name;
	let firstName = personEl.firstName ? personEl.firstName : 'New'
	let lastName = personEl.lastName ? personEl.lastName : 'Person'
	return (
		<Col span={8}>
			<StatisticCustom title={personEl.beneficialOwnerId ? 'UBO' : personEl.roleType ? personEl.roleType === 'OtherIndividual' ? 'Authorized Person' : personEl.roleType : 'Contact Person'} value={fullName ? fullName : firstName + ' ' + lastName} />
			<br />
			{personEl.email && <><SubText>{personEl.email}</SubText><br /></>}
			{personEl.phone && <><SubText>{personEl.phone}</SubText><br /></>}
		</Col>
	)
}

export default KycIntro
