import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client"
import { ubosBackGet } from "../asyncThunks/ubosBackGet"
import { uboBackCreate } from "../asyncThunks/uboBackCreate"
import { uboBackUpdate } from "../asyncThunks/uboBackUpdate"
import { uboBackArchive } from "../asyncThunks/uboBackArchive"
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store"

import { reduce } from "lodash";

export type UboResponse = Components.Schemas.BeneficialOwnerProfile;
export type ArchiveReponse = Paths.ArchiveBeneficialOwner.Responses.$200;

type State = {
  newUbo: UboResponse;
  updatedUbo: UboResponse;
  ubosList: UboResponse[];
  archivedUbo: ArchiveReponse;
};

const emptyUbo = {
    beneficialOwnerId: '',
    sourceOfWealthBusinessActivities: false,
    sourceOfWealthProfessionalOccupation: false,
    sourceOfWealthInheritance: false,
    sourceOfWealthOther: false,
    isPoliticallyExposedPerson: false,
    isUSNationality: false,
    createdAt: ''
}

const initialState: State = {
  newUbo: emptyUbo,
  updatedUbo: emptyUbo,
  ubosList: [],
  archivedUbo: false
};

type CaseReducers<State> = {
  uboBackCreateClear: CaseReducerType<State, object>;
  uboBackUpdateClear: CaseReducerType<State, object>;
  uboBackArchiveClear: CaseReducerType<State, object>;
};

export const ubosSlice = createSlice<State, CaseReducers<State>>({
  name: "ubosBack",
  initialState,
  reducers: {
    uboBackCreateClear: (state, { payload }) => {
      state.newUbo = initialState.newUbo
    },
    uboBackUpdateClear: (state, { payload }) => {
      state.updatedUbo = initialState.updatedUbo
    },
    uboBackArchiveClear: (state, { payload }) => {//add backend archive
      state.archivedUbo = initialState.archivedUbo
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ubosBackGet.fulfilled, (state, action) => {
      state.ubosList = action.payload;
    });
    builder.addCase(uboBackCreate.fulfilled, (state, action) => {
      state.newUbo = action.payload;
    });
    builder.addCase(uboBackUpdate.fulfilled, (state, action) => {
      state.updatedUbo = action.payload;
    });
    builder.addCase(uboBackArchive.fulfilled, (state, action) => {//add backend archive
      state.archivedUbo = action.payload;
    });
  }
});

export const { uboBackCreateClear, uboBackUpdateClear, uboBackArchiveClear } = ubosSlice.actions;
export default ubosSlice.reducer;
