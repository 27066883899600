import { Spin, Divider, Row, Col, Collapse, Empty, message, Popconfirm } from 'antd'
import { moment } from '../ui-elements/FormDate'
import _ from 'lodash'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { authorizedPersonsValidate } from '../../../store/kyc-front/asyncThunks/authorizedPersonsValidate'
import { authorizedPersonsBackValidate } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackValidate'
import { FC, useEffect, useState } from 'react'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import Title from 'antd/es/typography/Title'
import KycLayout from '../kyc-layout/KycLayout'
import KycAuthorizedPersonsForm from '../forms/KycAuthorizedPersonsForm';
import { themeColor, themeWeight } from '../../../styles/themeStyles'
import { APResponse } from '../../../store/kyc-front/slices/authorizedPersonsSlice'
import { ButtonUpper } from '../ui-elements/Buttons'
import { CustomCollapse, CollapsePanel, CollapseSubtitle } from '../ui-elements/CustomCollapse'
import { scrollToErrors } from '../ui-elements/BasicForm'
import styled from 'styled-components'
const { Panel } = Collapse

type resp = APResponse[];

const CustomDivider = styled(Divider)`
	margin-bottom: 0;
	&.new {
		margin-left: -20px;
    	margin-right: -20px;
    	width: initial;
	}
`

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

interface IProps {
	profile: any,
	profileGet: any,
	authorizedPersons: any,
	authorizedPersonsGet: any,
	authorizedPersonCreate: any,
	authorizedPersonClearCreate: any,
	authorizedPersonUpdate: any,
	authorizedPersonDocsGet: any,
	docUrlGet?: any,
	authorizedPersonDocCreate?: any,
	authorizedPersonDocCreateClear?: any,
	authorizedPersonArchive: any,
	authorizedPersonClearArchive: any,
	authorizedPersonClearUpdate: any,
	authorizedPersonDocArchive?: any,
	authorizedPersonDocArchiveClear?: any,
	validationAction?: any,
   	validationActionClear?: any
}

const KycAuthorizedPersonsListing: FC<IProps> = ({
	profile, profileGet, authorizedPersons, authorizedPersonsGet, authorizedPersonDocsGet, docUrlGet, authorizedPersonDocCreate, authorizedPersonDocCreateClear, authorizedPersonCreate, authorizedPersonClearCreate, authorizedPersonUpdate, authorizedPersonArchive, authorizedPersonClearArchive, authorizedPersonClearUpdate, authorizedPersonDocArchive, authorizedPersonDocArchiveClear, validationAction, validationActionClear
}) => {
	const [apsLoaded, setApsLoaded] = useState(false);
	const [newPanelAdded, setNewPanelAdded] = useState(false);
	const [messageShown, setMessageShown] = useState(true);
	const [created, setCreated] = useState(false);
	const [activeTabs, setActiveTabs] = useState<string[]>([]);
	const [showArchived, setShowArchived] = useState(false);
	let [disabled, setDisabled] = useState(false);
	const envs = useSelector((state) => state.general.envs);
	const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status) 
   	const userProfile = profile;
	let [popupEditVisible, setPopupEditVisible] = useState(false);
	const { isPending, isError, isFullfilled } = useActionStatus(authorizedPersonsGet);
	const createStatus = useActionStatus(authorizedPersonCreate);
	const updateStatus = useActionStatus(authorizedPersonUpdate);
	const validateStatus = useActionStatus(envs.admin ? authorizedPersonsBackValidate : authorizedPersonsValidate);
	const errorsState = useSelector((state) => state.general.errorsState);
	useEffect(() => {
        dispatch(authorizedPersonsGet({ params: { companyId: envs.profileId} }));
        if(userProfile.companyId === '') {
            dispatch(profileGet({ params: { companyId: envs.profileId } }));
        }
    }, []);
	useEffect(() => {
        if((userProfile?.profile?.reviewStatus === 'SubmittedForReview' || userProfile?.profile?.reviewStatus === 'Approved') && (disabled === false)) {
            setDisabled(true);
        }
    }, [userProfile])
	//Showing all collapsed elements from errors listing
	useEffect(() => {
      	if(status.authorizedPersonsValidated && validateStatus.isFullfilled) {
      		let noErrors = false;
      		status.authorizedPersonsValidated.forEach((personStatus) => {
      			if(activeTabs.find(el => el === personStatus.entry)) {
      				noErrors = false;
      				return
      			} else {
      				//@ts-ignore
      				if(personStatus.validationErrors.length) {
      					setActiveTabs(oldList => [personStatus.entry, ...oldList]);
      					noErrors = false;
      				} else {
      					noErrors = true
      				}
      				
      			}
      		})
      		if(noErrors) {
      			//message.success('This form is valid');
      			//dispatch(validationActionClear({}))
      		}
      	}
      	
    }, [status.authorizedPersonsValidated])
	 useEffect(() => {
        if(!_.isEmpty(errorsState) && (errorsState.route === 'step2')) {
            dispatch(validationAction({ params: { companyId: envs.profileId } }));
        }
    }, [errorsState])
    useEffect(() => {
    	if(messageShown) {
            if(createStatus.isFullfilled && (authorizedPersons.newAuthorizedPerson.authorizedPersonId !== '')) {
               message.success('Person was sucessfully added');
               setActiveTabs(oldList => [authorizedPersons.newAuthorizedPerson.authorizedPersonId, ...oldList]);
               dispatch(authorizedPersonClearCreate({}));
               dispatch(authorizedPersonsGet({ params: { companyId: envs.profileId} }))
               setCreated(true);
               setMessageShown(false);
            }
            if(createStatus.isError) {
               message.error('Some error occured while adding new person');
               dispatch(authorizedPersonClearCreate({}));
               setMessageShown(false);
            }
      }
      if(isFullfilled && (created === true)) {
         setNewPanelAdded(true);
         setCreated(false);
				let timeId;
				clearTimeout(timeId)
				timeId = setTimeout(() => {
			      setNewPanelAdded(false)
			}, 1500)
      }
    });
	const addAuthorizedPerson = () => {
		let newElement = {personalDetails: {}};
		//@ts-ignore
		dispatch(authorizedPersonCreate({ params: { companyId: envs.profileId}, data: newElement}));
		//.then(response => dispatch(setFormUpdated({updated: true, form: response.payload.authorizedPersonId, formName: 'apForm'})))
		setMessageShown(true);
	}
	const handleCollapseChange = (els: string[] | never[]) => {
		setActiveTabs([...els]);
	}
	const cancelEdit = () => {
        setPopupEditVisible(false);
   }
   const handleEditVisibleChange = () => {
        setPopupEditVisible(!popupEditVisible);
   }
   const confirmEdit = () => {
      setPopupEditVisible(false);
      addAuthorizedPerson();
      //dispatch new state for kyc form - PartiallyFilled
  	}
  	const handleShowArchived = () => {
  		setShowArchived(!showArchived);
  	}
	let array = [...authorizedPersons.authorizedPersons];
	let archivedArray:any[] = [];
	if(array) {
		if(envs.admin) {
			let adminArray:string[] = [];
			array.forEach(el => {
				if(el.archivedAt) {
					archivedArray.push(el);
				} else {
					adminArray.push(el);
				}
			})
			array = adminArray;
		}
		array.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
		archivedArray.sort(function(a,b){
			return moment(b.createdAt).diff(moment(a.createdAt), 'milliseconds')
		});
	}
	return (
      	<KycLayout pageName='Step 2' heading='Authorized persons' nav={{step: 2, next: "/step3", prev: "/step1", navSubpage: 'kyc'}} adminView={envs.admin} validationAction={envs.admin ? authorizedPersonsBackValidate : authorizedPersonsValidate} validationActionParams={{companyId: envs.profileId}}>
      		<Text>Please name at least one person with signatory rights for the company. Please name all persons who will act on behalf of the company towards us.</Text>
      		<div style={{'marginBottom': '-17px'}}>
      			{disabled ? (
	      			<PopconfirmCustom
	                   title="If You make this change it may block Your account until Bitclear AG approves the changes"
	                   visible={popupEditVisible}
	                   onVisibleChange={handleEditVisibleChange}
	                   onConfirm={confirmEdit}
	                   onCancel={cancelEdit}
	                   okText="Add new Authorized Person"
	                   cancelText="Cancel"
	                   icon={<QuestionCircleOutlined />}>
	            		<ButtonUpper className='btn-sm' btnType="primary" ghost>Add authorized person</ButtonUpper>
	            	</PopconfirmCustom>
            	) : (
            		<ButtonUpper className='btn-sm' btnType="primary" ghost onClick={addAuthorizedPerson}>Add authorized person</ButtonUpper>
            	)}
            	<CustomDivider className={newPanelAdded === true ? 'new' : ''} />
	      		{authorizedPersons?.authorizedPersons?.length && authorizedPersons.authorizedPersons.find((el: any) => !el.archivedAt) ? (
	      			 <CustomCollapse
	      			 		id='authorizedPersonsForm'
				          	activeKey={activeTabs}
				          	bordered={false}
				          	onChange={handleCollapseChange}
				          	ghost={true}
				          	expandIcon={(props: any) => <ButtonUpper btnType="default">Details <DownOutlined rotate={props?.isActive ? 180 : 0} /></ButtonUpper>}
				          	expandIconPosition='right'
				        >
				        	{array.map((person, i) => {
					        	return (
					        		<Panel className={i === 0 && newPanelAdded === true ? 'new' : ''} key={person.authorizedPersonId} header={(<CollapsePanel archived={person.archivedAt ? true : false} name={(person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person')} role={person.roleType ? (person.roleType ==='OtherIndividual' ? 'Other' : person.roleType as string) : 'Role'} />)}>
							         <KycAuthorizedPersonsForm 
							         	person={person} 
							         	docUrlGet={docUrlGet} 
							         	authorizedPersonClearUpdate={authorizedPersonClearUpdate}
							         	authorizedPersonsGet={authorizedPersonsGet}
							         	authorizedPersonUpdate={authorizedPersonUpdate}
							         	authorizedPersonDocsGet={authorizedPersonDocsGet} 
							         	authorizedPersonDocCreate={authorizedPersonDocCreate}
							         	authorizedPersonDocCreateClear={authorizedPersonDocCreateClear}
							         	authorizedPersonArchive={authorizedPersonArchive}
							         	authorizedPersonClearArchive={authorizedPersonClearArchive}
							         	authorizedPersonDocArchive={authorizedPersonDocArchive}
							         	authorizedPersonDocArchiveClear={authorizedPersonDocArchiveClear}
							         	validationAction={validationAction}
											validationActionClear={validationActionClear}
							         />
							      </Panel>
					        	)
				        })}

				    </CustomCollapse>
				) : (
					<>
						{isFullfilled ? (
							<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Authorized Person' />
						) : (
							<Spin size="large" style={{'marginTop': '30px'}} />
						)}
					</>
					
				)}
			</div>
			{envs.admin &&
				<>
					<Row>
						<Col span={24} style={{'textAlign': 'right'}}>
							<ButtonUpper btnType='dashed' className='btn-sm dashed-primary right' ghost onClick={handleShowArchived}>{showArchived ? 'Hide archived APs' : 'Show archived APs'}</ButtonUpper>
						</Col>
					</Row>
					{showArchived &&
						<Row>
							<Col span={24}>
								{archivedArray.length ? (
					      			 <CustomCollapse
								          	activeKey={activeTabs}
								          	bordered={false}
								          	onChange={handleCollapseChange}
								          	ghost={true}
								          	expandIcon={(props: any) => <ButtonUpper btnType="default">Details <DownOutlined rotate={props?.isActive ? 180 : 0} /></ButtonUpper>}
								          	expandIconPosition='right'
								        >
								        {archivedArray.map((person, i) => {
									        	return (
									        		<Panel key={person.authorizedPersonId} header={(<CollapsePanel archived={person.archivedAt ? true : false} name={(person.firstName ? person.firstName : 'New') + ' ' + (person.lastName ? person.lastName : 'Person') } role={person.roleType ? (person.roleType ==='OtherIndividual' ? 'Other' : person.roleType as string) : 'Role'} />)}>
											         <KycAuthorizedPersonsForm 
											         	person={person} 
											         	authorizedPersonClearUpdate={authorizedPersonClearUpdate}
											         	authorizedPersonsGet={authorizedPersonsGet}
											         	authorizedPersonUpdate={authorizedPersonUpdate}
											         	authorizedPersonDocsGet={authorizedPersonDocsGet} 
											         	docUrlGet={docUrlGet} 
											         	deleteForm={false}
											         	restoreForm={true}
											         	authorizedPersonArchive={authorizedPersonArchive}
											         	authorizedPersonClearArchive={authorizedPersonClearArchive}
											         	authorizedPersonDocArchive={authorizedPersonDocArchive}
											         	authorizedPersonDocArchiveClear={authorizedPersonDocArchiveClear}
											         	validationAction={validationAction}
											         	validationActionClear={validationActionClear}
											         />
											      </Panel>
									        	)
								        })}

								    </CustomCollapse>
								) : (
									<>
										{isFullfilled ? (
											<Empty style={{'marginTop':'50px', 'marginBottom' : '30px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description='No archived UBOs' />
										) : (
											<Spin size="large" style={{'marginTop': '30px'}} />
										)}
									</>
								)}
							</Col>
						</Row>
					}
					</>
				}
		</KycLayout>
			 
	)
}

export default KycAuthorizedPersonsListing
