import { Divider, Timeline, Popconfirm, Form, Input } from 'antd'
import _ from 'lodash'
import { SolutionOutlined, CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { FC, ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { themeColor, themeSize } from '../../../styles/themeStyles'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { dispatch } from '../../../store/store'
import { readyForReviewValidate } from '../../../store/kyc-front/asyncThunks/readyForReviewValidate'
import { individualReadyForReviewValidate } from '../../../store/kyc-front/asyncThunks/individualReadyForReviewValidate'
import { readyForReviewBackValidate } from '../../../store/kyc-backend/asyncThunks/readyForReviewBackValidate'
import { individualBackReadyForReviewValidate } from '../../../store/kyc-backend/asyncThunks/individualBackReadyForReviewValidate'

import { useSelector } from '../../../hooks/useSelector'
import { setFormUpdated } from '../../../store/kyc-front/slices/navSlice'

import { FormSaver } from '../../../utilities/FormSaver'

const { Item } = Timeline;

const CustomLink = styled(Link)`
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
`

const CustomLinkSpan = styled.span`
    cursor: pointer;
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
`
const KycStep = styled.span`
    font-size: 13px!important;
    .ant-timeline-item-content {
        top: -4px;
        margin-left: 20px;
    } 
    color: ${themeColor.gray};
    &.active {
        text-decoration: underline;
    }
    
`

const IconBadge = styled.span`
    .anticon {
        font-size: ${themeSize.lg};
        left: 5px;
        position: relative;
        top: 1px;
    }
    &.success {
        color: ${themeColor.green};
    }
    &.error {
        color: ${themeColor.red};
    }
`

interface IProps {
    adminView?: boolean,
}

interface sProps {
    readyForReviewValidated: boolean | undefined
}

export const KycSteps: FC<IProps> = ({adminView}) => {
    const nav = useSelector((state) => state.general.nav);
    let {formUpdated, currentEditedForm} = nav;
    const envs = useSelector((state) => state.general.envs);
    const companyValidationAction = envs.admin ? readyForReviewBackValidate : readyForReviewValidate;
    const individualValidationAction = envs.admin ? individualBackReadyForReviewValidate : individualReadyForReviewValidate;
    const { readyForReviewValidated, individualReadyForReviewValidated } = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status);
    const [popupVisible, setPopupVisible] = useState(-1);
    const [stepSummary, setStepSummary] = useState({0: true, 1: false, 2: false, 3: false, 4: false});
    const readyForReviewValidatedStatus = useActionStatus(companyValidationAction);
    const [onConfirm, setOnConfirm] = useState(false);
    const [onConfirmLink, setOnConfirmLink] = useState('')
    const individualReadyForReviewValidatedStatus = useActionStatus(individualValidationAction);
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
    useEffect(() => {
        if(envs.accountType === 'BUSINESS') {
            if(!readyForReviewValidatedStatus.isFullfilled) {
                if(envs.admin) {
                    dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                } else {
                    dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                }
            }
        } else {
            if(!individualReadyForReviewValidatedStatus.isFullfilled) {
                if(envs.admin) {
                    dispatch(individualBackReadyForReviewValidate({params: {individualId: envs.profileId}}))
                } else {
                    dispatch(individualReadyForReviewValidate({params: null}));
                }
                
            }
            
        }
    }, []);
    useEffect(() => {
        let step1 = true;
        let step2 = true;
        if(envs.accountType === 'BUSINESS') {
            
            if(readyForReviewValidatedStatus.isFullfilled) {
                let step3 = true
                let step4 = true;
                //@ts-ignore
                if(readyForReviewValidated.companyProfileValidated.validationErrors.length) {
                    step1 = false;
                }
                //@ts-ignore
                readyForReviewValidated?.authorizedPersonsValidated.forEach(person => {
                    //@ts-ignore
                    if(person.validationErrors.length) {
                        step2 = false
                        return
                    }
                })
                //@ts-ignore
                readyForReviewValidated?.beneficialOwnersValidated.forEach(person => {
                    //@ts-ignore
                    if(person.validationErrors.length) {
                        step3 = false
                        return
                    }
                })
                //@ts-ignore
                if(readyForReviewValidated.numberOfDirectorsValidated.validationErrors.length) {
                    step2 = false;
                }
                //@ts-ignore
                if(readyForReviewValidated.numberOfBeneficialOwnersValidated.validationErrors.length) {
                    step3 = false;
                }
                //@ts-ignore
                if(readyForReviewValidated.numberOfSignatoryValidated.validationErrors.length) {
                    step4 = false;
                }
                setStepSummary({0: true, 1: step1, 2: step2, 3: step3, 4: step4})
            }
        } 
    }, [readyForReviewValidated]);
    useEffect(() => {
        let step1 = true;
        let step2 = true;
        if(envs.accountType === 'PRIVATE') {
            if(individualReadyForReviewValidatedStatus.isFullfilled) {
                //@ts-ignore
                if(individualReadyForReviewValidated.beneficialOwnerValidated.validationErrors.length) {
                    step1 = false;
                }
                //@ts-ignore
                if(individualReadyForReviewValidated.declarationValidated.validationErrors.length) {
                    step2 = false;
                }
                setStepSummary({0: true, 1: step1, 2: step2, 3: false, 4: false})
            }
        }
    }, [individualReadyForReviewValidated]);
    const handleVisibleChange = (i: number) => {
        if(formUpdated.updated) {
            setPopupVisible(i);
        }
    }
    const confirm = (link: string) => {
        setOnConfirm(true);
        setOnConfirmLink(link);
    }
    const cancel = () => {
        setPopupVisible(-1);
    }
    const { accountType } = envs;
    let {step} = nav.nav;
    let steps = [];
    if(accountType === 'BUSINESS') {
        steps = [
            {name: 'Intro - Welcome to KYC', saved: true, link: envs.type === 'standalone' ? '/summary' : '/'}, 
            {name: 'Company information & business description', saved: stepSummary[1], link: '/step1'}, 
            {name: 'Authorized persons', saved: stepSummary[2], link: '/step2'}, 
            {name: 'Ultimate Beneficial Owners', saved: stepSummary[3], link: '/step3'}, 
            {name: 'Data validation and printout', saved: stepSummary[4], link: '/step4'}
        ]
    } else {
        steps = [
            {name: 'Intro - Welcome to KYC', saved: true, link: envs.type === 'standalone' ? '/summary' : '/'}, 
            {name: 'Your personal details', saved: stepSummary[1], link: '/step1'}, 
            {name: 'Declarations and printout', saved: stepSummary[2], link: '/step2'}
        ]
    }
    return (
        <>
            <FormSaver onConfirm={onConfirm} onConfirmLink={onConfirmLink} />
            <Timeline
                items={steps.map((stepItem, i) => {
                    return {
                        color: i === step ? themeColor.primaryColor : themeColor.gray,
                        children: (
                            <span key={'step'+ i}>
                                {formUpdated.updated ? (
                                    <CustomLinkSpan onClick={() => confirm(stepItem.link)} key={'step'+ i} >
                                        <KycStep className={i === step ? 'active' : ''}>{stepItem.name}{stepItem.saved && <IconBadge style={{'position': 'absolute'}} className='success'><CheckCircleFilled /></IconBadge>}</KycStep>
                                    </CustomLinkSpan>

                                ) : (
                                    <CustomLink to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + stepItem.link : envs.routeSuffix + stepItem.link} key={'step'+ i} >
                                         <KycStep className={i === step ? 'active' : ''}>{stepItem.name}{stepItem.saved && <IconBadge style={{'position': 'absolute'}} className='success'><CheckCircleFilled /></IconBadge>}</KycStep>
                                    </CustomLink>
                                )}
                            </span>
                        )
                    }
                })}
              />
        </>
    )
}