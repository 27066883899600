import { FC, useEffect, useState } from 'react'
import { Row, Col, Divider, Select, Card, Button, message } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { authorizedPersonsBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonsBackGet'
import { approveCompanyToggle } from '../../../store/kyc-backend/asyncThunks/approveCompanyToggle'
import { approveIndividualToggle } from '../../../store/kyc-backend/asyncThunks/approveIndividualToggle'
import { approveCompanyToggleClear } from '../../../store/kyc-backend/slices/finalApproveSlice'
import { statusUpdate } from '../../../store/kyc-front/asyncThunks/statusUpdate' 
import { useActionStatus } from '../../../hooks/useActionStatus'
import FormSelect from '../../kyc-front/ui-elements/FormSelect'
import { PageContent } from '../../layout/PageContent'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { ValidationErrors } from '../../kyc-backend/ui-elements/ValidationErrors';
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { SuccessIcon } from '../../kyc-front/ui-elements/SuccessInfo'

const Muted = styled.p`
    color: ${themeColor.gray}
`
const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`


const KycDataValidation: FC = () => {
	const profile = useSelector((state) => state.admin.backCompany);
	const envs = useSelector((state) => state.general.envs);
	const authorizedPersons = useSelector((state) => state.admin.backAuthorizedPersons);
	const finalApprove = useSelector((state) => state.admin.finalApprove);
	const companyFinal = finalApprove.companyApprovedStatus;
	const [signatoriesChosen, setSignatoriesChosen] = useState<string[]>([]);
	const [signatoriesLoaded, setSignatoriesLoaded] = useState(false);
	const { Option } = Select;
	const formStatus = useActionStatus(authorizedPersonsBackGet);
	let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
	useEffect(() => {
        return () => {
            dispatch(approveCompanyToggleClear({}));
        };
    }, []);
	useEffect(() => {
		dispatch(authorizedPersonsBackGet({params: {companyId: envs.profileId}}))
	}, [])
	useEffect(() => {
		if(signatoriesLoaded === false && formStatus.isFullfilled) {
			let signatoriesArray:string[] = [];
			authorizedPersons.authorizedPersons.map((ap, i) => {
				if(ap.signatory) {
					// @ts-ignore
					signatoriesArray.push((ap.firstName ? ap.firstName : 'New') + ' ' + (ap.lastName ? ap.lastName : 'Person'));
				}
			})
			setSignatoriesChosen(signatoriesArray)
			setSignatoriesLoaded(true);
		}
		
		
	});
	useEffect(() => {
		if(companyFinal.isApproved) {
			window.scrollTo(0, 0);
		}
	}, [companyFinal.isApproved])
	return (
        	<KycLayout pageName='Step 4' heading='Data validation and printout' nav={{step: 4, prev:'/step3', hideNav: false, navSubpage: 'kyc'}} approve={true}>
				{companyFinal.isApproved ? (
					<div>
		                    <Row justify='center'>
		                        <CenteredCol>
		                            <SuccessIcon />
		                            <p><b>Approved!</b></p>
		                            <p>Your have succesfully approved this KYC</p>
		                        </CenteredCol>
		                    </Row>
		                    <Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
		                        <CenteredCol>
		                            <Link to={envs.type === 'standalone' ? isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + '/summary' : envs.routeSuffix + '/summary' : envs.routeSuffix + '/'}><ButtonUpper btnType='default'>Go to Summary</ButtonUpper></Link>
		                        </CenteredCol>
		                    </Row>
		            </div>
		        ) : (
		        <>
					<PageContent>
							<Row gutter={30} className='ant-form-vertical' style={{'marginTop': '-30px'}}>
								<Col md={24}>
									<p>1.  The funds that are and will be used to fund the transactions are clear and are not derived from any illegal activities but are derived from above mentioned source(s).</p>

		                            <p>2.  We also recognize that the company may be subject to tax where tax resident and we hereby undertake to obtain advice as necessary to ensure that the due taxes are paid and statutory compliance is honored. To this effect, Bitclear AG accepts no liability for any tax consequences.</p>

		                            <p>3.  We hereby declare that the details furnished above are true and correct to the best of our knowledge and belief and <b>we undertake to inform you of any changes therein, immediately by sending an email to KYC@bitclear.li</b>. In case any of the above information is found to be false or untrue or misleading or misrepresenting, we are aware that we may be held liable for it.</p>

		                            <Muted>The EU General Data Protection Regulation (GDPR), which came into force on 25th May 2018, is effective for all EU and EEA residents in respect of their personal data which is collected and processed. The Privacy Policy statement of Bitclear AG in respect of the processing of clients’ data and the rights of clients is available on www.bitclear.li/privacy-policy. Please contact KYC@bitclear.li for any data protection matters.</Muted>
		                        </Col>
		                        {signatoriesLoaded && formStatus.isFullfilled &&
				                    <Col md={24}>
				                    	{/* @ts-ignore */}
				                        <FormSelect mode='multiple' defaultValue={signatoriesChosen} label='Signatories declared by client:' id='declaredSignatories' placeholder='No signatory chosen' onChange={() => false} showErrors={false} hideApprove={true} disabled>
				                            {authorizedPersons.authorizedPersons.map((ap, i) => {
				                                if(ap.signatory) {
				                                    return <Option key={'signatories' + i} value={'signatories' + i}>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</Option>
				                                }
				                                
				                            })}
				                        </FormSelect>
				                    </Col>
				                }
			                </Row>
		                <br />
		                <Muted>(Signatory must be authorized to sign for the company)</Muted>
					</PageContent>
					<ValidationErrors />
				</>
				)}
				
			</KycLayout>

	)
}

export default KycDataValidation
