import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualResponse } from "../slices/archivedSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type RequestBody = Components.Schemas.ArchiveRequest;
type PathParameters = Paths.ArchiveIndividualToggle.PathParameters;

export const individualArchive: AsyncThunkActionType<
  IndividualResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "data">
> = createAsyncThunk(
  "individualArchive",
  async ({ params, data, config }, thunkAPI) => {
	const { rejectWithValue } = thunkAPI;

	try {
		const response = await api.ArchiveIndividualToggle(params, data, config);
		return response.data as IndividualResponse;
	} catch (err: any) {
		return rejectWithValue(err.response.data);
	}
  }
);
