import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyResponse } from "../slices/approvedSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type RequestBody = Components.Schemas.CompanyApproveFields;
type reqBody = {
  data: Components.Schemas.CompanyApproveFields,
  name: string
}
type PathParameters = Paths.CompanyApproveFields.PathParameters;
type approveResp = {
  name: string,
  data: CompanyResponse
}

export const companyApproveFieldsUpdate: AsyncThunkActionType<
  approveResp,
  AsyncThunkDataType<PathParameters, reqBody, "data">
> = createAsyncThunk(
  "companyApproveFieldsUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CompanyApproveFields(params, data.data, config);
      return {name: data.name, data: response.data} as approveResp;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
