import { Space, Row, Col, Progress, List, Button, Affix, Layout, Result, Badge, Menu } from 'antd'
import type { MenuProps } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import { LeftOutlined, CaretLeftOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import Text, { TextProps } from 'antd/es/typography/Text'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { logout, logoutUser } from 'modules/keycloak'
import { logoutAndClearState } from 'store/kyc-front/asyncThunks/logoutAndClearState'
import { setNav } from '../../../store/kyc-front/slices/navSlice'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import { readyForReviewValidateClear, individualReadyForReviewValidateClear } from '../../../store/kyc-front/slices/statusSlice'
import { readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import { companyDocsGetClear as companyDocsGetBackClear} from '../../../store/kyc-backend/slices/companyDocsSlice'
import { adminMessagesGet } from '../../../store/kyc-backend/asyncThunks/adminMessagesGet'
import { companyDocsGetClear } from '../../../store/kyc-front/slices/companyDocsSlice'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { Components } from '../../../api/kyc/generated/client'
import { PageContent } from '../../layout/PageContent'
import { ButtonUpper } from '../ui-elements/Buttons'
import { adminProfileGet } from '../../../store/kyc-backend/asyncThunks/adminProfileGet'
import { adminProfilesGet } from '../../../store/kyc-backend/asyncThunks/adminProfilesGet'
import { adminProfileGetClear } from '../../../store/kyc-backend/slices/adminSlice'
import { profileGetClear } from '../../../store/kyc-front/slices/profileSlice'
import { individualGetClear } from '../../../store/kyc-front/slices/individualSlice'
import { identityGetClear } from '../../../store/kyc-front/slices/identitySlice'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { camelCaseToName } from '../../../utilities/nameParsers'
import Error403 from '../Error403'
import FooterDrawer from './KycFooterDrawer'
import { KycSteps } from './KycSteps'
import KycMessages from '../../kyc-messages/KycMessages'
import Logo from '../../../assets/logo-kyc.png'

import { FormSaver } from '../../../utilities/FormSaver'

import { KycNav } from './KycNav';
import { themeColor, themeSize, themeScreen } from '../../../styles/themeStyles'
const { Footer, Header } = Layout



const PageName = styled(Text)`
	color: ${themeColor.primaryColor};
	font-size: ${themeSize.heading3};
	font-weight: 500;
`
const ButtonGroupItem = styled(ButtonUpper)`
	width: 100%;
`
const Sticky = styled.div`
	position: -webkit-sticky; 
  	position: sticky;
  	z-index: 9;
  	&.no-stick {
  		position: relative;
  	}
`
const CustomBadge = styled(Badge)`
   z-index: 9;
   display: block;
	 width: 100%;
	.ant-badge-count {
		padding: 1px 6px;
		
	}
	.ant-btn-dashed {
		padding: 0;
    	height: 33px;
    	font-size: 14px;
	}
`

const CustomProgress = styled(Progress)`
	&.ant-progress-circle.ant-progress-status-success .ant-progress-text {
		color: ${themeColor.green}!important;
	}
`

export const ImgLogo = styled.img`
	max-width: 265px;
	margin-left: -45px;
`

export const HeaderCustom = styled(Header)`
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 0;
	border-bottom: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent}
	}
`
export const FooterCustom = styled(Footer)`
	width: 100%;
	border-top: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent}
	}
`

const FooterCustomModule = styled(Footer)`
	padding: 0;
`

export const NavCustom = styled.div`
	border-bottom: 1px solid ${themeColor.grayWhite};
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 65px;
	background-color: #fff;
	padding-top: 8px;
	padding-bottom: 8px;
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
	p {
		margin-bottom: 0;
		padding-top: 3px;
		color: ${themeColor.gray};
		.client {
			color: #000;
		}
	}
`

export const NavCustomTabs = styled(NavCustom)`
	top: 108px;
	padding-top: 0;
	padding-bottom: 0;
`

export const ButtonUpperCustom = styled(Link)`
	.btn-sm {
		font-size: 14px;
		font-weight: 500;
		padding-left: 0;
		color: ${themeColor.gray};
	}
`

const ButtonUpperTabs = styled(ButtonUpper)`
	&.btn-sm {
		color: ${themeColor.gray};
		padding-top: 8px;
		padding-bottom: 8px;
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

type ReviewStatusType = Components.Schemas.ReviewStatusType;

interface IProps {
	approve?: boolean,
	archive?: boolean,
	restore?: boolean,
	listingView?: boolean,
	pageName?: string,
	heading?: string,
	adminView?: boolean,
	children: ReactNode,
	validationAction?: (value: any) => void,
	validationActionParams?: object
	nav: {
		next?: string,
		step?: number,
		nextName?: string,
		nextButton?: string,
		prev?: string,
		prevName?: string,
		status?: ReviewStatusType | undefined,
		hideNav?: boolean,
		hideSteps?: boolean,
		navPage?: string,
		navSubpage?: string
	}
}

const MenuCustom = styled(Menu)`
	position: relative;
	top: -1px;
	&.ant-menu-dark {
		background: #fff;
		.ant-menu-item {
			color: #000;
			font-weight: bold;
			&.ant-menu-item-selected {
				background-color: #f6f6f6;
				color: ${themeColor.primaryColor};
			}
			&:hover {
				background-color: #f6f6f6;
			}
		}
	}
	
	
`

const items = (count: number) => ([
  	{
    	label: 'Client profiles',
    	key: '',
  	},
  	{
    	label: 'Archived clients',
    	key: 'archived'
  	}, 
  	{
    	label: <>Messages <Badge count={count} /></>,
    	key: 'messages'
  	}
]);

export const KycLayout: FC<IProps> = ({
	pageName, heading, adminView, children, nav, validationAction, validationActionParams, approve, archive, restore, listingView
}) => {
	let {next, step, nextName, nextButton, prev, prevName, status, hideNav, hideSteps, navPage, navSubpage} = nav;
	const envs = useSelector((state) => state.general.envs);
	const profile = useSelector((state) => envs.admin ? state.admin.backCompany.profile : state.user.profile.userProfile);
	const individualProfile = useSelector((state) => envs.admin ? state.admin.backIndividual.individual : state.user.individual.individual);
	const {apiURL, admin, adminSuffix, type, env, routeSuffix, profileId, accountType} = envs;
	const messages = useSelector((state) => state.user.messages);
	const adminProfile = useSelector((state) => state.admin.admin);
	const adminInbox = useSelector((state) => state.admin.adminInbox);
	const navEl = useSelector((state) => state.general.nav);
	const { session } = useSelector((state) => state.general.auth);
	let {formUpdated} = navEl;
	const { readyForReviewValidated, individualReadyForReviewValidated } = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status);
	const adminProfileLoaded = useActionStatus(adminProfileGet);
	const [onConfirm, setOnConfirm] = useState(false);
   	const [onConfirmLink, setOnConfirmLink] = useState('');
   	const [navSearch, setNavSearch] = useState('');
   	const statusInfo = envs.accountType === 'BUSINESS' ? profile?.profile?.reviewStatus : individualProfile?.reviewStatus;
   	const relationStatus = envs.accountType === 'BUSINESS' ? profile?.profile?.businessRelation?.isActive : individualProfile?.businessRelation?.isActive
   	const [current, setCurrent] = useState(navEl.nav.navPage ? navEl.nav.navPage : '');
   	const profileArchived = profile?.profile?.archivedAt || individualProfile?.archivedAt ? true : false;
   	const adminMessagesGetStatus = useActionStatus(adminMessagesGet);
   	let navigate = useNavigate();
   	let messagesCount = 0;
	const authState = useSelector((state) => state.general.auth);
   	//@ts-ignore
   	if(adminInbox?.messages.companyMessages.length || adminInbox?.messages.individualMessages.length) {
   		//@ts-ignore
   		messagesCount = adminInbox.messages.companyMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length + adminInbox.messages.individualMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length;
   	}
   	const onClick: MenuProps['onClick'] = e => {
	    setCurrent(e.key);
	    dispatch(setNav({next, step, nextName, nextButton, prev, prevName, status, hideNav, navPage: e.key, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage}))
		navigate(envs.routeSuffix + '/' + e.key);
	};
	useEffect(() => {
		if(nav.navPage) {
			setCurrent(nav.navPage);
		}
		dispatch(setNav({next, step, nextName, nextButton, prev, prevName, status, hideNav, navPage: nav.navPage ? nav.navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage}));
		if(envs.admin && type === 'standalone') {
			if(!adminMessagesGetStatus.isFullfilled) {
				dispatch(adminMessagesGet({params: null}));
			}
			//if(envs.env !== 'local') {
				const interval = setInterval(() => {
				    dispatch(adminMessagesGet({params: null}));
				}, 60000);
				return () => clearInterval(interval);
			//}
		}
	}, []);
	useEffect(() => { 
		if(profileArchived && current !== 'archived') {
			setCurrent('archived');
			let currNav = navEl.nav;
			dispatch(setNav({next: currNav.next, step: currNav.step, nextName: currNav.nextName, nextButton: currNav.nextButton, prev: currNav.prev, prevName: currNav.prevName, status: currNav.status, hideNav: currNav.hideNav, navPage: 'archived', paginationPage: currNav.paginationPage, sorters: currNav.sorters, filters: currNav.filters, navSubpage}));
		}
	}, [profileArchived]);
	useEffect(() => {
		if(envs.admin) {
			 if(adminProfile.profile.adminId === '') {
            dispatch(adminProfileGet({params: null}));
         }
         if(adminProfile.profiles.length === 0) {
            dispatch(adminProfilesGet({params: null}));
         }
		}
        
    }, [adminProfile]);
    useEffect(() => {
    	let navSearchNew = navEl.nav.paginationPage ? '?page=' + navEl.nav.paginationPage : '';
	    if(navEl.nav.sorters) {
	    	_.forEach(navEl.nav.sorters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val;
	    		}
	    	})
	    }
	    if(navEl.nav.filters) {
	    	_.forEach(navEl.nav.filters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val;
	    		}
	    	})
	    }
	    setNavSearch(navSearchNew);
    }, [navEl.nav]);
	const navToInbox = () => {
		if(formUpdated.updated) {
			setOnConfirm(true);
        	setOnConfirmLink('/inbox');
      } else {
      	navigate(envs.routeSuffix + '/inbox')
      }
	}
	const handleLogoutClick = () => {
		if(envs.accountType === 'BUSINESS') {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}));
				dispatch(readyForReviewBackValidateClear({}));
				dispatch(companyDocsGetBackClear({}));
			} else {
				dispatch(companyDocsGetClear({}));
				dispatch(identityGetClear({}));
				dispatch(profileGetClear({}));
				dispatch(readyForReviewValidateClear({}));
			}
			
		} else {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}));
				dispatch(individualBackReadyForReviewValidateClear({}));
			} else {
				dispatch(identityGetClear({}));
				dispatch(individualGetClear({}));
				dispatch(individualReadyForReviewValidateClear({}))
			}
		}
		dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: '', accountType: ''}));
		onLogout();
	}
	const onLogout = async () => {
		await logoutUser({
			refreshToken: session?.refresh_token,
			//@ts-ignore
			keycloakDomain: process.env.REACT_APP_KEYCLOAK_DOMAIN,
			//@ts-ignore
			client_id: envs.admin ? process.env.REACT_APP_KEYCLOAK_CLIENT_BACK : process.env.REACT_APP_KEYCLOAK_CLIENT_FRONT,
			//@ts-ignore
			is_backend: envs.admin,
			callback: () => dispatch(logout(true))
		})
	}
	let config = envs;
	return (
		<>
			{envs.type === 'standalone' &&
				<HeaderCustom>
						<div className='content'>
							<Row>
								<Col sm={8} md={7} lg={6} xl={5}>
						 			<ImgLogo src={Logo} alt='Bitclear KYC' />
						 		</Col>
						 		<Col sm={8} md={7} lg={10} xl={11}>
							 		{envs.admin && envs.type === 'standalone' &&
							 			/*@ts-ignore*/
							 			<MenuCustom theme='dark' style={{ width: 510 }} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items(messagesCount)} />
							 		}
						 		</Col>
						 		<Col sm={8} md={10} lg={8} xl={8}>
							 		<div style={{color: themeColor.gray, textAlign: 'right'}}>
							 			<span style={{color: '#000'}}>Hello, {envs.admin ? camelCaseToName(adminProfile.profile.username) : envs.accountType === 'BUSINESS' ? profile.profile.name : individualProfile.profile.firstName ? individualProfile.profile.firstName : individualProfile.profile.lastName ? individualProfile.profile.lastName : 'there'}</span>
							 			<span> | </span>
							 			<a onClick={handleLogoutClick}><b>Logout</b></a>
							 		</div>
						 		</Col>
						 		
						 	</Row>
					    </div>
				</HeaderCustom>
			}
			{envs.admin && envs.type === 'standalone' && 
				<KycMessages />
			}
			{envs.type === 'standalone' && envs.admin && nav.step !== -1 &&
				<>
					<NavCustom style={{top: messagesCount ? 122 : 65}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<ButtonUpperCustom to={navEl.nav.navPage ? envs.routeSuffix + '/' + navEl.nav.navPage + navSearch : envs.routeSuffix + navSearch}><ButtonUpper className='btn-sm' btnType='text'><CaretLeftOutlined />Back to listing</ButtonUpper></ButtonUpperCustom>
								</Col>
								<Col>
									<p>
										<b className='client'>{envs.accountType === 'BUSINESS' ? profile.profile.name : <>{individualProfile?.profile?.firstName ? individualProfile.profile.firstName : 'New'} {individualProfile?.profile?.lastName ? individualProfile.profile.lastName : 'Person'}</>}</b> | 
										<Text type={relationStatus ? "success" : "secondary"}><b> {relationStatus ? 'Active' : 'Not active'}</b></Text>
										{!relationStatus && <> | <Text type={statusInfo === 'SubmittedForReview' || statusInfo === 'Approved' ? "success" : statusInfo === 'NewReviewStatus' || statusInfo === 'NewPartiallyFilled' ? "secondary" : "secondary"}><b> KYC {profileArchived ? 'Archived' : camelCaseToName(statusInfo)} </b></Text></>}
									</p>
								</Col>
							</Row>
						</div>
					</NavCustom>
					<NavCustomTabs style={{top: messagesCount ? 165 : 108}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<Link to={routeSuffix + '/' + profileId + '/kyc/summary'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'kyc' ? 'btn-sm active' : 'btn-sm'} btnType='text'>KYC</ButtonUpperTabs></Link>
								
									<Link to={routeSuffix + '/' + profileId + '/business-profile'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'business-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Profile</ButtonUpperTabs></Link>
								
									<Link to={routeSuffix + '/' + profileId + '/risk-profile'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'risk-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Risk profile</ButtonUpperTabs></Link>
								
								{/*
									<Col>
										<Link to={routeSuffix + '/' + profileId + '/world-check'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'world-check' ? 'btn-sm active' : 'btn-sm'} btnType='text'>World Check</ButtonUpperTabs></Link>
									</Col>
									<Col>
										<Link to={routeSuffix + '/' + profileId + '/transactions'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'transactions' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Transactions</ButtonUpperTabs></Link>
									</Col>
								*/}
									<Link to={routeSuffix + '/' + profileId + '/relation'}><ButtonUpperTabs className={navEl.nav.navSubpage === 'relation' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Relation</ButtonUpperTabs></Link>
								</Col>
							</Row>
						</div>
					</NavCustomTabs>
				</>
			}
			{!envs.admin && envs.type === 'standalone' && nav.step !== -1 &&
				<KycMessages />
			}
			<FormSaver onConfirm={onConfirm} onConfirmLink={onConfirmLink} />
			<div style={{'height': '100%'}}>
				{envs.admin && adminProfile.profile.adminId === '' && adminProfileLoaded.isFullfilled || adminProfileLoaded.isError ? (
					<Error403 />
				) : (
					<PageContent>		
						{listingView === true ? (
							<Row gutter={30}>
								{children}
							</Row>) : ( 	
							<>	    
							<Row gutter={30}>
							    <Col sm={24} md={hideSteps ? 24 : 12} lg={hideSteps ? 24 : 18} xl={hideSteps ? 24 : 18} id='pageWrapper'>
							    	<Space direction='vertical' size={30} style={{'marginBottom': '15px'}}>
							    		<Space direction='vertical' size={'middle'}>
								    		{pageName && <PageName>{pageName}</PageName>}
								      	<Title level={1}>{heading}</Title>
								      </Space>
								    	{children}
									</Space>
									{!hideNav &&
										<Sticky className={((status === 'Approved' || status === 'SubmittedForReview') && step === 0) && !envs.admin ? 'no-stick' : ''} style={{'bottom': `${envs.admin ? '57px' : '0'}`,'borderTop': `2px solid ${themeColor.grayWhite}`}}>
											<KycNav approve={approve} archive={archive} restore={restore} validationAction={validationAction} validationActionParams={validationActionParams} />
										</Sticky>
									}
									
							     </Col>
							     {!hideSteps &&
									    <Col sm={24} md={12} lg={6} xl={6}>
										    <Sticky style={{'top': envs.admin && envs.type === 'standalone' ? '170px' : '80px'}}>
										    	<div>
											    	<Space direction='vertical' size={30}>
											    		{!envs.admin && 
											    			<div>
													    		<CustomBadge count={envs.accountType === 'BUSINESS' ? messages.companyMessages.filter((message) => !message.viewedAt && message.createdBy).length : messages.individualMessages.filter((message) => !message.viewedAt && message.createdBy).length}>
													    			<ButtonUpper btnType="dashed" className='dashed-primary' onClick={() => navToInbox()} block>KYC Messages</ButtonUpper>
													      		</CustomBadge>
												      		</div>
												      	}
										    			<Space direction='vertical' size={'middle'}>
												    		<Title level={5}>KYC steps</Title>
												      	<KycSteps adminView={adminView} />
												    	</Space>
											      	<Space direction='vertical' size={'large'} align='center'>
											      		<Title level={5}>KYC replenishment progress</Title>
											      		<CustomProgress type="circle" percent={envs.accountType === 'BUSINESS' ? readyForReviewValidated.percentageFilled : individualReadyForReviewValidated.percentageFilled} strokeColor={themeColor.green} />
											      	</Space>
											      	
										      	</Space>
									      	</div>
											</Sticky>
										</Col>
									}
								</Row>
								{envs.admin &&
									<Sticky style={{'bottom':'0', 'borderTop': `2px solid ${themeColor.grayWhite}`}}>
										<FooterCustomModule>
											<Row style={{zIndex: 1, background: '#fff', padding: '10px 30px', marginLeft: '-30px', marginRight: '-30px', justifyContent: 'space-between'}}>
								            	{/*<Col span={6}>
										            <ButtonGroupItem btnType="dashed" block size='small' danger>Delete Profile</ButtonGroupItem>
								            	</Col>*/}
								            	<Col span={8}>
										            <FooterDrawer type='message' />
										        </Col>
								            	<Col span={8}>
										            <FooterDrawer type='note' />
										        </Col>
								            </Row>
							            </FooterCustomModule>
						            </Sticky>
								}
								</>
						)}
					</PageContent>
				)}
			</div>
			{envs.type === 'standalone' &&
				<FooterCustom>
					<div className='content'>
					 	<p style={{paddingTop: 20, paddingBottom: 20}}>Copyright © 2022 Bitclear.li</p>
				   </div>
				</FooterCustom>
			}
		</>
	)
}

export default KycLayout
