import { Table, Tag, Space, Button, Typography, Input, Row, Col } from 'antd'
import _ from 'lodash'
import type { InputRef } from 'antd'
import type { FilterConfirmProps } from 'antd/lib/table/interface'
import type { ColumnsType, ColumnType, TableProps } from 'antd/lib/table'
import { CloseCircleOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import { PageContent } from '../../layout/PageContent'
import { store, dispatch } from '../../../store/store'
import { FC, useEffect, useState, useRef } from 'react'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import TableElement from '../../kyc-front/ui-elements/TableElement'
import { companyProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfilesBackGet'
import { individualProfilesBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfilesBackGet'
import { readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import { approveCompanyToggleClear , approveIndividualToggleClear } from '../../../store/kyc-backend/slices/finalApproveSlice'
import { companyDocsGetClear } from '../../../store/kyc-backend/slices/companyDocsSlice'
import { uboBackDocsGetClear } from '../../../store/kyc-backend/slices/uboDocsSlice'
import { authorizedPersonDocsBackGetClear } from '../../../store/kyc-backend/slices/authorizedPersonDocsSlice'
import { companyProfileBackGetClear, archivedCompanyProfilesBackGetClear } from '../../../store/kyc-backend/slices/companySlice'
import { individualProfileBackGetClear } from '../../../store/kyc-backend/slices/individualSlice'
import { identityGetClear } from 'store/kyc-backend/slices/identitySlice'
import { camelCaseToName } from '../../../utilities/nameParsers'
import { themeColor } from '../../../styles/themeStyles'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import { setNav, setFormUpdated } from '../../../store/kyc-front/slices/navSlice'

const { Text } = Typography;

const TableCustom = styled(Table)`
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        font-size: 15px;
        margin-top: -5px;
    }
`

interface DataType {
    title: string,
    dataIndex: string,
    key: string,
    render: any
}

type DataIndex = keyof DataType;


const ClientListing: FC = () => {
	const company = useSelector((state) => state.admin.backCompany);
    const individual = useSelector((state) => state.admin.backIndividual);
    const envs = useSelector((state) => state.general.envs);
    const adminProfile = useSelector((state) => state.admin.admin);
    const {apiURL, admin, adminSuffix, type, env, routeSuffix, profileId, accountType} = envs;
	const { profiles } = company;
    const { individuals } = individual;
    const sortersList = ['createdAt','approvedAt', 'reviewStatus', 'businessRelation', 'fxAccount'];
    const navEl = useSelector((state) => state.general.nav);
    let navigate = useNavigate();
	useEffect(() => {
        dispatch(companyProfilesBackGet({}));
        dispatch(individualProfilesBackGet({params: {}}));
    }, []);
    const assignValuesToStatuses = (status: string) => {
        switch(status) {
            case 'SubmittedForReview':
               return 10
            case 'PartiallyFilled':
                return 9
            case 'CorrectedByAdmin':
                return 8
            case 'CorrectionsRequired':
                return 7
            case 'NewPartiallyFilled':
                return 6
            case 'NewReviewStatus':
                return 5
            case 'Approved':
                return 4
            case 'Denied':
                return 3
            case 'Unknown':
                return 2
            default:
                return 1
        }
    }
	return (
        <KycLayout listingView={true} pageName='Client listing' nav={{hideNav: true, step: -1}}>
    		<PageContent>
    			<Title level={2} style={{marginTop: -40, marginBottom: 20}}>Client profiles</Title> 
                <br />
                {profiles.length || individuals.length ? (
                    <TableElement
                        sortersListProps={sortersList}
                        sortersState={{
                            createdAt: false,
                            approvedAt: false,
                            reviewStatus: false,
                            businessRelation: false,
                            fxAccount: false
                        }}
                    >
                        {({onTableChange, getColumnSearchProps, getColumnFilterProps, defaultCurrent, sorters, filters}) => {
                            const columns = (redirectToUser: any) => ([
                              {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                //@ts-ignore
                                ...getColumnSearchProps('name'),
                                render: (text: string) => <b>{text}</b>,
                              },
                              {
                                title: 'Account type',
                                dataIndex: 'accountType',
                                key: 'accountType',
                                ...getColumnFilterProps('accountType',
                                [
                                    {
                                        text: 'BUSINESS',
                                        value: 'BUSINESS',
                                    },
                                    {
                                        text: 'PRIVATE',
                                        value: 'PRIVATE',
                                    },
                                ]),
                                onFilter: (value: string, record: any) => record.accountType.startsWith(value),
                                render: (relation: any) => <span>{relation}</span>,
                              },
                              {
                                title: 'Product',
                                dataIndex: 'productType',
                                key: 'productType',
                                ...getColumnFilterProps('productType',
                                [
                                    {
                                        text: 'FX',
                                        value: 'FX',
                                    },
                                    {
                                        text: 'PAYGATE',
                                        value: 'PAY',
                                    },
                                ]),
                                onFilter: (value: string, record: any) => record.productType.startsWith(value),
                                render: (type: any) => <span>{type === 'PAY' ? 'PAYGATE' : type}</span>,
                              },
                              {
                                title: 'Created at',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                sortOrder: sorters.createdAt,
                                //@ts-ignore
                                sorter: (a: any, b: any) => new Date(a.createdAt) - new Date(b.createdAt),
                                render: (created: string) => <span>{moment(created).format('YYYY-MM-DD HH:mm:ss')}</span>,
                              },
                              {
                                title: 'KYC approved',
                                dataIndex: 'approvedAt',
                                key: 'approvedAt',
                                sortOrder: sorters.approvedAt,
                                //@ts-ignore
                                sorter: (a: any, b: any) => a.approvedAt && b.approvedAt ? new Date(a.approvedAt) - new Date(b.approvedAt) : a.approvedAt ? 100000000 : -100000000,
                                render: (approved: string) => <>{!approved ? <>-</> : <span>{moment(approved).format('YYYY-MM-DD HH:mm:ss')}</span>}</>,

                              },
                              {
                                title: 'KYC status',
                                dataIndex: 'reviewStatus',
                                key: 'reviewStatus',

                                ...getColumnFilterProps('reviewStatus',
                                [
                                    {
                                        text: 'Approved',
                                        value: 'Approved',
                                    },
                                    {
                                        text: 'Submitted for Review',
                                        value: 'SubmittedForReview'
                                    },
                                    {
                                        text: 'Corrected by Admin',
                                        value: 'CorrectedByAdmin',
                                    },
                                    {
                                        text: 'Corrections Required',
                                        value: 'CorrectionsRequired'
                                    },
                                    {
                                        text: 'Partially Filled',
                                        value: 'PartiallyFilled'
                                    },
                                    {
                                        text: 'New Partially Filled', 
                                        value: 'NewPartiallyFilled'
                                    },
                                    {
                                        text: 'New Review Status',
                                        value: 'NewReviewStatus'
                                    },
                                    {
                                        text: 'Denied',
                                        value: 'Denied'
                                    },
                                    {
                                        text: 'Unknown',
                                        value: 'Unknown'
                                    }
                                ]),
                                onFilter: (value: string, record: any) => record.reviewStatus ? record.reviewStatus.startsWith(value) : value === 'Unknown' ? value === 'Unknown' : false,
                                render: (status: any) => <span>{status ? camelCaseToName(status) : '-'}</span>
                              },
                              {
                                title: 'Business relation',
                                dataIndex: 'businessRelation',
                                key: 'businessRelation',
                                ...getColumnFilterProps('businessRelation',
                                [
                                    {
                                        text: 'Active',
                                        value: 'isActive',
                                    },
                                    {
                                        text: 'Not active',
                                        value: 'notActive'
                                    }
                                ]),
                                onFilter: (value: string, record: any) => record.businessRelation.isActive && value === 'isActive' ? true : record.businessRelation.isActive === false && value === 'notActive' ? true : false,
                                render: (relation: any) => <span>{relation?.isActive ? <><Text type="success"><CheckCircleOutlined style={{marginRight: 8}} /></Text>Active</> : <>-</>}</span>,
                              },
                              {
                                title: 'FX Account',
                                dataIndex: 'fxAccount',
                                key: 'fxAccount',
                                ...getColumnFilterProps('fxAccount',
                                [
                                    {
                                        text: 'Created',
                                        value: 'isCreated',
                                    },
                                    {
                                        text: 'Not created',
                                        value: 'notCreated'
                                    }
                                ]),
                                onFilter: (value: string, record: any) => record.fxAccount && value === 'isCreated' ? true : record.fxAccount === undefined && value === 'notCreated' ? true : false,
                                render: (account: any) => <span>{account ? <><Text type="success"><CheckCircleOutlined style={{marginRight: 8}} /></Text>Created</> : <>-</>}</span>,
                              },
                              {
                                title: 'Action',
                                key: 'action',
                                render: (el: any) => (
                                    <Space size="middle">
                                        <ButtonUpper className='btn-sm' btnType='primary' onClick={() => redirectToUser(el)} ghost>Review</ButtonUpper>
                                    </Space>
                                ),
                              },
                            ]);
                            const redirectToUser = (el: any) => {
                                dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: el.id, accountType: el.accountType}));
                                //@ts-ignore
                                dispatch(setNav({paginationPage: defaultCurrent, sorters, filters}));
                                navigate(el.routeSuffix + '/' + el.id + '/summary');
                            }
                            return (
                                <TableCustom 
                                    pagination={{ showSizeChanger: false, current: defaultCurrent }} 
                                    onChange={onTableChange} 
                                    columns={columns(redirectToUser)}
                                    dataSource={[...profiles, ...individuals].map((profile, i) => {
                                        return {
                                            //@ts-ignore
                                            name: profile.name ? profile.name : profile.profile?.firstName + ' ' + profile.profile?.lastName, 
                                            key: i,
                                            //@ts-ignore
                                            businessRelation: profile.businessRelation, 
                                            //@ts-ignore
                                            createdAt: profile.createdAt, 
                                            //@ts-ignore
                                            approvedAt: profile.approvedAt, 
                                            //@ts-ignore
                                            accountType: profile.companyId ? 'BUSINESS' : 'PRIVATE', 
                                            //@ts-ignore
                                            productType: profile.bitclearProductType ? profile.bitclearProductType : profile.companyId ? '' : 'FX', 
                                            //@ts-ignore
                                            id: profile.companyId ? profile.companyId : profile.individualId, 
                                            //@ts-ignore
                                            reviewStatus: profile.reviewStatus, 
                                            //@ts-ignore
                                            fxAccount: profile.externalAccountId, 
                                            routeSuffix
                                        }
                                    })}
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
                <br />
    		</PageContent>
        </KycLayout>
	)
}

export default ClientListing
