import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
/*import { statusUpdate } from "../asyncThunks/statusUpdate";
import { individualStatusUpdate } from "../asyncThunks/individualStatusUpdate";*/
import { individualBackValidate } from "../asyncThunks/individualBackValidate";
import { individualBackDeclarationsValidate } from "../asyncThunks/individualBackDeclarationsValidate";
import { companyBackValidate } from "../asyncThunks/companyBackValidate";
import { authorizedPersonsBackValidate } from "../asyncThunks/authorizedPersonsBackValidate";
import { readyForReviewBackValidate } from "../asyncThunks/readyForReviewBackValidate";
import { individualBackReadyForReviewValidate } from "../asyncThunks/individualBackReadyForReviewValidate";
import { ubosBackValidate } from "../asyncThunks/ubosBackValidate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

/*export type StatusResponse = Components.Schemas.CompanyPreReviewValidated;
export type IndividualStatusResponse = Components.Schemas.IndividualPreReviewValidated;*/
export type IndividualDeclarationsValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId;
export type IndividualValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId;
export type ProfileResponse = Components.Schemas.ValidatedModelEntryCompanyId;
export type AuthorizedPersonsResponse = Components.Schemas.ValidatedModelEntryAuthorizedPersonId[];
export type UbosResponse = Components.Schemas.ValidatedModelEntryBeneficialOwnerId[];
export type ReadyForReviewResponse = Components.Schemas.CompanyPreReviewValidated;
export type IndividualReadyForReviewResponse = Components.Schemas.IndividualPreReviewValidated;

type State = {
  //updatedStatus: StatusResponse,
  companyValidated: ProfileResponse,
  individualValidated: IndividualValidateResponse;
  individualDeclarationsValidated: IndividualDeclarationsValidateResponse;
  /*individualUpdatedStatus: IndividualStatusResponse,*/
  authorizedPersonsValidated: AuthorizedPersonsResponse,
  beneficialOwnersValidated: UbosResponse,
  readyForReviewValidated: ReadyForReviewResponse,
  individualReadyForReviewValidated: IndividualReadyForReviewResponse
};

const initialState: State = {
  /*updatedStatus: {
    companyProfileValidated: {
      entry: '',
      validationErrors: []
    },
    authorizedPersonsValidated: [],
    beneficialOwnersValidated: [],
    numberOfDirectorsValidated: {
      entry: 0
    },
    numberOfSignatoryValidated: {
      entry: 0
    },
    numberOfBeneficialOwnersValidated: {
      entry: 0
    },
    percentageFilled: 0
  },*/
  companyValidated: {
    entry: '',
    validationErrors: []
  },
  individualValidated: {
    entry: '',
    validationErrors: []
  },
  individualDeclarationsValidated: {
    entry: '',
    validationErrors: []
  },
  /*individualUpdatedStatus: {
    // @ts-ignore
    beneficialOwnerValidated: {},
    // @ts-ignore
    declarationValidated: {}
  },*/
  authorizedPersonsValidated : [],
  beneficialOwnersValidated: [],
  readyForReviewValidated: {
    // @ts-ignore
    companyProfileValidated: {
      entry: '',
      validationErrors: []
    },
    numberOfDirectorsValidated: {
      entry: 0
    },
    numberOfSignatoryValidated: {
      entry: 0
    },
    numberOfBeneficialOwnersValidated: {
      entry: 0
    },
    percentageFilled: 0
  },
  individualReadyForReviewValidated : {
    // @ts-ignore
    beneficialOwnerValidated: {},
    percentageFilled: 0
  }
};

type CaseReducers<State> = {
  //statusUpdateClear: CaseReducerType<State, object>;
  companyBackValidateClear: CaseReducerType<State, object>;
  individualBackValidateClear: CaseReducerType<State, object>;
  individualBackDeclarationsValidateClear: CaseReducerType<State, object>;
  authorizedPersonsBackValidateClear: CaseReducerType<State, object>;
  ubosBackValidateClear: CaseReducerType<State, object>;
  /*individualStatusUpdateClear: CaseReducerType<State, object>;*/
  readyForReviewBackValidateClear: CaseReducerType<State, object>;
  individualBackReadyForReviewValidateClear: CaseReducerType<State, object>;
};

export const backStatusSlice = createSlice<State, CaseReducers<State>>({
  name: "backStatus",
  initialState,
  reducers: {
    /*statusUpdateClear: (state, { payload }) => {
      state.updatedStatus = initialState.updatedStatus
    },*/
    companyBackValidateClear: (state, { payload }) => {
      state.companyValidated = initialState.companyValidated
    },
    individualBackValidateClear: (state, { payload }) => {
      state.individualValidated = initialState.individualValidated
    },
    individualBackDeclarationsValidateClear: (state, { payload }) => {
      state.individualDeclarationsValidated = initialState.individualDeclarationsValidated
    },
    authorizedPersonsBackValidateClear: (state, { payload }) => {
      state.authorizedPersonsValidated = initialState.authorizedPersonsValidated
    },
    ubosBackValidateClear: (state, { payload }) => {
      state.beneficialOwnersValidated = initialState.beneficialOwnersValidated
    },
    /*individualStatusUpdateClear: (state, { payload }) => {
      state.individualUpdatedStatus = initialState.individualUpdatedStatus
    },*/
    readyForReviewBackValidateClear: (state, { payload }) => {
      state.readyForReviewValidated = initialState.readyForReviewValidated
    },
    individualBackReadyForReviewValidateClear: (state, { payload }) => {
      state.individualReadyForReviewValidated = initialState.individualReadyForReviewValidated
    }
  },
  extraReducers: (builder) => {
    /*builder.addCase(statusUpdate.fulfilled, (state, action) => {
      state.updatedStatus = action.payload
    });*/
    builder.addCase(companyBackValidate.fulfilled, (state, action) => {
      state.companyValidated = action.payload
    });
    builder.addCase(individualBackValidate.fulfilled, (state, action) => {
      state.individualValidated = action.payload
    });
    builder.addCase(individualBackDeclarationsValidate.fulfilled, (state, action) => {
      state.individualDeclarationsValidated = action.payload
    });
    /*builder.addCase(individualStatusUpdate.fulfilled, (state, action) => {
      state.individualUpdatedStatus = action.payload
    });*/
    builder.addCase(authorizedPersonsBackValidate.fulfilled, (state, action) => {
      state.authorizedPersonsValidated = action.payload
    });
    builder.addCase(ubosBackValidate.fulfilled, (state,action) => {
      state.beneficialOwnersValidated = action.payload
    });
    builder.addCase(readyForReviewBackValidate.fulfilled, (state,action) => {
      state.readyForReviewValidated = action.payload
    });
    builder.addCase(individualBackReadyForReviewValidate.fulfilled, (state,action) => {
      state.individualReadyForReviewValidated = action.payload
    })
  },
});

export const { /*statusUpdateClear,*/ companyBackValidateClear, individualBackValidateClear, individualBackDeclarationsValidateClear, authorizedPersonsBackValidateClear, ubosBackValidateClear, /*individualStatusUpdateClear,*/ readyForReviewBackValidateClear, individualBackReadyForReviewValidateClear } = backStatusSlice.actions;
export default backStatusSlice.reducer;
