import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../../api/kyc-backend/api"
import { BusinessProfileResponse } from '../slices/businessProfileSlice'
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "../../../api/kyc-backend/generated/client"

type PathParameters = Paths.CalculateBusinessProfileFromKYC.PathParameters;

export const companyBusinessProfileFromKycGet: AsyncThunkActionType<
  BusinessProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("companyBusinessProfileFromKycGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CalculateBusinessProfileFromKYC(data.params, null, data?.config);
    return response.data as BusinessProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
