import { Spin } from 'antd'
import { FC, useEffect } from 'react'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { companyValidate } from '../../../store/kyc-front/asyncThunks/companyValidate'
import { companyUpdate } from '../../../store/kyc-front/asyncThunks/companyUpdate'
import { companyDocsGet } from '../../../store/kyc-front/asyncThunks/companyDocsGet'
import { companyDocArchive } from '../../../store/kyc-front/asyncThunks/companyDocArchive'
import { companyDocCreate } from '../../../store/kyc-front/asyncThunks/companyDocCreate'
import { companyDocUrlGet } from '../../../store/kyc-front/asyncThunks/companyDocUrlGet'
import { companyUpdateClear } from '../../../store/kyc-front/slices/companySlice'
import { companyDocsGetClear, companyDocCreateClear, companyDocUrlGetClear, companyDocArchiveClear } from '../../../store/kyc-front/slices/companyDocsSlice'
import { companyValidateClear } from '../../../store/kyc-front/slices/statusSlice'
import KycLayout from '../kyc-layout/KycLayout'
import KycCompanyInfoForm from '../forms/KycCompanyInfoForm';

const KycCompanyInfo: FC = () => {	
	const profile = useSelector((state) => state.user.profile);
	const company = useSelector((state) => state.user.company);
	const envs = useSelector((state) => state.general.envs);
	const { userProfile } = profile;
	const description = userProfile.description;
	useEffect(() => {
		dispatch(profileGet({ params: { companyId: envs.profileId } }))
	}, [])
	return (
      	<KycLayout pageName='Step 1' heading='Company information & business description' nav={{step: 1, next: "/step2", prev: envs.type === 'standalone' ? '/summary' : "/"}} validationAction={companyValidate} validationActionParams={{companyId: envs.profileId}}>
      		{userProfile.companyId !== '' ? (
				<KycCompanyInfoForm 
					company={profile.userProfile} 
					companyGet={profileGet} 
					companyUpdate={companyUpdate} 
					companyUpdatedResp={company.updated} 
					companyClearUpdate={companyUpdateClear}
    				companyDocsGet={companyDocsGet}
    				companyDocsGetParams={{companyId: envs.profileId}}
    				companyDocsGetClear={companyDocsGetClear}
    				companyDocArchive={companyDocArchive}
    				companyDocArchiveClear={companyDocArchiveClear}
    				companyDocCreate={companyDocCreate}
    				companyDocCreateClear={companyDocCreateClear}
    				companyDocUrlGet={companyDocUrlGet}
    				companyDocUrlGetClear={companyDocUrlGetClear} 
					validationAction={companyValidate} 
            		validationActionClear={companyValidateClear}
				/>
			) : (
				<Spin size="large" />
			)}
		</KycLayout>
			 
	)
}

export default KycCompanyInfo
