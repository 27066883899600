import { Spin, Row, Col, Divider } from 'antd'
import { FC, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { dispatch } from '../../../store/store'
import { useSelector } from '../../../hooks/useSelector'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { individualProfileBackUpdate } from '../../../store/kyc-backend/asyncThunks/individualProfileBackUpdate'
import { individualProfileBackUpdateClear } from '../../../store/kyc-backend/slices/individualSlice'
import { individualBackDeclarationsValidate } from '../../../store/kyc-backend/asyncThunks/individualBackDeclarationsValidate'
import { individualBackDeclarationsValidateClear } from '../../../store/kyc-backend/slices/statusSlice'
import { approveCompanyToggle } from '../../../store/kyc-backend/asyncThunks/approveCompanyToggle'
import { approveIndividualToggle } from '../../../store/kyc-backend/asyncThunks/approveIndividualToggle'
import { approveIndividualToggleClear } from '../../../store/kyc-backend/slices/finalApproveSlice'
import { ButtonUpper } from '../../kyc-front/ui-elements/Buttons'
import { PageContent } from '../../layout/PageContent'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { ValidationErrors } from '../../kyc-backend/ui-elements/ValidationErrors';
import KycIndividualDeclarationsForm from '../../kyc-front/forms/KycIndividualDeclarationsForm'
import KycIndividualDataValidationForm from '../../kyc-front/forms/KycIndividualDataValidationForm'
import styled from 'styled-components'
import { SuccessIcon } from '../../kyc-front/ui-elements/SuccessInfo'

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

const KycIndividualDeclarations: FC = () => {	
	const individual = useSelector((state) => state.admin.backIndividual);
	const envs = useSelector((state) =>  state.general.envs);
	const finalApprove = useSelector((state) => state.admin.finalApprove);
	const individualFinal = finalApprove.individualApprovedStatus;
	let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
	useEffect(() => {
        return () => {
            dispatch(approveIndividualToggleClear({}));
        };
    }, []);
	useEffect(() => {
		dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
	}, [])
	useEffect(() => {
		if(individualFinal.isApproved) {
			window.scrollTo(0, 0);
		}
	}, [individualFinal.isApproved])
	return (
		<>
      	<KycLayout pageName='Step 2' heading='Declarations and printout' nav={{step: 2, prev: "/step1", hideNav: false, navSubpage: 'kyc'}} validationAction={individualBackDeclarationsValidate} validationActionParams={{individualId: envs.profileId}} adminView={true} approve={true}>
			{individualFinal.isApproved ? (
				<div>
	                    <Row justify='center'>
	                        <CenteredCol>
	                            <SuccessIcon />
	                            <p><b>Approved!</b></p>
	                            <p>Your have succesfully approved this KYC</p>
	                        </CenteredCol>
	                    </Row>
	                    <Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
	                        <CenteredCol>
	                            <Link to={envs.type === 'standalone' ? isStandaloneAdmin ? envs.routeSuffix +  '/' + envs.profileId + '/summary' : envs.routeSuffix + '/summary' : envs.routeSuffix + '/'}><ButtonUpper btnType='default'>Go to Summary</ButtonUpper></Link>
	                        </CenteredCol>
	                    </Row>
	            </div>
	        ) : (
	        	<>
	        		{individual.individual.individualId !== '' ? (
		      			<>
							<KycIndividualDeclarationsForm 
								individual={individual} 
								individualGet={individualProfileBackGet} 
								individualUpdate={individualProfileBackUpdate} 
								individualClearUpdate={individualProfileBackUpdateClear}  
								validationAction={individualBackDeclarationsValidate} 
		            			validationActionClear={individualBackDeclarationsValidateClear}
							/>
							{!envs.admin ? (
								<KycIndividualDataValidationForm individual={individual} individualGet={individualProfileBackGet} individualUpdate={individualProfileBackUpdate} individualClearUpdate={individualProfileBackUpdateClear} />
							) : (
								<ValidationErrors />
							)}
						</>
					) : (
						<Spin size="large" />
					)}
	        	</>
	        )}
		</KycLayout>
		{/*}
		<PageContent>
			<Row>
				<Col sm={24} md={12} lg={18} xl={18}>
					<Divider />
					<Row justify='center'>
						<CenteredCol>
							<p><b>Congratulations!</b></p>
							<p>everything is complete, you can:</p>
							<ButtonUpper btnType='primary'>Download & print kyc</ButtonUpper>
						</CenteredCol>
					</Row>
				</Col>
			</Row>
		</PageContent>*/}
		</>
			 
	)
}

export default KycIndividualDeclarations
