import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc-backend/generated/client";
import { companyProfileBackGet } from "../asyncThunks/companyProfileBackGet";
import { companyProfilesBackGet } from "../asyncThunks/companyProfilesBackGet";
import { archivedCompanyProfilesBackGet } from "../asyncThunks/archivedCompanyProfilesBackGet";
import { companyAllDocumentsGet } from "../asyncThunks/companyAllDocumentsGet";
import { companyBackUpdate } from "../asyncThunks/companyBackUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { reduce } from "lodash";

export type ProfileResponse = Components.Schemas.CompanyResponse;
type ArchivedProfileResponse = Components.Schemas.CompanyProfileResponse;
export type CompanyUpdatedResponse = Components.Schemas.CompanyResponse;
type DocsResponse = Components.Schemas.CompanyDocumentCombined;

type State = {
  profile: ProfileResponse;
  profiles: ProfileResponse[];
  archivedProfiles: ArchivedProfileResponse[];
  updated: CompanyUpdatedResponse;
  companyAllDocuments: DocsResponse[];
};

const emptyProfile = {
    companyId: '',
    businessRelation: {
        isActive: false
    },
    name: '',
    legalForm: '',
    registrationNumber: '',
    placeOfIncorporation: '',
    phone: '',
    fax: '',
    createdAt: ''
}

const initialState: State = {
  profile: {
      companyId: "",
      description: {
        id: "",
        companyId: "",
        website: "",
        activitiesAndBusinessModel: "",
        geographicalArea: "",
        annualTurnoverEUR: "",
        estimatedAmountOfBTC: "",
        estimatedAmountOfEUR: "",
        banks: "",
        fundingOfBusinessDescription: "",
        fundingOfBusinessType: "",
        businessLicence: "",
        complianceProgram: "",
        numberOfEmployees: -1,
        createdAt: ""
      },
      profile: {
        businessRelation: {
            isActive: false
        },
        companyId: "",
        name: "",
        legalForm: "",
        registrationNumber: "",
        placeOfIncorporation: "",
        phone: "",
        fax: "",
        createdAt: ""
      }
  },
  profiles: [],
  archivedProfiles: [],
  updated: {
    companyId: '',
    profile: emptyProfile
  },
  companyAllDocuments: []
};

type CaseReducers<State> = {
  companyBackUpdateClear: CaseReducerType<State, object>;
  companyProfileBackGetClear: CaseReducerType<State, object>;
  companyAllDocumentsGetClear: CaseReducerType<State, object>;
  archivedCompanyProfilesBackGetClear: CaseReducerType<State, object>;
};

export const companySlice = createSlice<State, CaseReducers<State>>({
  name: "company",
  initialState,
  reducers: {
    companyBackUpdateClear: (state, { payload }) => {
        state.updated = initialState.updated
    },
    companyProfileBackGetClear: (state, { payload }) => {
        state.profile = initialState.profile
    },
    companyAllDocumentsGetClear: (state, { payload }) => {
        state.companyAllDocuments = initialState.companyAllDocuments
    },
    archivedCompanyProfilesBackGetClear: (state, {payload}) => {
        state.archivedProfiles = initialState.archivedProfiles
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyProfileBackGet.fulfilled, (state, action) => {
        state.profile = action.payload;
    });
    builder.addCase(companyProfilesBackGet.fulfilled, (state, action) => {
        state.profiles = action.payload;
    });
    builder.addCase(companyBackUpdate.fulfilled, (state, action) => {
        state.updated = action.payload
    });
    builder.addCase(companyAllDocumentsGet.fulfilled, (state, action) => {
        state.companyAllDocuments = action.payload
    });
    builder.addCase(archivedCompanyProfilesBackGet.fulfilled, (state, action) => {
        state.archivedProfiles = action.payload
    });
  },
});

export const { companyBackUpdateClear, companyProfileBackGetClear, companyAllDocumentsGetClear, archivedCompanyProfilesBackGetClear } = companySlice.actions;
export default companySlice.reducer;
