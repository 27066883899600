import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { authorizedPersonsBackGet } from "../asyncThunks/authorizedPersonsBackGet";
import { authorizedPersonBackUpdate } from "../asyncThunks/authorizedPersonBackUpdate";
import { authorizedPersonBackCreate } from "../asyncThunks/authorizedPersonBackCreate";
import { authorizedPersonBackArchive } from "../asyncThunks/authorizedPersonBackArchive";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

import { reduce } from "lodash";

export type APResponse = Components.Schemas.AuthorizedPersonProfile;
export type ArchiveReponse = Paths.ArchiveAuthorizedPerson.Responses.$200;

type State = {
  newAuthorizedPerson: APResponse;
  authorizedPersons: APResponse[];
  archivedAuthorizedPerson: ArchiveReponse;
  updatedAuthorizedPerson: APResponse;
};

const initialState: State = {
  authorizedPersons: [],
  newAuthorizedPerson: {
    authorizedPersonId: '',
    companyId: '',
    createdAt: ''
  },
  updatedAuthorizedPerson: {
    authorizedPersonId: '',
    companyId: '',
    firstName: '',
    lastName: '',
    createdAt: ''
  },
  archivedAuthorizedPerson: false,
};

type CaseReducers<State> = {
  authorizedPersonBackCreateClear: CaseReducerType<State, object>;
  authorizedPersonBackUpdateClear: CaseReducerType<State, object>;
  authorizedPersonBackArchiveClear: CaseReducerType<State, object>;
};

export const authorizedPersonsSlice = createSlice<State, CaseReducers<State>>({
  name: "authorizedPersonsBack",
  initialState,
  reducers: {
    authorizedPersonBackCreateClear: (state, { payload }) => {
      state.newAuthorizedPerson = initialState.newAuthorizedPerson
    },
    authorizedPersonBackUpdateClear: (state, { payload }) => {
      state.updatedAuthorizedPerson = initialState.updatedAuthorizedPerson
    },
    authorizedPersonBackArchiveClear: (state, { payload }) => {
      state.archivedAuthorizedPerson = initialState.archivedAuthorizedPerson
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authorizedPersonBackArchive.fulfilled, (state, action) => {
      state.archivedAuthorizedPerson = action.payload;
    });
    builder.addCase(authorizedPersonsBackGet.fulfilled, (state, action) => {
      state.authorizedPersons = action.payload;
    });
    builder.addCase(authorizedPersonBackUpdate.fulfilled, (state, action) => {
      state.updatedAuthorizedPerson = action.payload;
    });
    builder.addCase(authorizedPersonBackCreate.fulfilled, (state, action) => {
      state.newAuthorizedPerson = action.payload;
    });
  }
});

export const { authorizedPersonBackCreateClear, authorizedPersonBackUpdateClear, authorizedPersonBackArchiveClear } = authorizedPersonsSlice.actions;
export default authorizedPersonsSlice.reducer;
