import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type ProfileResponse = Components.Schemas.IndividualProfile;

export const individualGet: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<null, null>
> = createAsyncThunk("individualGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.GetIndividualProfile();
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
