import { FC } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import KycAuthorizedPersonsListing from '../listings/KycAuthorizedPersonsListing'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { authorizedPersonDocsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocsGet'
import { authorizedPersonDocUrlGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocUrlGet'
import { authorizedPersonDocCreate } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocCreate'
import { authorizedPersonDocCreateClear, authorizedPersonDocArchiveClear } from '../../../store/kyc-front/slices/authorizedPersonDocsSlice'
import { authorizedPersonsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonsGet'
import { authorizedPersonCreate } from '../../../store/kyc-front/asyncThunks/authorizedPersonCreate'
import { authorizedPersonUpdate } from '../../../store/kyc-front/asyncThunks/authorizedPersonUpdate'
import { authorizedPersonArchive } from '../../../store/kyc-front/asyncThunks/authorizedPersonArchive'
import { authorizedPersonDocArchive } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocArchive'
import { authorizedPersonCreateClear, authorizedPersonUpdateClear, authorizedPersonArchiveClear } from '../../../store/kyc-front/slices/authorizedPersonsSlice'
import { authorizedPersonsValidate } from '../../../store/kyc-front/asyncThunks/authorizedPersonsValidate'
import { authorizedPersonsValidateClear } from '../../../store/kyc-front/slices/statusSlice'


const KycAuthorizedPersons: FC = () => {
	const profile = useSelector((state) => state.user.profile);
	const authorizedPersons = useSelector((state) => state.user.authorizedPersons);
	return (
		<KycAuthorizedPersonsListing 
			profile={profile.userProfile} 
			profileGet={profileGet} 
			docUrlGet={authorizedPersonDocUrlGet}
			authorizedPersons={authorizedPersons} 
			authorizedPersonsGet={authorizedPersonsGet} 
			authorizedPersonCreate={authorizedPersonCreate} 
			authorizedPersonClearCreate={authorizedPersonCreateClear} 
			authorizedPersonClearUpdate={authorizedPersonUpdateClear}
			authorizedPersonUpdate={authorizedPersonUpdate} 
			authorizedPersonDocsGet={authorizedPersonDocsGet} 
			authorizedPersonDocCreate={authorizedPersonDocCreate}
			authorizedPersonDocCreateClear={authorizedPersonDocCreateClear}
			authorizedPersonArchive={authorizedPersonArchive}
			authorizedPersonClearArchive={authorizedPersonArchiveClear}
			authorizedPersonDocArchive={authorizedPersonDocArchive}
			authorizedPersonDocArchiveClear={authorizedPersonDocArchiveClear}
			validationAction={authorizedPersonsValidate} 
           	validationActionClear={authorizedPersonsValidateClear}
		/>	 
	)
}

export default KycAuthorizedPersons
