import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

//type ProfilesResponse = Components.Schemas.CompanyProfileResponse[];
type ProfilesResponse = any;

export const companyProfilesBackGet: AsyncThunkActionType<
  ProfilesResponse,
  AsyncThunkDataType<null, null>
> = createAsyncThunk("companyProfilesBackGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.getCompanyProfiles(null, null, data?.config);
    //return response.data as ProfilesResponse[];
    return response.data as unknown as string;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
