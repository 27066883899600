import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormSelect from '../ui-elements/FormSelect'
import { individualStatusUpdate } from '../../../store/kyc-front/asyncThunks/individualStatusUpdate'
import { individualStatusUpdateClear } from '../../../store/kyc-front/slices/statusSlice'
import { individualValidate } from '../../../store/kyc-front/asyncThunks/individualValidate'
//import { individualValidateClear } from '../../../store/kyc-front/slices/statusSlice'
import Well from '../ui-elements/Well'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import KycDocsInfo from '../KycDocsInfo'

const Muted = styled.p`
    color: ${themeColor.gray}
`

interface KProps {
    printRender?: boolean,
    individual: any,
    individualGet: any,
    individualUpdate?: any,
    individualClearUpdate?: any,
}

const KycIndividualDataValidationForm: FC<KProps> = ({printRender, individual, individualGet, individualUpdate, individualClearUpdate}) => {
    const { Option } = Select;
    const envs = useSelector((state) => state.general.envs);
    const status = useSelector((state) => state.user.status);
    //let indStat = status.individualValidated;
    const initialValues = { }
    const validation = Yup.object({
        
    });
    return (
        <BasicFormWrapper 
            id='dataValidationForm' 
            initial={initialValues} 
            validation={validation} 
            dispatchSubmit={individualStatusUpdate} 
            dispatchGet={individualGet} 
            successCondition={'success'} 
            finalSubmit={true}
        >
            {({handleInputChange, onSelectChange, onDateChange, values}) => {
                return (
                    <>
                        <div>
                            <Well>
                                <p><b>Next Steps:</b></p>
                                <p>1) Our compliance team will review the information and documents provided by you and give you feedback</p>
                                <p>2) We may request additional information or documents in case anything is incomplete or unclear</p>
                                <p>3) Once everything is complete, we will ask you to print, sign and send the original form including supporting documents to us by post/courier.</p>
                            </Well>
                            <br />
                        </div>
                    </>
                )
            }}
        </BasicFormWrapper>
    )
}

export default KycIndividualDataValidationForm
