import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { uboBackDocsGet } from "../asyncThunks/uboBackDocsGet";
import { uboBackDocUrlGet } from "../asyncThunks/uboBackDocUrlGet";
import { uboBackDocCreate } from "../asyncThunks/uboBackDocCreate";
import { uboBackDocArchive } from "../asyncThunks/uboBackDocArchive";
import { ObjectKeyType } from "types";
import { reduce } from "lodash";
import { CaseReducerType } from "../../store";

export type UboDocsResponse = Components.Schemas.BeneficialOwnerDocument;
export type UrlResponse = Paths.GetBeneficialOwnerDocument.Responses.$200;
export type ArchiveReponse = Paths.ArchiveBeneficialOwnerDocumentEndpoint.Responses.$200;

type State = {
    uboDocs: UboDocsResponse[];
    uboDocUrl: {id: string, value: UrlResponse}[];
    newUboDoc: UboDocsResponse,
    uboDocArchived: {id: string, value: ArchiveReponse}
};

const initialState: State = {
    newUboDoc: {
        documentId: '',
        companyId: '',
        uboId: '',
        filename: '',
        originalFilename: '',
        originalMimeType: '',
        role: '',
        createdAt: ''
    },
    uboDocs: [],
    uboDocUrl: [],
    uboDocArchived: {id: '', value: false}
};

type CaseReducers<State> = {
    uboBackDocCreateClear: CaseReducerType<State, object>;
    uboBackDocsGetClear: CaseReducerType<State, object>;
    uboBackDocUrlGetClear: CaseReducerType<State, object>;
    uboBackDocArchiveClear: CaseReducerType<State, object>;
};

export const uboDocsSlice = createSlice<State, CaseReducers<State>>({
    name: "uboBackDocs",
    initialState,
    reducers: {
        uboBackDocCreateClear: (state, { payload }) => {
            state.newUboDoc = initialState.newUboDoc
        },
        uboBackDocUrlGetClear: (state, { payload }) => {
            state.uboDocUrl = initialState.uboDocUrl
        },
        uboBackDocsGetClear: (state, { payload }) => {
            state.uboDocs = initialState.uboDocs
        },
        uboBackDocArchiveClear: (state, { payload }) => {
            state.uboDocArchived = initialState.uboDocArchived
        }
    },
    extraReducers: (builder) => {
        builder.addCase(uboBackDocsGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocs.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocs[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocs.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
        builder.addCase(uboBackDocCreate.fulfilled, (state, action) => {
            state.newUboDoc = action.payload;
        });
        builder.addCase(uboBackDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newUboDoc = action.payload;
        });
        builder.addCase(uboBackDocArchive.fulfilled, (state, action) => {
            state.uboDocArchived = action.payload;
        });
        builder.addCase(uboBackDocUrlGet.fulfilled, (state, action) => {
            //@ts-ignore
            let elInd = state.uboDocUrl.findIndex((el) => el.id == action.meta.arg.params.beneficialOwnerId);
            if (elInd > -1) {
                //@ts-ignore
                state.uboDocUrl[elInd].value = action.payload
            } else {
                //@ts-ignore
                state.uboDocUrl.push({id: action.meta.arg.params.beneficialOwnerId, value: action.payload})
            }
        });
    }
});

export const { uboBackDocCreateClear, uboBackDocUrlGetClear, uboBackDocsGetClear, uboBackDocArchiveClear } = uboDocsSlice.actions;
export default uboDocsSlice.reducer;
